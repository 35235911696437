// React
import React from "react";
import { Modal, ScrollView, View } from "react-native";

import * as ImagePicker from "expo-image-picker";
// Components
import Screen from "../../components/Screen";
// Hooks
import usePopUp from "../../hooks/usePopUp";
// Api's
import Container from "../../components/Container";
import ListingCard from "../../components/ListingCard";
import useApi from "../../hooks/useApi";
import leadsApi from "../../api/leads";
import { useEffect } from "react";
import { useState } from "react";
import colors from "../../config/colors";
import IconTouchable from "../../components/IconTouchable";
import { callNumber } from "../../utility/contactUser";
import useLocation from "../../hooks/useLocation";
import AppButtonGoBack from "../../components/AppButtonGoBack";
import { ILead } from "../../interfaces/lead";
import AppTextInput from "../../components/AppTextInput";
import authStorage from "../../auth/storage";
import jwtDecode from "jwt-decode";

export default function MyRequestScreen({ navigation }) {
    const { setMessage } = usePopUp();
    const { getCurrentLocation } = useLocation();

    const cancelLeadApi = useApi(leadsApi.cancelLead);
    const boostLeadApi = useApi(leadsApi.boostLead);
    const completeLeadApi = useApi(leadsApi.completeLead);

    const [customerRating, setCustomerRating] = useState(0);
    const [customerFeedback, setCustomerFeedback] = useState("");
    const [markAsCompletedModalVisible, setMarkAsCompletedModalVisible] = useState(false);

    const getLeadApi = useApi(leadsApi.getLeadCustomer);
    const uploadLeadImageApi = useApi(leadsApi.uploadImage);
    const setLocationApi = useApi(leadsApi.setLocation);

    const [lead, setLead] = useState<ILead | null>(null);

    useEffect(() => {
        getLead();
    }, []);

    const handleCloseRequest = async (type: string) => {
        if (type == "cancelled") {
            const response = await cancelLeadApi.request(lead._id, customerFeedback, customerRating);
            if (!response.ok) {
                setMessage(response.data);
                return;
            }
        }

        if (type == "completed") {
            const response = await completeLeadApi.request(lead._id, customerFeedback, customerRating);
            if (!response.ok) {
                setMessage(response.data);
                return;
            }
        }

        setMarkAsCompletedModalVisible(false);
        setMessage("Thank you, your request has been closed");
        navigation.goBack();
    };

    const handleCallAgent = async (phone: string) => {
        callNumber(phone);
    };

    const upLoadImageFromLibrary = async () => {
        let image = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            aspect: [4, 3],
            quality: 1,
        });

        if (image.canceled) {
            return;
        }

        await uploadLeadImageApi.request(lead._id, image.assets[0].uri);
        getLead();
    };

    const handleUpdateLocation = async () => {
        const location = await getCurrentLocation();
        if (!location) return setMessage("Unable to get location");

        const response = await setLocationApi.request(lead._id, location);
        if (response.ok) {
            setMessage("Location Updated");
        } else {
            setMessage("Unable to update location");
        }
    };

    const getLead = async () => {
        let lead = await authStorage.getToken();
        if (lead) {
            lead = jwtDecode(lead);
        }

        if (!lead || !lead._id) {
            setMessage("Unable to load lead \n Please try again");
            return;
        }

        const response = await getLeadApi.request(lead._id);
        if (response.ok) {
            setLead(response.data);
        } else {
            setMessage("Unable to load lead \n Please try again");
            navigation.goBack();
        }
    };

    const handleBoostLead = async () => {
        const response = await boostLeadApi.request(lead._id);
        if (!response.ok) {
            setMessage(response.error);
            return;
        }

        setLead({ ...lead, boosted: true });

        setMessage("Your request has been boosted and sent to more available agents");
    };

    return (
        <Screen loading={getLeadApi.loading}>
            {lead && (
                <ScrollView style={{ flex: 1 }} bounces={false} showsVerticalScrollIndicator={false}>
                    <ListingCard lead={lead && lead} status={lead.status} showImages={lead.images.length > 1} />
                    <Container title={"Add Images"} icon={"image"} onPress={() => upLoadImageFromLibrary()} />

                    {lead.type == "vehicleRecovery" && lead.status == "Booked" && (
                        <Container title={"Update Location"} icon={"map-marker"} onPress={() => handleUpdateLocation()} />
                    )}

                    {lead.agentID && lead.status == "Booked" && (
                        <Container
                            title={"Contact Assigned Agent"}
                            subTitle={lead.agentID.name + "\n" + lead.agentID.contact.phone}
                            icon={"account"}
                            utility={<IconTouchable name="phone" onPress={() => handleCallAgent(lead.agentID.contact.phone)} />}
                        />
                    )}
                    {lead.type == "vehicleParts" && (
                        <Container
                            title={"View Matching Stock"}
                            subTitle={"Contact an agent with matching stock"}
                            icon={"store"}
                            onPress={() => navigation.navigate("BrowseStock", lead._id)}
                        />
                    )}
                    {!lead.boosted && !lead.agentID && (
                        <Container
                            title={"Boost this request!"}
                            subTitle={"We will notify more of our agents about your request"}
                            icon={"arrow-up"}
                            iconColor={colors.green}
                            onPress={() => handleBoostLead()}
                        />
                    )}
                    {!lead.agentID && (
                        <Container title={"Close Request"} subTitle={"Mark the request as closed"} icon={"check"} onPress={() => setMarkAsCompletedModalVisible(true)} />
                    )}
                </ScrollView>
            )}

            <Modal animationType="fade" visible={markAsCompletedModalVisible} transparent={false}>
                <Screen>
                    <AppButtonGoBack onPress={() => setMarkAsCompletedModalVisible(false)} />
                    <Container title={"Rate your experience"} animated={false}>
                        <View style={{ flexDirection: "row", justifyContent: "space-evenly", alignItems: "center" }}>
                            {[1, 2, 3, 4, 5].map((num) => (
                                <IconTouchable
                                    key={num}
                                    name="star"
                                    backgroundColor={colors.white}
                                    iconColor={customerRating >= num ? colors.honeyComb : colors.medium}
                                    borderColor={colors.white}
                                    onPress={() => setCustomerRating(num)}
                                />
                            ))}
                        </View>
                    </Container>
                    {customerRating > 0 && (
                        <>
                            <Container title={"Any feedback?"} animated={false}>
                                <AppTextInput style={{ height: 100 }} onChangeText={setCustomerFeedback} multiline={true} />
                            </Container>
                            {lead.type !== "premiumSalvage" && <Container title={"Mark as complete"} icon={"check"} onPress={() => handleCloseRequest("completed")} />}
                            <Container title={"Close this request"} icon={"close"} onPress={() => handleCloseRequest("cancelled")} />
                        </>
                    )}
                </Screen>
            </Modal>
        </Screen>
    );
}
