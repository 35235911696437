import React, { useState } from "react";
import { View, ScrollView, Linking } from "react-native";
import { useFocusEffect } from "@react-navigation/native";
import Screen from "../components/Screen";
import authApi from "../api/auth";
import useApi from "../hooks/useApi";
import { format, formatDistance, isValid } from "date-fns";
import usersApi from "../api/users";
import DateTimePickerNew from "../components/DateTimePickerNew";
import AppSwitch from "../components/AppSwitch";
import usePopUp from "../hooks/usePopUp";
import Container from "../components/Container";
import useUser from "../hooks/useUser";
import config from "../config/config";
import IconTouchable from "../components/IconTouchable";
import { sendWhatsapp } from "../utility/contactUser";
import Icon from "../components/Icon";
import colors from "../config/colors";
import SubContainer from "../components/SubContainer";
import chatsApi from "../api/chats";
import useToast from "../hooks/useToast";
import { IUser } from "../interfaces/user";
import AppTextInput from "../components/AppTextInput";

export default function UserDetailsScreen({ navigation, route }) {
    const getUserApi = useApi(usersApi.getUser);
    const setUserNextPaymentDateApi = useApi(usersApi.setNextPaymentDate);
    const setUserNameApi = useApi(usersApi.setName);
    const setUserEmailApi = useApi(usersApi.setEmail);
    const setUserPhoneApi = useApi(usersApi.setPhone);
    const setUserLandlineApi = useApi(usersApi.setLandline);
    const setUserNoteApi = useApi(usersApi.setNote);
    const setUserPostcodeApi = useApi(usersApi.setPostcode);
    const setPaypalIDApi = useApi(usersApi.setPaypalID);
    const resetPasswordApi = useApi(authApi.resetPassword);
    const newChatApi = useApi(chatsApi.newChat);
    const promoteDeviceApi = useApi(usersApi.promoteDevice);

    const { user, logOut } = useUser();
    const [email, setEmail] = useState("");
    const [userName, setUserName] = useState("");
    const [mobile, setMobile] = useState("");
    const [landline, setLandline] = useState("");
    const [note, setNote] = useState("");
    const [postcode, setPostcode] = useState("");
    const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
    const { showToast } = useToast();

    const [editUserNameVisible, setEditUserNameVisible] = useState(false);
    const [editEmailVisible, setEditEmailVisible] = useState(false);
    const [editMobileVisible, setEditMobileVisible] = useState(false);
    const [editLandlineVisible, setEditLandlineVisible] = useState(false);
    const [editPostcodeVisible, setEditPostcodeVisible] = useState(false);
    const [editNoteVisible, setEditNoteVisible] = useState(false);

    const cancelPaypalSubscriptionApi = useApi(usersApi.cancelPayPalSubscription);
    const addSubscriptionApi = useApi(usersApi.addSubscription);
    const removeSubscriptionApi = useApi(usersApi.removeSubscription);
    const toggleVerifiedApi = useApi(usersApi.setIsVerified);
    const MMB_ACCOUNT_ID = "631a79abf97a8178ac41376a";
    const { setMessage, confirmDialog } = usePopUp();

    useFocusEffect(
        React.useCallback(() => {
            getUser();
        }, [])
    );

    const refreshPaypalDetails = async (paypalID: string, subType: string) => {
        if (!paypalID) {
            showToast("Please enter a valid Paypal ID", "error");
            return;
        }

        if (paypalID == "BANK") {
            showToast("Unable to refresh paypal details\n this is a bank subscription", "error");
            return;
        }

        if (!selectedUser) {
            return;
        }

        const response = await setPaypalIDApi.request(selectedUser._id, paypalID, subType);
        if (!response.ok) {
            showToast("Unable to refresh paypal details", "error");
            return;
        }
        showToast(subType + " Paypal Details Updated", "success");
        getUser();
    };

    const getUser = async () => {
        const response = await getUserApi.request(route.params.userID);
        if (response.ok) {
            setSelectedUser(response.data);
            setUserName(response.data.name);
        } else {
            navigation.goBack();
        }
    };

    const updateNextPaymentDate = async (date: string, subType: string) => {
        const response = await setUserNextPaymentDateApi.request(route.params.userID, date, subType);
        if (response.ok) {
            getUser();
        }
    };

    const toggleSubscription = async (subscription: string) => {
        if (!selectedUser) {
            return;
        }

        if (selectedUser.subscriptions.includes(subscription)) {
            const response = await removeSubscriptionApi.request(selectedUser._id, subscription);
            if (!response.ok) {
                setMessage("Unable to remove subscription");
            }
        } else {
            const response = await addSubscriptionApi.request(selectedUser._id, subscription);
            if (!response.ok) {
                setMessage("Unable to add subscription");
            }
        }

        showToast(subscription + " subscription Updated", "success");

        getUser();
    };

    const handlePasswordReset = async () => {
        const response = await resetPasswordApi.request(selectedUser.email);
        if (response.ok) {
            setMessage("Password Reset Email Sent");
        } else {
            setMessage("Password Reset Failed, please contact support");
        }
    };

    const handleUpdateName = async () => {
        if (!userName) {
            setMessage("Please enter a valid name");
            return;
        }

        const response = await setUserNameApi.request(selectedUser._id, userName);
        if (!response.ok) {
            setMessage(response.data);
            return;
        }
        getUser();
        setEditUserNameVisible(false);
    };

    const handleUpdateEmail = async () => {
        if (!email) {
            setMessage("Please enter a valid email");
            return;
        }

        const response = await setUserEmailApi.request(selectedUser._id, email);
        if (!response.ok) {
            setMessage(response.data);
            return;
        }
        getUser();
        setEditEmailVisible(false);
    };

    const handleUpdateMobile = async () => {
        if (!mobile) {
            setMessage("Please enter a valid Phone Number");
            return;
        }

        const response = await setUserPhoneApi.request(selectedUser._id, mobile);
        if (!response.ok) {
            setMessage(response.data);
            return;
        }
        getUser();
        setEditMobileVisible(false);
    };

    const handleUpdateLandline = async () => {
        if (!landline) {
            setMessage("Please enter a valid Phone Number");
            return;
        }

        const response = await setUserLandlineApi.request(selectedUser._id, landline);
        if (!response.ok) {
            setMessage(response.data);
            return;
        }
        getUser();
        setEditLandlineVisible(false);
    };

    const handleUpdateNote = async () => {
        if (!note) {
            setMessage("Please enter a valid Note");
            return;
        }

        const response = await setUserNoteApi.request(selectedUser._id, note);
        if (!response.ok) {
            setMessage(response.data);
            return;
        }
        getUser();
        setEditNoteVisible(false);
    };

    const handleUpdatePostcode = async () => {
        if (!postcode) {
            setMessage("Please enter a valid postcode");
            return;
        }

        const response = await setUserPostcodeApi.request(selectedUser._id, postcode);
        if (!response.ok) {
            setMessage(response.data);
            return;
        }
        getUser();
        setEditPostcodeVisible(false);
    };

    const handleCancelSubscription = async (subscriptionID: string, subType: string) => {
        if (!subscriptionID) {
            setMessage("No Active Paypal ID found, unable to cancel subscription\n\n Please contact support");
            return;
        }

        if (subscriptionID == "BANK") {
            setMessage("You can only cancel a bank subscription manually, Please contact support");
            return;
        }

        const response = await cancelPaypalSubscriptionApi.request(subscriptionID, subType, selectedUser._id);
        if (response.ok) {
            showToast("Paypal Subscription Cancelled", "success");
            getUser();
        } else {
            setMessage("Subscription Cancel Failed, Please contact support");
        }
    };

    const handleNewChat = async () => {
        const response = await newChatApi.request([MMB_ACCOUNT_ID, selectedUser._id]);
        if (response.ok) {
            navigation.navigate("Chat", { chatID: response.data, activeUser: MMB_ACCOUNT_ID });
        } else {
            setMessage("Unable to create new chat");
        }
    };

    const toggleVerified = async () => {
        const response = await toggleVerifiedApi.request(selectedUser._id);
        if (!response.ok) {
            showToast("Unable to set verified status", "error");
        }

        showToast("Verified Status Updated", "success");

        getUser();
    };

    const handlePromoteDevice = async (deviceID: string) => {
        const response = await promoteDeviceApi.request(selectedUser._id, deviceID);
        if (!response.ok) {
            showToast("Unable to promote device", "error");
            return;
        }

        showToast("Device Promoted", "success");
        getUser();
    };

    if (!user) return null;

    return (
        <Screen>
            {selectedUser && (
                <ScrollView>
                    <Container title={"Log Out"} icon={"logout"} onPress={() => confirmDialog(() => logOut(), "Are you sure you want to logout?")} />
                    <Container
                        title={"My Stats"}
                        subTitle={"Call history and relevant statistics"}
                        icon={"chart-bell-curve"}
                        onPress={() => navigation.navigate("MyStats", selectedUser._id)}
                    />
                    <Container
                        title={"Email"}
                        subTitle={selectedUser.email}
                        icon={"email"}
                        utility={
                            user.isAdmin && (
                                <>
                                    {editEmailVisible ? (
                                        <IconTouchable name={"check"} onPress={() => handleUpdateEmail()} size={32} />
                                    ) : (
                                        <IconTouchable backgroundColor={colors.red} name={"pencil"} onPress={() => setEditEmailVisible(true)} size={32} />
                                    )}
                                </>
                            )
                        }
                    >
                        {editEmailVisible && user.isAdmin && <AppTextInput autoCapitalize="none" onChangeText={setEmail} placeholder={"Enter New Email"} />}
                    </Container>
                    <Container
                        title={"Name"}
                        subTitle={selectedUser.name}
                        icon={"account"}
                        utility={
                            editUserNameVisible ? (
                                <IconTouchable name={"check"} onPress={() => handleUpdateName()} size={32} />
                            ) : (
                                <IconTouchable name={"pencil"} onPress={() => setEditUserNameVisible(true)} size={32} />
                            )
                        }
                    >
                        {editUserNameVisible && <AppTextInput onChangeText={setUserName} placeholder={"Enter New Name"} />}
                    </Container>
                    <Container
                        title={"Phone"}
                        subTitle={selectedUser.contact.phone}
                        icon={"phone"}
                        utility={
                            editMobileVisible ? (
                                <>
                                    <IconTouchable name={"check"} onPress={() => handleUpdateMobile()} size={32} />
                                </>
                            ) : (
                                <View style={{ flexDirection: "row" }}>
                                    {user.isAdmin && (
                                        <IconTouchable backgroundColor={colors.red} name={"whatsapp"} onPress={() => sendWhatsapp(selectedUser.contact.phone)} size={32} />
                                    )}
                                    <IconTouchable name={"pencil"} onPress={() => setEditMobileVisible(true)} size={32} />
                                </View>
                            )
                        }
                    >
                        {editMobileVisible && <AppTextInput keyboardType={"numeric"} onChangeText={setMobile} placeholder={"Enter New Phone Number"} />}
                    </Container>
                    <Container
                        title={"Landline"}
                        subTitle={selectedUser.contact.landline}
                        icon={"phone-classic"}
                        utility={
                            editLandlineVisible ? (
                                <IconTouchable name={"check"} onPress={() => handleUpdateLandline()} size={32} />
                            ) : (
                                <IconTouchable name={"pencil"} onPress={() => setEditLandlineVisible(true)} size={32} />
                            )
                        }
                    >
                        {editLandlineVisible && <AppTextInput keyboardType="numeric" onChangeText={setLandline} placeholder={"Enter New Phone Number"} />}
                    </Container>
                    <Container
                        title={"Postcode"}
                        subTitle={selectedUser.location.postcode}
                        icon={"map"}
                        utility={
                            <>
                                {editPostcodeVisible ? (
                                    <IconTouchable name={"check"} onPress={() => handleUpdatePostcode()} size={32} />
                                ) : (
                                    <View style={{ flexDirection: "row" }}>
                                        {user.isAdmin && (
                                            <IconTouchable
                                                backgroundColor={colors.red}
                                                name={"map-marker-remove"}
                                                onPress={() =>
                                                    Linking.openURL(
                                                        `https://www.google.com/maps/place/${selectedUser.location.coords.latitude},${selectedUser.location.coords.longitude}`
                                                    )
                                                }
                                                size={32}
                                            />
                                        )}
                                        <IconTouchable name={"pencil"} onPress={() => setEditPostcodeVisible(true)} size={32} />
                                    </View>
                                )}
                            </>
                        }
                    >
                        {editPostcodeVisible && <AppTextInput onChangeText={setPostcode} placeholder={"Enter New Postcode"} />}
                    </Container>

                    <Container title={"Change Password"} icon={"lock-reset"} onPress={() => navigation.navigate("ChangePassword", user.email)} />
                    {/* SUBSCRIPTIONS */}
                    {user.isAdmin && (
                        <>
                            <Container
                                title={"Block User"}
                                subTitle={"Block device, phone and email"}
                                icon={"block-helper"}
                                iconBackgroundColor={colors.red}
                                onPress={() => navigation.navigate("BlockUser", selectedUser)}
                            />
                            <Container
                                title={"Start In-App Chat"}
                                subTitle={"Start a chat with this user"}
                                icon={"chat"}
                                iconBackgroundColor={colors.red}
                                onPress={handleNewChat}
                            />

                            <Container
                                title={"Verified User"}
                                icon={"shield-check"}
                                iconBackgroundColor={colors.red}
                                onPress={toggleVerified}
                                utility={<AppSwitch onChange={toggleVerified} value={selectedUser.isVerified} />}
                            />
                            <Container
                                title={"Notes"}
                                subTitle={selectedUser.note}
                                icon={"note-text"}
                                iconBackgroundColor={colors.red}
                                utility={
                                    editNoteVisible ? (
                                        <IconTouchable name={"check"} onPress={() => handleUpdateNote()} size={32} />
                                    ) : (
                                        <IconTouchable name={"pencil"} onPress={() => setEditNoteVisible(true)} size={32} />
                                    )
                                }
                            >
                                {editNoteVisible && <AppTextInput onChangeText={setNote} placeholder={"Enter new Note"} />}
                            </Container>

                            <Container title={"Send Password Reset Email"} iconBackgroundColor={colors.red} icon={"lock-reset"} onPress={() => handlePasswordReset()} />
                            <Container title={"Device IDs"} icon={"bug"} iconBackgroundColor={colors.red}>
                                {selectedUser.deviceIDs?.length ? (
                                    selectedUser.deviceIDs.map((device, index) => (
                                        <SubContainer
                                            utility={
                                                <>
                                                    <IconTouchable
                                                        name={"magnify"}
                                                        onPress={() => navigation.navigate("MatchingDevices", { device: device.deviceID })}
                                                        size={32}
                                                    />
                                                    {index > 1 && (
                                                        <IconTouchable
                                                            name={"arrow-up"}
                                                            onPress={() => handlePromoteDevice(device.deviceID)}
                                                            size={32}
                                                            iconColor={colors.red}
                                                        />
                                                    )}
                                                </>
                                            }
                                            key={index}
                                            title={device.model || "Unknown Device"}
                                            subTitle={
                                                isValid(new Date(device.lastLoginDate))
                                                    ? "Last Login: " + format(new Date(device.lastLoginDate), "dd-MM-yyyy HH:mm")
                                                    : "Last Login: Never"
                                            }
                                        />
                                    ))
                                ) : (
                                    <SubContainer title={"No Devices"} />
                                )}
                            </Container>
                            {config.subscriptions.map((subscription) => {
                                return (
                                    <Container
                                        animated={false}
                                        key={subscription.name}
                                        title={subscription.nameFormatted}
                                        icon={subscription.iconName}
                                        subTitle={
                                            selectedUser[subscription.subscriptionObject]?.status && "Paypal Status: " + selectedUser[subscription.subscriptionObject]?.status
                                        }
                                        utility={
                                            <AppSwitch onChange={() => toggleSubscription(subscription.name)} value={selectedUser.subscriptions.includes(subscription.name)} />
                                        }
                                    >
                                        <SubContainer
                                            title={"Paypal ID"}
                                            subTitle={selectedUser[subscription.subscriptionObject]?.id ? selectedUser[subscription.subscriptionObject].id : "No ID Found"}
                                            utility={
                                                <>
                                                    <View style={{ paddingRight: 8 }}>
                                                        <IconTouchable
                                                            name={"refresh"}
                                                            onPress={() => refreshPaypalDetails(selectedUser[subscription.subscriptionObject]?.id, subscription.name)}
                                                            size={40}
                                                        />
                                                    </View>
                                                    <IconTouchable
                                                        onPress={() =>
                                                            navigation.navigate("UpdatePaypalID", [
                                                                selectedUser,
                                                                selectedUser[subscription.subscriptionObject]?.id,
                                                                subscription.name,
                                                            ])
                                                        }
                                                        name={"pencil"}
                                                        size={40}
                                                    />
                                                </>
                                            }
                                        />

                                        <SubContainer
                                            title={
                                                selectedUser[subscription.subscriptionObject]?.lastPaymentAmount
                                                    ? "Last Payment" + "   £" + selectedUser[subscription.subscriptionObject]?.lastPaymentAmount
                                                    : "Last Payment"
                                            }
                                            subTitle={
                                                selectedUser[subscription.subscriptionObject]?.lastPaymentDate &&
                                                format(new Date(selectedUser[subscription.subscriptionObject]?.lastPaymentDate), "dd-MM-yyyy") +
                                                    "  -  " +
                                                    formatDistance(new Date(selectedUser[subscription.subscriptionObject]?.lastPaymentDate), new Date(), {
                                                        addSuffix: true,
                                                    })
                                            }
                                        />

                                        <SubContainer
                                            title={"Next Payment Due"}
                                            subTitle={
                                                selectedUser[subscription.subscriptionObject]?.nextPaymentDate &&
                                                format(new Date(selectedUser[subscription.subscriptionObject]?.nextPaymentDate), "dd-MM-yyyy") +
                                                    "  -  " +
                                                    formatDistance(new Date(selectedUser[subscription.subscriptionObject]?.nextPaymentDate), new Date(), {
                                                        addSuffix: true,
                                                    })
                                            }
                                            utility={
                                                <DateTimePickerNew
                                                    onChange={(date: string) => updateNextPaymentDate(date, subscription.name)}
                                                    selectTime={true}
                                                    displayComponent={<Icon borderWidth={2} borderColor={colors.silver} name={"pencil"} size={40} />}
                                                />
                                            }
                                        />

                                        <View style={{ flexDirection: "row" }}>
                                            <View style={{ flex: 1 }}>
                                                <SubContainer
                                                    title={"Transactions"}
                                                    utility={
                                                        <IconTouchable
                                                            onPress={() =>
                                                                navigation.navigate("TransactionHistory", {
                                                                    subscriptionID: selectedUser[subscription.subscriptionObject]?.id,
                                                                    user: selectedUser,
                                                                })
                                                            }
                                                            name={"arrow-right"}
                                                            size={40}
                                                        />
                                                    }
                                                />
                                            </View>
                                            <View style={{ flex: 1 }}>
                                                <SubContainer
                                                    title={"Cancel Sub"}
                                                    utility={
                                                        <IconTouchable
                                                            onPress={() =>
                                                                confirmDialog(
                                                                    () => handleCancelSubscription(selectedUser[subscription.subscriptionObject]?.id, subscription.name),
                                                                    "Are you sure you want to cancel this paypal subscription?"
                                                                )
                                                            }
                                                            iconColor={colors.red}
                                                            name={"trash-can"}
                                                            size={40}
                                                        />
                                                    }
                                                />
                                            </View>
                                        </View>
                                    </Container>
                                );
                            })}

                            <Container title={"Expo Version"} subTitle={selectedUser.expoVersion} icon={"bug"} />
                            <Container title={"Build Version"} subTitle={selectedUser.buildVersion} icon={"bug"} />
                        </>
                    )}
                </ScrollView>
            )}
        </Screen>
    );
}
