import React from "react";
import Screen from "../components/Screen";
import Container from "../components/Container";
import SubContainer from "../components/SubContainer";
import colors from "../config/colors";
import { format, isValid } from "date-fns";
import { ScrollView } from "react-native";
import useUser from "../hooks/useUser";

function VehicleValuationScreen({ route }) {
    const { user } = useUser();

    const mileageAtLastMOT = route.params.mileageAtLastMOT;

    const vehicleYear = route.params.year;

    //how many years between vehicle year and now.
    const ageOfVehicle = new Date().getFullYear() - vehicleYear;

    //divide mileage by years
    const averageMileage = mileageAtLastMOT / ageOfVehicle;

    return (
        <Screen>
            <ScrollView>
                <Container>
                    <SubContainer
                        title={route.params.registration + " - " + route.params.year}
                        icon={new Date(route.params.motDueDate) > new Date() ? "check" : "close"}
                        iconBackgroundColor={new Date(route.params.motDueDate) > new Date() ? colors.green : colors.red}
                        subTitle={isValid(new Date(route.params.motDueDate)) && "Mot Due: " + format(new Date(route.params.motDueDate), "dd-MM-yyyy")}
                    />
                    {user.isAdmin && (
                        <SubContainer
                            title={route.params.mileageAtLastMOT + " Miles"}
                            subTitle={averageMileage > 10000 ? "Too Many Miles" : "Less than 10K a year"}
                            icon={averageMileage > 10000 ? "close" : "check"}
                            iconBackgroundColor={averageMileage > 10000 ? colors.red : colors.green}
                        />
                    )}

                    <SubContainer
                        icon={route.params.isRunning ? "check" : "close"}
                        iconBackgroundColor={route.params.isRunning ? colors.green : colors.red}
                        title={route.params.isRunning ? "Engine Running" : "None Starter"}
                    />
                    <SubContainer icon={"currency-gbp"} title={"OTR Price"} subTitle={route.params.onTheRoad} />
                    <SubContainer icon={"currency-gbp"} title={"Dealer Forecourt"} subTitle={route.params.dealerForecourt} />
                    <SubContainer icon={"currency-gbp"} title={"Trade Retail"} subTitle={route.params.tradeRetail} />
                    <SubContainer icon={"currency-gbp"} title={"Private Clean"} subTitle={route.params.privateClean} />
                    <SubContainer icon={"currency-gbp"} title={"Private Average"} subTitle={route.params.privateAverage} />
                    <SubContainer icon={"currency-gbp"} title={"Trade Average"} subTitle={route.params.tradeAverage} />
                    <SubContainer icon={"currency-gbp"} title={"Auction"} subTitle={route.params.auction} />
                    <SubContainer icon={"currency-gbp"} title={"Trade Poor"} subTitle={route.params.tradePoor} />
                </Container>
            </ScrollView>
        </Screen>
    );
}

export default VehicleValuationScreen;
