import React, { useEffect } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import { LineChart, BarChart } from "react-native-chart-kit";
import colors from "../config/colors";
import Screen from "../components/Screen";
import AppText from "../components/AppText";
import useApi from "../hooks/useApi";
import reportingApi from "../api/reporting";
import { subMonths, format, startOfDay, addDays, endOfDay } from "date-fns";
import Container from "../components/Container";
import { useState } from "react";
import TabSelector from "../components/TabSelector";

function ReportingScreen({}) {
    const getReportingApi = useApi(reportingApi.getReporting);
    const getStatusCountsApi = useApi(reportingApi.getStatusCounts);
    const [reportType, setReportType] = useState("Lead");
    const [reportData, setReportData] = useState({});
    const [statusCounts, setStatusCounts] = useState({});

    useEffect(() => {
        getReports();
    }, []);

    const getReports = async () => {
        const response = await getReportingApi.request();
        if (!response.ok) return;

        setReportData(response.data);
    };

    const handleGetStatusCounts = async (dateRange: string) => {
        const response = await getStatusCountsApi.request(dateRange);
        if (!response.ok) return;

        setStatusCounts(response.data);
    };

    const handleSetReportType = async (type: string) => {
        setReportType(type);
    };

    if (!reportData) return null;
    if (!statusCounts) return null;

    return (
        <Screen loading={getReportingApi.loading || getStatusCountsApi.loading}>
            <ScrollView>
                <Container title={"Select Report Type"}>
                    <TabSelector tabs={[{ name: "Lead" }, { name: "User" }]} onPress={(value) => handleSetReportType(value.name)} />
                </Container>
                {reportType === "Lead" && (
                    <>
                        <Container title="Leads">
                            <TabSelector
                                tabs={[{ name: "yesterday" }, { name: "today" }, { name: "last7" }, { name: "last30" }]}
                                onPress={(value) => handleGetStatusCounts(value.name)}
                            />
                            <View style={[styles.row, { paddingTop: 8 }]}>
                                <View style={styles.cell}>
                                    <AppText style={styles.textSmall}>Type</AppText>
                                    <AppText style={[styles.textSmall, { color: colors.red }]}>Rejected</AppText>
                                    <AppText style={[styles.textSmall, { color: colors.red }]}>Inactive</AppText>
                                    <AppText style={[styles.textSmall, { color: colors.red }]}>Reported</AppText>
                                    <AppText style={[styles.textSmall, { color: colors.red }]}>Cancelled</AppText>
                                    <AppText style={[styles.textSmall, { color: colors.red }]}>Search</AppText>
                                    <AppText style={[styles.textSmall, { color: colors.primary }]}>Quoted</AppText>
                                    <AppText style={[styles.textSmall, { color: colors.primary }]}>Booked</AppText>
                                    <AppText style={[styles.textSmall, { color: colors.green }]}>Completed</AppText>
                                    <AppText style={styles.textSmall}>Total</AppText>
                                    <AppText style={styles.textSmall}>Notified Agents</AppText>
                                    <AppText style={styles.textSmall}>Customer Login</AppText>
                                    <AppText style={styles.textSmall}>Customer Login %</AppText>
                                    <AppText style={styles.textSmall}>Scrappers</AppText>
                                    <AppText style={styles.textSmall}>In-App</AppText>
                                    <AppText style={styles.textSmall}>ppc1</AppText>
                                    <AppText style={styles.textSmall}>ppc2</AppText>
                                    <AppText style={styles.textSmall}>ppc3</AppText>
                                    <AppText style={styles.textSmall}>ppc4</AppText>
                                    <AppText style={styles.textSmall}>ppc5</AppText>
                                    <AppText style={styles.textSmall}>ppc6</AppText>
                                    <AppText style={styles.textSmall}>Uncontacted</AppText>
                                </View>
                                <View style={styles.cell}>
                                    <AppText style={styles.textSmall}>Rec</AppText>
                                    {statusCounts.vehicleRecoveryLeadsByStatus &&
                                        statusCounts.vehicleRecoveryLeadsByStatus.map((data) => (
                                            <AppText key={data._id} style={styles.textSmall}>
                                                {data.count}
                                            </AppText>
                                        ))}
                                </View>
                                <View style={styles.cell}>
                                    <AppText style={styles.textSmall}>Scrap</AppText>
                                    {statusCounts.vehicleScrapLeadsByStatus &&
                                        statusCounts.vehicleScrapLeadsByStatus.map((data) => (
                                            <AppText key={data._id} style={styles.textSmall}>
                                                {data.count}
                                            </AppText>
                                        ))}
                                </View>
                                <View style={styles.cell}>
                                    <AppText style={styles.textSmall}>Salv</AppText>
                                    {statusCounts.vehicleSalvageLeadsByStatus &&
                                        statusCounts.vehicleSalvageLeadsByStatus.map((data) => (
                                            <AppText key={data._id} style={styles.textSmall}>
                                                {data.count}
                                            </AppText>
                                        ))}
                                </View>
                                <View style={styles.cell}>
                                    <AppText style={styles.textSmall}>Prem</AppText>
                                    {statusCounts.premiumSalvageLeadsByStatus &&
                                        statusCounts.premiumSalvageLeadsByStatus.map((data) => (
                                            <AppText key={data._id} style={styles.textSmall}>
                                                {data.count}
                                            </AppText>
                                        ))}
                                </View>
                                <View style={styles.cell}>
                                    <AppText style={styles.textSmall}>Part</AppText>
                                    {statusCounts.vehiclePartsLeadsByStatus &&
                                        statusCounts.vehiclePartsLeadsByStatus.map((data) => (
                                            <AppText key={data._id} style={styles.textSmall}>
                                                {data.count}
                                            </AppText>
                                        ))}
                                </View>
                                <View style={styles.cell}>
                                    <AppText style={styles.textSmall}>Trade</AppText>
                                    {statusCounts.vehicleTradeLeadsByStatus &&
                                        statusCounts.vehicleTradeLeadsByStatus.map((data) => (
                                            <AppText key={data._id} style={styles.textSmall}>
                                                {data.count}
                                            </AppText>
                                        ))}
                                </View>
                                <View style={styles.cell}>
                                    <AppText style={styles.textSmall}>Totals</AppText>
                                    {statusCounts.totalLeadsByStatus &&
                                        statusCounts.totalLeadsByStatus.map((data) => (
                                            <AppText key={data._id} style={styles.textSmall}>
                                                {data.count}
                                            </AppText>
                                        ))}
                                </View>
                            </View>
                        </Container>

                        <Container title="Subscriptions">
                            <BarChart
                                showValuesOnTopOfBars={true}
                                withHorizontalLabels={false}
                                withInnerLines={false}
                                data={{
                                    labels: ["Recov", "Salv", "Part", "Scrap", "premium", "trade", "Total"],
                                    datasets: [
                                        {
                                            data: reportData.subCounts ? reportData.subCounts : [0, 1, 2, 3, 4, 5, 6],
                                            color: (opacity = 1) => `rgba(255, 0, 0, ${opacity})`,
                                        },
                                    ],
                                }}
                                width={440}
                                height={200}
                                chartConfig={{
                                    backgroundGradientFromOpacity: 0,
                                    backgroundGradientToOpacity: 0,
                                    color: (opacity = 1) => `rgba(255, 0, 0, ${opacity})`,
                                    decimalPlaces: 0,
                                    fillShadowGradientOpacity: 1,
                                }}
                                fromZero={true}
                                style={{ marginLeft: -80 }}
                            />
                        </Container>
                        <Container
                            title="Due Payments Next 7 Days"
                            subTitle={`${format(startOfDay(new Date()), "dd-MM-yy HH:mm")} - ${format(endOfDay(addDays(new Date(), 6)), "dd-MM-yy HH:mm")}`}
                        >
                            <BarChart
                                showValuesOnTopOfBars={true}
                                withHorizontalLabels={false}
                                withInnerLines={false}
                                data={{
                                    labels: ["Recov", "Salv", "Part", "Scrap", "Total"],
                                    datasets: [
                                        {
                                            data: reportData.totalPaymentsInNext7Days ? reportData.totalPaymentsInNext7Days : [0, 1, 2, 3, 4],
                                            color: (opacity = 1) => `rgba(255, 0, 0, ${opacity})`,
                                        },
                                    ],
                                }}
                                width={440}
                                height={200}
                                chartConfig={{
                                    backgroundGradientFromOpacity: 0,
                                    backgroundGradientToOpacity: 0,
                                    color: (opacity = 1) => `rgba(255, 0, 0, ${opacity})`,
                                    decimalPlaces: 0,
                                    fillShadowGradientOpacity: 1,
                                }}
                                fromZero={true}
                                style={{ marginLeft: -80 }}
                            />
                        </Container>

                        <Container title={"Calls from customers over last month"} subTitle={reportData.totalCallsFromCustomers || ""} />
                        {reportData.topUncontactedPostcodes && (
                            <Container
                                title="Top 5 Most Uncontacted Postcodes"
                                subTitle={reportData.topUncontactedPostcodes
                                    .map((item) => `${item._id}: ` + item.topPostcodes.map((postcode) => `${postcode.postcodePrefix}: ${postcode.count}`).join(", "))
                                    .join("\n")}
                            />
                        )}
                        {reportData.busiestPostcodes && (
                            <Container
                                title="Top 3 Busiest Postcodes (Most leads)"
                                subTitle={reportData.busiestPostcodes
                                    .map((item) => `${item._id}: ` + item.topPostcodes.map((postcode) => `${postcode.postcodePrefix}: ${postcode.count}`).join(", "))
                                    .join("\n")}
                            />
                        )}
                        <Container
                            title={"Lead"}
                            subTitle={"by status"}
                            toolTip={
                                "These are all the statuses a lead is considered end of life.\n\nReported = lead was reported by more than 2 agents.\nInactive = text message to customer never arrived.\nCancelled = Customer Cancelled the Job.\nCompleted = Agent has marked the job as completed."
                            }
                        >
                            <LineChart
                                data={{
                                    legend: ["Reported", "Inactive", "Cancelled"],
                                    labels: [
                                        format(subMonths(new Date(), 5), "MMM"),
                                        format(subMonths(new Date(), 4), "MMM"),
                                        format(subMonths(new Date(), 3), "MMM"),
                                        format(subMonths(new Date(), 2), "MMM"),
                                        format(subMonths(new Date(), 1), "MMM"),
                                        format(new Date(), "MMM"),
                                    ],
                                    datasets: [
                                        {
                                            data: reportData.totalReportedLeadsByMonth ? reportData.totalReportedLeadsByMonth : [0, 1, 2, 3, 4, 5],
                                            color: (opacity = 1) => `rgba(255, 0, 0, ${opacity})`,
                                            strokeWidth: 5,
                                        },
                                        {
                                            data: reportData.totalInactiveLeadsByMonth ? reportData.totalInactiveLeadsByMonth : [0, 1, 2, 3, 4, 5],
                                            color: (opacity = 1) => `rgba(0, 0, 255, ${opacity})`,
                                            strokeWidth: 5,
                                        },

                                        {
                                            data: reportData.totalCancelledLeadsByMonth ? reportData.totalCancelledLeadsByMonth : [0, 1, 2, 3, 4, 5],
                                            color: (opacity = 1) => `rgba(255, 0, 255, ${opacity})`,
                                            strokeWidth: 5,
                                        },
                                    ],
                                }}
                                width={400}
                                height={200}
                                fromZero={true}
                                withShadow={true}
                                segments={8}
                                chartConfig={{
                                    backgroundGradientFrom: colors.tertiary,
                                    backgroundGradientFromOpacity: 0,
                                    backgroundGradientTo: colors.secondary,
                                    backgroundGradientToOpacity: 0,
                                    color: (opacity = 1) => `rgba(255, 0, 0, ${opacity})`,
                                    decimalPlaces: 0,
                                    useShadowColorFromDataset: true,
                                }}
                                bezier
                                style={{ flex: 1, marginLeft: -20 }}
                            />
                        </Container>

                        <Container title={"Leads"} subTitle={"Booked and Unbooked"}>
                            <LineChart
                                data={{
                                    legend: ["Completed / Booked", "Unbooked"],
                                    labels: [
                                        format(subMonths(new Date(), 5), "MMM"),
                                        format(subMonths(new Date(), 4), "MMM"),
                                        format(subMonths(new Date(), 3), "MMM"),
                                        format(subMonths(new Date(), 2), "MMM"),
                                        format(subMonths(new Date(), 1), "MMM"),
                                        format(new Date(), "MMM"),
                                    ],
                                    datasets: [
                                        {
                                            data: reportData.totalBookedLeadsByMonth ? reportData.totalBookedLeadsByMonth : [0, 1, 2, 3, 4, 5],
                                            color: (opacity = 1) => `rgba(0, 255, 0, ${opacity})`,
                                            strokeWidth: 5,
                                        },

                                        {
                                            data: reportData.totalUnbookedLeadsByMonth ? reportData.totalUnbookedLeadsByMonth : [0, 1, 2, 3, 4, 5],
                                            color: (opacity = 1) => `rgba(255, 0, 0, ${opacity})`,
                                            strokeWidth: 5,
                                        },
                                    ],
                                }}
                                width={400}
                                height={200}
                                fromZero={true}
                                withShadow={true}
                                segments={8}
                                chartConfig={{
                                    backgroundGradientFrom: colors.tertiary,
                                    backgroundGradientFromOpacity: 0,
                                    backgroundGradientTo: colors.secondary,
                                    backgroundGradientToOpacity: 0,
                                    color: (opacity = 1) => `rgba(255, 0, 0, ${opacity})`,
                                    decimalPlaces: 0,
                                    useShadowColorFromDataset: true,
                                }}
                                bezier
                                style={{ flex: 1, marginLeft: -20 }}
                            />
                        </Container>
                        <Container title="Leads Reported this month" subTitle={"By reason"}>
                            <BarChart
                                showValuesOnTopOfBars={true}
                                withHorizontalLabels={false}
                                withInnerLines={false}
                                data={{
                                    labels: ["Number", "Cheaper", "Quote Only", "Cancelled", "Other"],
                                    datasets: [
                                        {
                                            data: reportData.reportCounts ? reportData.reportCounts : [0, 1, 2, 3, 4, 5],
                                            color: (opacity = 1) => `rgba(255, 0, 0, ${opacity})`,
                                        },
                                    ],
                                }}
                                width={400}
                                height={200}
                                chartConfig={{
                                    backgroundGradientFromOpacity: 0,
                                    backgroundGradientToOpacity: 0,
                                    color: (opacity = 1) => `rgba(255, 0, 0, ${opacity})`,
                                    decimalPlaces: 0,
                                    fillShadowGradientOpacity: 1,
                                }}
                                fromZero={true}
                                style={{ alignItems: "center", marginLeft: -60 }}
                            />
                        </Container>
                    </>
                )}

                {reportType === "User" && (
                    <>
                        <Container title={"New Agents"}>
                            <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "flex-end" }}>
                                <LineChart
                                    data={{
                                        legend: ["Agents", "agents with subs"],
                                        labels: [
                                            format(subMonths(new Date(), 6), "MMM"),
                                            format(subMonths(new Date(), 5), "MMM"),
                                            format(subMonths(new Date(), 4), "MMM"),
                                            format(subMonths(new Date(), 3), "MMM"),
                                            format(subMonths(new Date(), 2), "MMM"),
                                            format(subMonths(new Date(), 1), "MMM"),
                                            format(new Date(), "MMM"),
                                        ],
                                        datasets: [
                                            {
                                                data: reportData.totalNewAgentsByMonth ? reportData.totalNewAgentsByMonth : [0, 1, 2, 3, 4, 5, 6],
                                                color: (opacity = 1) => `rgba(255, 0, 0, ${opacity})`,
                                                strokeWidth: 5,
                                            },
                                            {
                                                data: reportData.totalNewAgentsWithSubs ? reportData.totalNewAgentsWithSubs : [0, 1, 2, 3, 4, 5, 6],
                                                color: (opacity = 1) => `rgba(0, 255, 0, ${opacity})`,
                                                strokeWidth: 5,
                                            },
                                        ],
                                    }}
                                    width={430}
                                    height={250}
                                    fromZero={true}
                                    withShadow={true}
                                    yLabelsOffset={10}
                                    segments={12}
                                    chartConfig={{
                                        backgroundGradientFrom: colors.tertiary,
                                        backgroundGradientFromOpacity: 0,
                                        backgroundGradientTo: colors.secondary,
                                        backgroundGradientToOpacity: 0,
                                        color: (opacity = 1) => `rgba(255, 0, 0, ${opacity})`,
                                        decimalPlaces: 0,
                                    }}
                                    bezier
                                    style={{ marginLeft: -30 }}
                                />
                            </View>
                        </Container>
                        <Container title={"User"} subTitle={"Totals"}>
                            <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "flex-end" }}>
                                <BarChart
                                    showValuesOnTopOfBars={true}
                                    withHorizontalLabels={false}
                                    withInnerLines={false}
                                    data={{
                                        labels: ["Total", "Subscribers", "Login Last 7 Days"],
                                        datasets: [
                                            {
                                                data: reportData.userCounts ? reportData.userCounts : [0, 0, 0],
                                                color: (opacity = 1) => `rgba(255, 0, 0, ${opacity})`,
                                            },
                                        ],
                                    }}
                                    width={400}
                                    height={200}
                                    chartConfig={{
                                        backgroundGradientFromOpacity: 0,
                                        backgroundGradientToOpacity: 0,
                                        color: (opacity = 1) => `rgba(255, 0, 0, ${opacity})`,
                                        decimalPlaces: 0,
                                        fillShadowGradientOpacity: 1,
                                    }}
                                    fromZero={true}
                                    style={{ marginLeft: -50 }}
                                />
                            </View>
                        </Container>
                        <Container title={"Average Lead Distance Filter"} subTitle={reportData.leadDistanceFilter} />
                    </>
                )}
            </ScrollView>
        </Screen>
    );
}

const styles = StyleSheet.create({
    text: {
        color: colors.tertiary,
        fontWeight: "900",
        fontSize: 17,
    },
    textSmall: {
        color: colors.tertiary,
        fontWeight: "900",
        fontSize: 11,
    },
    row: {
        backgroundColor: colors.white,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingHorizontal: 10,
    },
    cell: {
        flexDirection: "column",
        alignSelf: "flex-start",
    },
    cell2: {
        flexDirection: "column",
        alignSelf: "flex-start",
        backgroundColor: colors.honeyComb,
    },
    header: {
        justifyContent: "flex-end",
        alignItems: "center",
        flexDirection: "row",
    },
});

export default ReportingScreen;
