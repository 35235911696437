import React, { useState, useEffect, useCallback } from "react";
import { NavigationContainer } from "@react-navigation/native";
import * as SplashScreen from "expo-splash-screen";
import * as Linking from "expo-linking";

import navigationTheme from "./navigation/navigationTheme";
import AppNavigator from "./navigation/AppNavigator";
import AuthNavigator from "./navigation/AuthNavigator";
import { UserContext } from "./context/userContext";
import { LeadsProvider } from "./context/leadsContext";
import { NewsProvider } from "./context/newsContext";
import { LocationProvider } from "./context/locationContext";
import PopUpContext from "./context/popUpContext";
import NotificationsContext from "./context/notificationsContext";
import { ToastProvider } from "./context/toastContext";
import authStorage from "./auth/storage";
import PopUpBox from "./components/PopUpBox";
import Toast from "./components/Toast";
import { Platform } from "react-native";
import { NetworkProvider } from "./context/networkContext";
import { IUser } from "./interfaces/user";
import * as Font from "expo-font";

const prefix = Linking.createURL("/");

SplashScreen.preventAutoHideAsync();

const App = () => {
    const [fontsLoaded] = Font.useFonts({
        SofiaProRegular: require("./assets/fonts/Sofia-Pro-Regular.otf"),
        MaterialCommunityIcons: require("@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf"),
    });

    const [user, setUser] = useState<IUser | null>(null);
    const [notifications, setNotifications] = useState([]);
    const [message, setMessage] = useState();
    const [popUpOnConfirm, setPopUpOnConfirm] = useState();
    const [isReady, setIsReady] = useState(false);

    const linking = {
        prefixes: [prefix],
        config: {
            screens: {
                Landing: "Landing",
                CustomerLogin: "CustomerLogin",
                BrowseStock: "BrowseStock",
                Subscriptions: "Subscriptions",
                Listings: "Listings",
                ListingDetails: "ListingDetails/:leadID",
                UserDetails: "UserDetails/:userID",
                MyChats: "MyChats",
                Chat: "Chat/:activeUser/:chatID",
                CustomerTabNavigator: {
                    screens: {
                        Quotes: "Quotes",
                        Request: "Request",
                    },
                },
            },
        },
    };

    useEffect(() => {
        restoreUser();
    }, []);

    const restoreUser = async () => {
        try {
            const user = (await authStorage.getUser()) as IUser;

            //important to check for user.name to ensure its not a stored lead
            //from the customer side of the app
            if (!user || !user.name) {
                setIsReady(true);
                return;
            }

            setUser(user);

            setIsReady(true);
        } catch (error) {
            setIsReady(true);
            return;
        }
    };

    const onLayoutRootView = useCallback(async () => {
        if (isReady && fontsLoaded) {
            await SplashScreen.hideAsync();
        }
    }, [isReady, fontsLoaded]);

    if (!isReady) return null;
    if (!fontsLoaded) return null;

    return (
        <UserContext.Provider value={{ user, setUser }}>
            <LeadsProvider>
                <NetworkProvider>
                    <LocationProvider>
                        <NewsProvider>
                            <PopUpContext.Provider value={{ message, setMessage, popUpOnConfirm, setPopUpOnConfirm }}>
                                <ToastProvider>
                                    <NotificationsContext.Provider value={{ notifications, setNotifications }}>
                                        <Toast />
                                        <PopUpBox />
                                        <NavigationContainer linking={linking} theme={navigationTheme} onReady={onLayoutRootView}>
                                            {!user ? <AuthNavigator /> : <AppNavigator />}
                                        </NavigationContainer>
                                    </NotificationsContext.Provider>
                                </ToastProvider>
                            </PopUpContext.Provider>
                        </NewsProvider>
                    </LocationProvider>
                </NetworkProvider>
            </LeadsProvider>
        </UserContext.Provider>
    );
};

export default App;
