import React, { useState, createContext } from "react";

const ToastContext = createContext(null);

const ToastProvider = ({ children }) => {
    const [toast, setToast] = useState({ message: "", type: "" });

    return <ToastContext.Provider value={{ toast, setToast }}>{children}</ToastContext.Provider>;
};

export { ToastContext, ToastProvider };
