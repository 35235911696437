import React, { useState } from "react";
import { View, Modal, FlatList } from "react-native";
import Screen from "./Screen";
import Container from "./Container";
import IconTouchable from "./IconTouchable";
import colors from "../config/colors";
import TabSelector from "./TabSelector";
import AppTextInput from "./AppTextInput";
import { HeaderBackButton } from "@react-navigation/stack";

//selectMultiple   - To determine if component should act as a single item selector or multiple item selector
//onChange         - The function to call when the selected item or items changes
//filters          - Available filters that can be used to narrow down the list
//displayProps     - Which items of the model should be displayed in the list view using object index strings like "name", "type" to choose the property
//title            - To allow certain text to be named correctly to help the user understand what the component does (Select "User" instead of Generic "Select Item" for everthing)
//dataItems        - Can be used to pass in a data set instead of using an api call
//modalVisible     - State to display the modal
//setModalVisible  - method to close modal on parent
//defaultFilter    - Set a default value for the filter so that its pre-selected
//hideSearch       - show or hide the search box

export default function Picker({
    selectMultiple = false,
    onChange,
    filters,
    displayProps,
    title = "Item",
    dataItems,
    modalVisible,
    setModalVisible,
    defaultFilter,
    hideSearch = false,
}) {
    const [selectedItems, setSelectedItems] = useState([]);

    const [searchTerm, setSearchTerm] = useState(defaultFilter);

    const handleSelect = async (item) => {
        if (!selectMultiple) {
            onChange([item]);
            setSelectedItems([item]);
            setModalVisible(false);
            return;
        }

        if (selectedItems.some((x) => x[displayProps[0]] == item[displayProps[0]])) {
            setSelectedItems(selectedItems.filter((x) => x !== item));
            return;
        }

        //Add to array
        setSelectedItems([...selectedItems, item]);
        onChange([...selectedItems, item]);
    };

    return (
        <Modal visible={modalVisible} animationType="fade">
            <Screen backgroundColor={colors.tertiary}>
                <View style={{ alignItems: "flex-start" }}>
                    {selectedItems.length > 0 ? (
                        <IconTouchable
                            name="check"
                            onPress={() => {
                                onChange(selectedItems);
                                setModalVisible(false);
                            }}
                        />
                    ) : (
                        <HeaderBackButton labelStyle={{ color: colors.white }} tintColor={colors.red} onPress={() => setModalVisible(false)} />
                    )}
                </View>
                <FlatList
                    data={
                        searchTerm && dataItems
                            ? dataItems.filter((item) => {
                                  if (item[displayProps[0]].toUpperCase().indexOf(searchTerm.toUpperCase()) > -1) {
                                      return item;
                                  }
                                  if (displayProps.length > 1) {
                                      if (item[displayProps[1]].toUpperCase().indexOf(searchTerm.toUpperCase()) > -1) {
                                          return item;
                                      }
                                  }
                              })
                            : dataItems
                    }
                    keyExtractor={(item) => item[displayProps[0]]}
                    ListHeaderComponent={
                        <Container title={title}>
                            {!hideSearch && <AppTextInput placeholder={"Search"} onChangeText={setSearchTerm} />}
                            {filters && (
                                <View style={{ paddingVertical: 8 }}>
                                    <TabSelector
                                        onPress={(value) => () => setSearchTerm(value.name)}
                                        tabs={filters.map((filter) => {
                                            return {
                                                name: filter,
                                            };
                                        })}
                                    />
                                </View>
                            )}
                        </Container>
                    }
                    extraData={searchTerm}
                    renderItem={({ item }) => (
                        <Container
                            title={item[displayProps[0]]}
                            subTitle={item[displayProps[1]]}
                            onPress={async () => handleSelect(item)}
                            backgroundColor={selectedItems.includes(item) ? colors.honeyComb : colors.white}
                        />
                    )}
                />
            </Screen>
        </Modal>
    );
}
