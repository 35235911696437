import React from "react";
import Screen from "../components/Screen";
import { WebView } from "react-native-webview";

function MOTHistoryScreen({ route }) {
    return (
        <Screen>
            <WebView originWhitelist={["*"]} source={{ uri: "https://www.check-mot.service.gov.uk/results?registration=" + route.params }} />
        </Screen>
    );
}

export default MOTHistoryScreen;
