import { format, getDay, isDate } from "date-fns";
import React, { useState } from "react";
import { View, StyleSheet, Modal, FlatList, TouchableOpacity, Pressable } from "react-native";
import Screen from "./Screen";
import IconTouchable from "./IconTouchable";
import AppText from "./AppText";
import colors from "../config/colors";
import Container from "./Container";
import { HeaderBackButton } from "@react-navigation/stack";
import { memo } from "react";

//title            - Set the title to display on the default component
//value            - Set initial display value
//buttonEnabled    - Allow Date to be changed by users
//onChange         - callback function to run when a date is selected
//selectTime       - Enable Time Selection
//displayComponent - replace the default listItem component with your own
//startDate     - override the default starting date (Date.now())
//monthsAhead      - how many months into the future can the user select from (default 24)
//NOTE - This component uses UTC values for the date to avoid display complications with Daylight Savings. When
//NOTE - daylight saving time is observed or another timezone offset is required

export default function DateTimePickerNew({
    title = "Date / Time",
    value = new Date(),
    buttonEnabled = false,
    onChange = (date) => {},
    selectTime = true,
    displayComponent,
    startDate = new Date(),
    monthsAhead = 3,
}) {
    const [selectedDate, setSelectedDate] = useState(new Date(value));

    const [modalVisible, setModalVisible] = useState(false);

    const availableHours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

    const [step, setStep] = useState(1);

    const getDaysOfMonth = (month: Date) => {
        //Get all days of the current month in date format
        const date = new Date(month.getUTCFullYear(), month.getUTCMonth(), 1, 11);
        var daysOfMonth = [];
        while (date.getUTCMonth() === month.getUTCMonth()) {
            daysOfMonth.push(new Date(date));
            date.setUTCDate(date.getUTCDate() + 1);
        }

        //Calculate how many days before the 1st of the month
        const daysBeforeStartOfMonth = getDay(daysOfMonth[0]);

        // Add extra spaces to the day array so that the day headers are spaced correctly
        for (var i = 0; i < daysBeforeStartOfMonth; i++) {
            daysOfMonth.unshift(1);
        }

        return daysOfMonth;
    };

    const availableMonths = [];

    for (var i = 0; i < monthsAhead; i++) {
        //get all months we want to display
        const monthToAdd = format(new Date(startDate), "MMMM yyyy");
        const daysOfMonth = getDaysOfMonth(new Date(startDate));

        availableMonths.push({ month: monthToAdd, days: daysOfMonth });
        startDate.setMonth(startDate.getMonth() + 1);
    }

    const handleSelectedDay = (day: Date) => {
        if (!isDate(day)) return;
        setSelectedDate(day);

        if (!selectTime) {
            updateDate(day, null);
            return;
        }

        setStep(step + 1);
    };

    const updateDate = (date: Date, hours: number) => {
        const offsetHours = hours + new Date(date).getTimezoneOffset() / 60;
        date.setUTCHours(offsetHours);
        setSelectedDate(date);
        onChange(date);

        setStep(1);
        setModalVisible(false);
    };

    const DayItem = memo(({ item }) => (
        <View style={{ flexDirection: "row", borderWidth: 1 }}>
            <AppText onPress={() => handleSelectedDay(item)} style={styles.dayText}>
                {item != 1 && format(new Date(item), "d")}
            </AppText>
        </View>
    ));

    const HourItem = memo(({ item, onPress }) => <Container title={item + ":00"} onPress={() => onPress(item)} />);

    return (
        <View>
            {displayComponent ? (
                <TouchableOpacity onPress={() => setModalVisible(true)}>{displayComponent}</TouchableOpacity>
            ) : (
                <Container
                    title={title}
                    subTitle={
                        selectedDate &&
                        selectedDate.toLocaleDateString(undefined, { year: "numeric", month: "long", day: "numeric" }) +
                            "\n" +
                            (selectTime && selectedDate && isDate(selectedDate) && selectedDate.getUTCHours() + ":00")
                    }
                    utility={buttonEnabled && <IconTouchable name="calendar" onPress={() => setModalVisible(true)} />}
                />
            )}
            <Modal visible={modalVisible} animationType="fade">
                <Screen backgroundColor={colors.tertiary}>
                    {step === 1 && (
                        <>
                            <HeaderBackButton
                                tintColor={colors.red}
                                labelStyle={{ color: colors.white }}
                                onPress={() => {
                                    setStep(1);
                                    setModalVisible(false);
                                }}
                            />
                            <FlatList
                                data={availableMonths}
                                keyExtractor={(item) => item.month?.toString()}
                                contentContainerStyle={{ paddingBottom: 24 }}
                                renderItem={({ item }) => (
                                    <Container title={item.month} animated={false}>
                                        <View style={{ justifyContent: "center", alignItems: "center", paddingVertical: 16 }}>
                                            <FlatList
                                                data={item.days}
                                                numColumns={7}
                                                keyExtractor={(item) => item.toString()}
                                                scrollEnabled={false}
                                                ListHeaderComponent={
                                                    <View
                                                        style={{
                                                            flexDirection: "row",
                                                            backgroundColor: colors.tertiary,
                                                            justifyContent: "space-evenly",
                                                        }}
                                                    >
                                                        <AppText style={styles.headerText}>S</AppText>
                                                        <AppText style={styles.headerText}>M</AppText>
                                                        <AppText style={styles.headerText}>T</AppText>
                                                        <AppText style={styles.headerText}>W</AppText>
                                                        <AppText style={styles.headerText}>T</AppText>
                                                        <AppText style={styles.headerText}>F</AppText>
                                                        <AppText style={styles.headerText}>S</AppText>
                                                    </View>
                                                }
                                                renderItem={({ item }) => (
                                                    <View style={{ flexDirection: "row", borderWidth: 1 }}>
                                                        <AppText onPress={() => handleSelectedDay(item)} style={styles.dayText}>
                                                            {item != 1 && format(new Date(item), "d")}
                                                        </AppText>
                                                    </View>
                                                )}
                                            />
                                        </View>
                                    </Container>
                                )}
                            />
                        </>
                    )}
                    {step === 2 && (
                        <>
                            <HeaderBackButton
                                tintColor={colors.red}
                                labelStyle={{ color: colors.white }}
                                onPress={() => {
                                    setStep(1);
                                }}
                            />
                            <AppText style={{ alignSelf: "center", color: colors.white, fontWeight: "bold", fontSize: 24 }}>
                                {selectedDate && isDate(selectedDate) && selectedDate.toLocaleDateString(undefined, { year: "numeric", month: "long", day: "numeric" })}
                            </AppText>
                            <FlatList
                                data={availableHours}
                                keyExtractor={(item) => item.toString()}
                                renderItem={({ item }) => <HourItem item={item} onPress={() => updateDate(selectedDate, item)} />}
                            />
                        </>
                    )}
                </Screen>
            </Modal>
        </View>
    );
}

const styles = StyleSheet.create({
    dayText: {
        fontWeight: "bold",
        width: 45,
        color: colors.black,
        textAlign: "center",
        padding: 8,
    },
    headerText: {
        fontWeight: "bold",
        width: 45,
        color: colors.white,
        textAlign: "center",
        padding: 8,
    },
});
