import React from "react";
import { View } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import AppText from "./AppText";
import colors from "../config/colors";

interface Props {
    name: any;
    size?: number;
    backgroundColor?: string;
    iconColor?: string;
    borderColor?: string;
    borderWidth?: number;
    iconText?: string;
}

const Icon: React.FC<Props> = ({ name, size = 40, backgroundColor = colors.tertiary, iconColor = colors.white, borderColor, borderWidth = 0, iconText }) => {
    return (
        <View
            style={{
                width: size,
                height: size,
                borderRadius: size / 2,
                backgroundColor,
                justifyContent: "center",
                alignItems: "center",
                borderColor,
                borderWidth,
            }}
        >
            {iconText ? (
                <AppText style={{ color: colors.white, fontWeight: "bold", textTransform: "uppercase" }}>
                    {iconText
                        .split(" ")
                        .map((character: string) => character[0])
                        .join("")}
                </AppText>
            ) : (
                <MaterialCommunityIcons name={name} color={iconColor} size={size * 0.525} />
            )}
        </View>
    );
};

export default Icon;
