// React
import React, { useEffect, useState, useRef } from "react";
import { KeyboardAvoidingView, ScrollView, View, Platform, TextInput } from "react-native";

import { format } from "date-fns";
// Components
import AppText from "../../components/AppText";
import Container from "../../components/Container";
import IconTouchable from "../../components/IconTouchable";
import Screen from "../../components/Screen";
// API
import chatsApi from "../../api/chats";
import useApi from "../../hooks/useApi";
// Config
import colors from "../../config/colors";
import config from "../../config/config";
import defaultStyles from "../../config/defaultStyles";

function CustomerChatScreen({ navigation, route }) {
    const sendMessageCustomerApi = useApi(chatsApi.sendMessageCustomer);
    const getChatApi = useApi(chatsApi.getChat);

    const [newMessage, setNewMessage] = useState("");
    const [chat, setChat] = useState();

    const scrollView = useRef(null);

    useEffect(() => {
        refreshChat();
        const autoRefresh = setInterval(() => {
            refreshChat();
        }, 10000);

        return () => clearInterval(autoRefresh);
    }, []);

    const refreshChat = async () => {
        const response = await getChatApi.request(route.params.chatID);
        if (!response.ok) {
            return;
        }

        setChat(response.data);
    };

    const handleSendMessage = async (message: string) => {
        if (!message) {
            return;
        }

        const response = await sendMessageCustomerApi.request(message, chat._id);
        if (response.ok) {
            setNewMessage("");
            refreshChat();
            if (!scrollView) return;

            scrollView?.current?.scrollToEnd({ animated: true });
        }
    };

    const getMessageSenderName = (senderID) => {
        const index = chat.users.findIndex((x) => x._id == senderID);
        if (index >= 0) {
            return chat.users[index].name;
        } else {
            return "Customer";
        }
    };

    return (
        <KeyboardAvoidingView behavior={Platform.OS === "ios" ? "padding" : "height"} style={{ flex: 1, backgroundColor: colors.tertiary }} keyboardVerticalOffset={104}>
            <Screen backgroundColor={colors.tertiary}>
                <ScrollView showsVerticalScrollIndicator={false} ref={scrollView} onContentSizeChange={() => scrollView?.current?.scrollToEnd({ animated: true })}>
                    {chat?.messages &&
                        chat.messages.map((message) => (
                            <View
                                key={message._id}
                                style={{
                                    alignItems: message.sender == chat.leadID ? "flex-end" : "flex-start",
                                    paddingLeft: message.sender == chat.leadID ? 40 : 0,
                                    paddingRight: message.sender == chat.leadID ? 0 : 40,
                                }}
                            >
                                <View style={{ width: "100%" }}>
                                    <Container
                                        animated={false}
                                        title={getMessageSenderName(message.sender) + " " + format(new Date(message.createdDate), "   MMM-dd HH:mm")}
                                        titleColor={!message.isNote ? colors.white : colors.red}
                                        backgroundColor={message.sender == chat.leadID ? "#0A4B3A" : "#192127"}
                                    >
                                        <AppText selectable={true} style={{ fontWeight: "bold", color: colors.white }}>
                                            {message.body}
                                        </AppText>
                                    </Container>
                                </View>
                            </View>
                        ))}
                </ScrollView>

                <View
                    style={{
                        backgroundColor: colors.tertiary,
                        flexDirection: "column",
                        borderTopColor: colors.light,
                        borderTopWidth: 0.25,
                        paddingHorizontal: 16,
                        paddingVertical: 8,
                    }}
                >
                    <View
                        style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            backgroundColor: colors.light,
                            padding: 4,
                            borderRadius: defaultStyles.borderRadius,
                        }}
                    >
                        <TextInput
                            placeholder="Type a message"
                            style={[config.text, { width: "75%", marginLeft: 8, maxHeight: 150 }]}
                            multiline={true}
                            value={newMessage}
                            onChangeText={(text) => setNewMessage(text)}
                            onFocus={() => setTimeout(() => scrollView?.current?.scrollToEnd({ animated: true }), 200)}
                            placeholderTextColor="grey"
                            hitSlop={{ top: 20, bottom: 20, left: 40, right: 40 }}
                            textAlign={"left"}
                        />
                        <IconTouchable name={"send"} actionBeforeAnimate={true} size={40} borderColor={"transparent"} onPress={() => handleSendMessage(newMessage)} />
                    </View>
                </View>
            </Screen>
        </KeyboardAvoidingView>
    );
}

export default CustomerChatScreen;
