import React, { useState, useRef } from "react";
import { View, FlatList, TouchableOpacity, Image } from "react-native";
import { Video } from "expo-av";
import colors from "../config/colors";
import Screen from "../components/Screen";
import Icon from "../components/Icon";

export default function ViewImagesScreen({ navigation, route }) {
    const [imageViewerImages, setImageViwerImages] = useState(route.params.images);

    const IMAGE_SIZE = 80;
    const video = useRef(null);
    const [imageIndex, setImageIndex] = useState(0);

    return (
        <Screen>
            {imageViewerImages[imageIndex]?.slice(-4) == ".mov" ? (
                <Video
                    ref={video}
                    style={{
                        flex: 1,
                    }}
                    source={{
                        uri: imageViewerImages[imageIndex],
                    }}
                    useNativeControls
                />
            ) : (
                <Image source={{ uri: imageViewerImages[imageIndex] }} resizeMode="contain" cachePolicy="memory" style={{ flex: 1 }} transition={500} />
            )}

            <View style={{ zIndex: 2, marginTop: 16, marginHorizontal: 16 }}>
                <FlatList
                    data={imageViewerImages}
                    keyExtractor={(item) => item}
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    renderItem={({ item, index }) => {
                        return (
                            <TouchableOpacity onPress={() => setImageIndex(index)}>
                                <View
                                    style={{
                                        width: IMAGE_SIZE,
                                        height: IMAGE_SIZE,
                                        borderRadius: 8,
                                        marginRight: 16,
                                        borderWidth: 4,
                                        borderColor: index == imageIndex ? colors.honeyComb : colors.tertiary,
                                        backgroundColor: colors.tertiary,
                                    }}
                                >
                                    {item.slice(-4) !== ".mov" ? (
                                        <Image
                                            source={{ uri: item }}
                                            style={{
                                                flex: 1,
                                            }}
                                            resizeMode="contain"
                                        />
                                    ) : (
                                        <Icon name={"video"} />
                                    )}
                                </View>
                            </TouchableOpacity>
                        );
                    }}
                />
            </View>
        </Screen>
    );
}
