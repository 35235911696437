import { useContext, useState } from "react";
import { Platform } from "react-native";
import jwtDecode from "jwt-decode";

import { UserContext } from "../context/userContext";
import { LeadsContext } from "../context/leadsContext";
import authStorage from "../auth/storage";
import usersApi from "../api/users";

import useApi from "./useApi";
import config from "../config/config";

import { getModel, getUniqueId } from "react-native-device-info";
import useToast from "./useToast";

const useUser = () => {
    const { user, setUser } = useContext(UserContext);
    const { setLeadTypeFilters, setLeadDistanceFilter } = useContext(LeadsContext);
    const [loading, setLoading] = useState();
    const getConfigApi = useApi(usersApi.getConfig);
    const { showToast } = useToast();

    const logIn = (authToken) => {
        try {
            const user = jwtDecode(authToken);
            authStorage.storeToken(authToken);
            setUser(user);
        } catch (error) {
            console.log(error);
        }
    };

    const logOut = () => {
        authStorage.removeToken();
        setUser(null);
    };

    const getConfig = async () => {
        var deviceID = "Web";
        var deviceModel = "Unknown";
        if (Platform.OS !== "web") {
            deviceID = await getUniqueId();
            deviceModel = getModel();
        }

        try {
            const response = await getConfigApi.request(config.versionNo, config.latestBuildNo, deviceID, deviceModel);
            if (!response.ok) {
                if (response.status == 403) {
                    logOut();
                }
                if (response.status == 409) {
                    showToast("You have too many devices logged in. Please contact support at www.makemebusy.com", "error", 5000);
                    logOut();
                }
                return;
            }

            user.subscriptions = response.data.subscriptions;
            user.userReviewDate = response.data.userReviewDate;
            user.profileImage = response.data.profileImage;
            user.contact = response.data.contact;
            user.uniqueID = response.data.uniqueID;
            user.name = response.data.name;
            user.email = response.data.email;
            user.leadTypeFilters = response.data.leadTypeFilters;
            user.leadDistanceFilter = response.data.leadDistanceFilter;
            user.isVerified = response.data.isVerified;
            user.deviceIDs = response.data.deviceIDs;
            setUser(user);

            setLeadTypeFilters(response.data.leadTypeFilters);
            setLeadDistanceFilter(response.data.leadDistanceFilter);
        } catch (error) {
            console.log(error);
            logOut();
        }
    };

    return { user, setUser, getConfig, logIn, logOut, loading };
};

export default useUser;
