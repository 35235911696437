import React, { useEffect } from "react";
import { View } from "react-native";
import Animated, { useSharedValue, useAnimatedStyle, withSpring, withTiming } from "react-native-reanimated";
import colors from "../config/colors";
import AppText from "./AppText";
import SubContainer from "./SubContainer";

export default function AnimatedFullScreenCheck({
    title,
    line1,
    line2,
    line3,
    onPress,
}: {
    title: string;
    line1?: string;
    line2?: string;
    line3?: string;
    onPress: () => void;
}) {
    const slideLeft = useSharedValue(-400);
    const slideRight = useSharedValue(+400);
    const fade = useSharedValue(0);

    useEffect(() => {
        slideLeft.value = withSpring(1, {}, () => (slideRight.value = withSpring(1, {}, () => (fade.value = withTiming(1)))));
    }, []);

    const line1AnimatedStyle = useAnimatedStyle(() => {
        return {
            transform: [{ translateX: slideLeft.value }],
            width: "75%",
        };
    });

    const line2AnimatedStyle = useAnimatedStyle(() => {
        return {
            transform: [{ translateX: slideRight.value }],
            width: "75%",
        };
    });

    const buttonAnimatedStyle = useAnimatedStyle(() => {
        return {
            opacity: fade.value,
            width: "75%",
            paddingTop: 40,
        };
    });

    return (
        <>
            <View
                style={{
                    backgroundColor: colors.green,
                    position: "absolute",
                    zIndex: 20,
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                    paddingTop: 100,
                }}
            >
                <AppText style={{ fontSize: 32, fontWeight: "bold", color: colors.white, textAlign: "center", paddingBottom: 40 }}>{title}</AppText>
                {line1 && (
                    <Animated.View style={line1AnimatedStyle}>
                        <SubContainer title={line1} icon={"cash"} iconBackgroundColor={"transparent"} iconColor={colors.green}></SubContainer>
                    </Animated.View>
                )}
                {line2 && (
                    <Animated.View style={line2AnimatedStyle}>
                        <SubContainer title={line2} icon={"star-circle"} iconBackgroundColor={"transparent"} iconColor={colors.green}></SubContainer>
                    </Animated.View>
                )}
                {line3 && (
                    <Animated.View style={line1AnimatedStyle}>
                        <SubContainer title={line3} icon={"alert"} iconBackgroundColor={"transparent"} iconColor={colors.green}></SubContainer>
                    </Animated.View>
                )}
                <Animated.View style={buttonAnimatedStyle}>
                    <SubContainer icon={"check"} iconBackgroundColor="transparent" iconColor={colors.green} title={"Tap to Continue"} onPress={onPress}></SubContainer>
                </Animated.View>
            </View>
        </>
    );
}
