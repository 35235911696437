import React from "react";
import { Pressable, StyleSheet } from "react-native";

import AppText from "./AppText";
import colors from "../config/colors";
import defaultStyles from "../config/defaultStyles";
import Animated, { useAnimatedStyle, useSharedValue, withSpring } from "react-native-reanimated";

interface Props extends React.ComponentProps<typeof Pressable> {
    title: string;
    style?: object;
    onPress: () => void;
}

export default function AppButton({ title, style, onPress, ...otherProps }: Props) {
    const spring = useSharedValue(1);

    const animatedStyle = useAnimatedStyle(() => {
        return {
            transform: [{ scale: spring.value }],
        };
    });

    const handlePress = () => {
        spring.value = 0.95;
        spring.value = withSpring(1, { damping: 1 });
        setTimeout(() => {
            if (onPress) {
                onPress();
            }
        }, 200);
    };

    return (
        <Animated.View style={animatedStyle}>
            <Pressable onPressIn={handlePress} style={[styles.container, style]} {...otherProps}>
                <AppText style={styles.text}>{title}</AppText>
            </Pressable>
        </Animated.View>
    );
}

const styles = StyleSheet.create({
    container: {
        padding: 12,
        borderRadius: defaultStyles.borderRadius,
        maxWidth: 600,
        shadowColor: colors.black,
        backgroundColor: colors.top2,
        shadowOffset: {
            width: 0,
            height: 4,
        },
        shadowOpacity: 0.5,
        elevation: 5,
        alignItems: "center",
        borderColor: colors.silver,
    },
    text: {
        color: colors.white,
    },
});
