import client from "./client";

const login = (email: string, password: string) => client.post("/auth", { email, password });
const customerLogin = (id: string, pin: string) => client.post("/auth/customerLogin", { id, pin });
const resetPassword = (email: string) => client.post("/auth/resetPassword", { email });
const adminPasswordReset = (email: string, newPassword: string) => client.post("/auth/adminPasswordReset", { email, newPassword });
const isExistingUser = (email: string) => client.post("/auth/isExistingUser/", { email });
const sendOTC = (phone: string) => client.post("/auth/sendOTC", { phone });
const validateOTC = (phone: string, code: string) => client.post("/auth/validateOTC", { phone, code });
const block = (itemToBlock: string, type: string, reason: string) => client.post("/auth/block", { itemToBlock, type, reason });

export default {
    login,
    resetPassword,
    adminPasswordReset,
    customerLogin,
    isExistingUser,
    sendOTC,
    validateOTC,
    block,
};
