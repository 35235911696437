import React from "react";
import IconTouchable from "./IconTouchable";
import { useNavigation } from "@react-navigation/native";

type Props = {
    onPress?: () => void;
};

export default function AppButtonGoBack({ onPress }: Props) {
    const navigation = useNavigation();

    return <IconTouchable name="arrow-left" onPress={onPress ? onPress : () => navigation.goBack()} />;
}
