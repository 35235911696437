import React, { useState } from "react";

import Screen from "../components/Screen";

import useApi from "../hooks/useApi";
import newsApi from "../api/news";
import { KeyboardAvoidingView, Platform, ScrollView } from "react-native";
import Container from "../components/Container";
import AppSwitch from "../components/AppSwitch";
import useToast from "../hooks/useToast";
import AppTextInput from "../components/AppTextInput";

function SendNewsScreen({ navigation }) {
    const [title, setTitle] = useState("");
    const [body, setBody] = useState("");
    const [url, setUrl] = useState("https://www.makemebusy.com");

    const [allUsersSelected, setAllUsersSelected] = useState(false);
    const [subscribersSelected, setSubscribersSelected] = useState(false);
    const [freeUsersSelected, setFreeUsersSelected] = useState(false);

    const { showToast } = useToast();

    const addNewsApi = useApi(newsApi.addNews);

    const handleSendNews = async () => {
        if (!title || !body) {
            showToast("Please enter a title and body", "error");
            return;
        }

        let audience = [];

        if (!allUsersSelected && !subscribersSelected && !freeUsersSelected) {
            showToast("Please select an audience", "error");
            return;
        }

        if (allUsersSelected) {
            audience.push("everyone");
        }

        if (subscribersSelected) {
            audience.push("subscribers");
        }

        if (freeUsersSelected) {
            audience.push("free users");
        }

        const response = await addNewsApi.request(audience, title, body, url);
        if (response.ok) {
            showToast("News Updated", "success");
            navigation.goBack();
        } else {
            showToast("Failed to Update News", "error");
        }
    };

    return (
        <Screen>
            <ScrollView>
                <KeyboardAvoidingView behavior={Platform.OS === "ios" ? "padding" : "height"} style={{ flex: 1 }}>
                    <Container title={"Title"} animated={false}>
                        <AppTextInput placeholder={"Enter Title"} blurOnSubmit={true} onChangeText={setTitle} multiline={true} autoCorrect={false} />
                    </Container>
                    <Container title={"Body"} animated={false}>
                        <AppTextInput placeholder={"Enter Body"} onChangeText={setBody} multiline={true} autoCorrect={true} />
                    </Container>
                    <Container title={"Url"} animated={false}>
                        <AppTextInput
                            placeholder={"Enter Url"}
                            blurOnSubmit={true}
                            onChangeText={setUrl}
                            multiline={true}
                            autoCorrect={false}
                            value={"https://www.makemebusy.com"}
                        />
                    </Container>
                    <Container
                        title={"All Users"}
                        icon={"account-group"}
                        utility={<AppSwitch value={allUsersSelected} onChange={() => setAllUsersSelected((prevValue) => !prevValue)} />}
                    />
                    <Container
                        title={"Subscribers"}
                        icon={"cash"}
                        utility={<AppSwitch value={subscribersSelected} onChange={() => setSubscribersSelected((prevValue) => !prevValue)} />}
                    />
                    <Container
                        title={"Free Users"}
                        icon={"account"}
                        utility={<AppSwitch value={freeUsersSelected} onChange={() => setFreeUsersSelected((prevValue) => !prevValue)} />}
                    />
                    <Container title={"Confirm Details"} onPress={() => handleSendNews()} icon={"check"} />
                </KeyboardAvoidingView>
            </ScrollView>
        </Screen>
    );
}

export default SendNewsScreen;
