// React Packages
import React, { useState } from "react";
import { KeyboardAvoidingView, Platform, ScrollView } from "react-native";
// Custom Components

import useApi from "../hooks/useApi";

// Styles and config
import Screen from "../components/Screen";
import useToast from "../hooks/useToast";
import leadsApi from "../api/leads";
import AppTextInput from "../components/AppTextInput";
import useUser from "../hooks/useUser";
import ContainerNew from "../components/ContainerNew";
import AppButton from "../components/AppButton";

export default function NewRecoveryPostScreen({ navigation }) {
    const newLeadApi = useApi(leadsApi.newLead);
    const { showToast } = useToast();
    const { user } = useUser();

    const [registration, setRegistration] = useState("");
    const [pickupPostcode, setPickupPostcode] = useState("");
    const [dropOffPostcode, setDropOffPostcode] = useState("");
    const [workRequired, setWorkRequired] = useState("");
    const [passengers, setPassengers] = useState(0);

    const handleConfirmRequest = async () => {
        if (!pickupPostcode) {
            showToast("Invalid Pickup Postcode", "error");
            return;
        }

        if (!dropOffPostcode) {
            showToast("Invalid Delivery Postcode", "error");
            return;
        }

        if (!registration) {
            showToast("Invalid Registration", "error");
            return;
        }

        if (!workRequired) {
            showToast("Invalid Work Required", "error");
            return;
        }

        if (isNaN(passengers)) {
            showToast("Invalid Passengers", "error");
            return;
        }

        const response = await newLeadApi.request(registration, pickupPostcode, user.contact.phone, workRequired, "vehicleRecovery", dropOffPostcode, "app", passengers);
        if (!response.ok) {
            if (response.data) {
                showToast(response.data, "error");
                return;
            }
            showToast("Something went wrong, please check the details you entered are correct", "error");
            return;
        }

        setRegistration("");
        setPickupPostcode("");
        setDropOffPostcode("");
        setWorkRequired("");
        setPassengers(0);

        navigation.navigate("ListingDetails", { leadID: response.data });
    };

    return (
        <Screen loading={newLeadApi.loading}>
            <KeyboardAvoidingView behavior={Platform.OS === "ios" ? "padding" : "height"} style={{ flex: 1 }} keyboardVerticalOffset={104}>
                <ScrollView contentContainerStyle={{ margin: 8, rowGap: 8 }}>
                    <ContainerNew
                        icon="tow-truck"
                        title={"New Recovery Request"}
                        style={{ rowGap: 8 }}
                        subTitle={"we'll use your in-app contact information to complete the listing."}
                    >
                        <AppTextInput autoFocus={true} icon={"map-marker"} placeholder={"Pickup Postcode"} onChangeText={setPickupPostcode} />
                        <AppTextInput icon={"map-marker"} placeholder={"Delivery Postcode"} onChangeText={setDropOffPostcode} />
                        <AppTextInput icon={"car"} placeholder={"Vehicle Registration"} onChangeText={setRegistration} />
                        <AppTextInput
                            icon={"account-group"}
                            keyboardType="numeric"
                            placeholder={"Number of Passengers"}
                            onChangeText={(text) => setPassengers(parseInt(text))}
                        />
                        <AppTextInput
                            icon={"engine-outline"}
                            multiline={true}
                            style={{ height: 100 }}
                            placeholder={"problems, accident, engine failure, jumpstart, will roll, etc"}
                            onChangeText={setWorkRequired}
                            returnKeyType="done"
                            blurOnSubmit={true}
                        />
                    </ContainerNew>
                    <AppButton title={"Confirm Details"} onPress={handleConfirmRequest} style={{ marginTop: 12 }} disabled={newLeadApi.loading} />
                </ScrollView>
            </KeyboardAvoidingView>
        </Screen>
    );
}
