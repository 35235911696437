// React
import React, { useEffect, useState, useRef } from "react";
import { Platform, StyleSheet, Text, View } from "react-native";
import MapView, { Marker } from "react-native-maps";
// Components
import Screen from "../components/Screen";
// Api
// Config
import useApi from "../hooks/useApi";
import usersApi from "../api/users";
import leadsApi from "../api/leads";
import { IUser } from "../interfaces/user";
import config from "../config/config";
import colors from "../config/colors";
import IconTouchable from "../components/IconTouchable";
import AppText from "../components/AppText";
import AppSwitch from "../components/AppSwitch";
import { ILead } from "../interfaces/lead";

export const UsersMapScreen = ({ navigation }) => {
    const [markers, setMarkers] = useState([]);
    const [selectedType, setSelectedType] = useState("vehicleRecovery");
    const [leadMarkers, setLeadMarkers] = useState([]);
    const [showUsers, setShowUsers] = useState(false);
    const [showLeads, setShowLeads] = useState(true);
    const [showContacted, setShowContacted] = useState(true);

    const mapRef = useRef();

    const getUsersBySubscriptionApi = useApi(usersApi.getUsersBySubscription);
    const getLeadsByTypeApi = useApi(leadsApi.getLeadsByType);

    useEffect(() => {
        loadMarkers();
    }, [selectedType, showUsers, showLeads, showContacted]);

    const loadMarkers = async () => {
        setMarkers([]);
        setLeadMarkers([]);

        if (showUsers) {
            const response = await getUsersBySubscriptionApi.request(selectedType);
            if (response.ok) {
                setMarkers(response.data);
            }
        }

        var leads = [];
        if (showLeads) {
            const leadsResponse = await getLeadsByTypeApi.request(selectedType);
            if (leadsResponse.ok) {
                leads = leadsResponse.data;
                if (!showContacted) {
                    leads = leadsResponse.data.filter((lead: ILead) => lead.availableAgents?.length < 1);
                }

                setLeadMarkers(leads);
            }
        }
    };

    return (
        <Screen>
            {markers && (
                <MapView
                    ref={mapRef}
                    style={styles.map}
                    mapType={"standard"}
                    initialRegion={{
                        latitude: 54,
                        longitude: -2.5,
                        latitudeDelta: 9.5,
                        longitudeDelta: 9.5,
                    }}
                    initialCamera={{
                        center: {
                            latitude: 54,
                            longitude: -2.5,
                        },
                        pitch: 0,
                        heading: 0,
                        altitude: 10000,
                        zoom: 7,
                    }}
                    googleMapsApiKey={"AIzaSyD86oxaEq4TTV77dO3hST84a2yc1q3BDKM"}
                    minZoomLevel={4}
                    pitchEnabled={false}
                    showsUserLocation={true}
                    rotateEnabled={false}
                    provider={Platform.OS === "web" || Platform.OS === "android" ? "google" : MapView.PROVIDER_APPLE}
                >
                    {showUsers &&
                        markers.map((user: IUser) => (
                            <View key={user._id}>
                                {user && (
                                    <Marker
                                        coordinate={user.location.coords}
                                        title={user.name}
                                        description={user.subscriptions?.length > 0 ? user.subscriptions[0].name : "No Subscription"}
                                        tappable={true}
                                        pinColor="blue"
                                        onCalloutPress={() => navigation.navigate("UserDetails", { userID: user._id })}
                                    />
                                )}
                            </View>
                        ))}
                    {showLeads &&
                        leadMarkers.map((lead: ILead) => (
                            <View key={lead._id}>
                                {
                                    <Marker
                                        coordinate={lead.location.coords}
                                        tappable={true}
                                        title={lead.vehicle.registration}
                                        onCalloutPress={() => navigation.navigate("ListingDetails", { leadID: lead._id })}
                                    />
                                }
                            </View>
                        ))}
                </MapView>
            )}
            <View style={{ flexDirection: "column", padding: 8, backgroundColor: colors.light }}>
                <View style={{ flexDirection: "row", justifyContent: "space-evenly" }}>
                    <View style={{ alignItems: "center" }}>
                        <AppSwitch value={showUsers} onChange={() => setShowUsers(!showUsers)} />
                        <AppText>Users</AppText>
                    </View>
                    <View style={{ alignItems: "center" }}>
                        <AppSwitch value={showLeads} onChange={() => setShowLeads(!showLeads)} />
                        <AppText>Leads</AppText>
                    </View>
                    <View style={{ alignItems: "center" }}>
                        <AppSwitch value={showContacted} onChange={() => setShowContacted(!showContacted)} />
                        <AppText>Contacted</AppText>
                    </View>
                </View>
                <View style={{ flexDirection: "row", justifyContent: "space-evenly", paddingVertical: 8 }}>
                    {config.subscriptions.map((subscription) => (
                        <View key={subscription.name}>
                            <IconTouchable
                                name={subscription.iconName}
                                iconColor={selectedType == subscription.name ? colors.green : undefined}
                                backgroundColor={colors.tertiary}
                                size={40}
                                onPress={() => setSelectedType(subscription.name)}
                            />
                        </View>
                    ))}
                </View>
                <AppText style={{ textAlign: "center" }}>{selectedType}</AppText>
            </View>
        </Screen>
    );
};

const styles = StyleSheet.create({
    map: {
        flex: 1,
    },
    text: {
        alignSelf: "center",
        fontSize: 20,
        fontWeight: "900",
    },
});
