import { Platform } from "react-native";

import * as ImagePicker from "expo-image-picker";

const useMedia = () => {
    //PROMPT USER FOR PERMISSION TO ACCESS CAMERA / MEDIA LIBRARY
    const requestMediaPermissions = async () => {
        if (Platform.OS !== "web") {
            const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
            if (status !== "granted") {
                return;
            }
        }

        if (Platform.OS !== "web") {
            const { status } = await ImagePicker.requestCameraPermissionsAsync();
            if (status !== "granted") {
                return;
            }
        }
    };

    const selectImageFromLibrary = async () => {
        try {
            await requestMediaPermissions();
            let imageData = await ImagePicker.launchImageLibraryAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.Images,
                aspect: [4, 3],
                quality: 0.5,
            });

            if (imageData.canceled) {
                return false;
            }

            return imageData.assets[0];
        } catch (error) {
            return false;
        }
    };

    const selectImageFromCamera = async () => {
        try {
            await requestMediaPermissions();

            var imageData = await ImagePicker.launchCameraAsync({
                aspect: [4, 3],
                quality: 0.3,
            });

            if (imageData.canceled) {
                return false;
            }

            return imageData.assets[0];
        } catch (error) {
            return false;
        }
    };

    return { selectImageFromCamera, selectImageFromLibrary, requestMediaPermissions };
};

export default useMedia;
