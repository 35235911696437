import React, { useState } from "react";
import { FlatList, View } from "react-native";

import Screen from "../components/Screen";
import useApi from "../hooks/useApi";
import leadsApi from "../api/leads";
import ListingCard from "../components/ListingCard";
import usePopUp from "../hooks/usePopUp";
import Container from "../components/Container";
import AppTextInput from "../components/AppTextInput";
import IconTouchable from "../components/IconTouchable";
import { ILead } from "../interfaces/lead";

function FindLeadScreen({ navigation }: { navigation: any }) {
    const { setMessage } = usePopUp();

    const findLeadsApi = useApi(leadsApi.find);
    const [leads, setLeads] = useState([]);
    const [registration, setRegistration] = useState("");

    const handleSubmit = async () => {
        const response = await findLeadsApi.request(registration);
        if (!response.ok) {
            setMessage("No Leads Found");
            return;
        }

        setLeads(response.data);
    };

    return (
        <Screen>
            <Container animated={false} title={"Find A Lead"}>
                <View style={{ flexDirection: "row", justifyContent: "center" }}>
                    <View style={{ flex: 1, paddingRight: 8 }}>
                        <AppTextInput placeholder="Enter Registration" onChangeText={setRegistration} />
                    </View>
                    <IconTouchable size={40} name="magnify" onPress={handleSubmit} />
                </View>
            </Container>
            <FlatList
                data={leads}
                keyExtractor={(item: ILead) => item._id}
                horizontal={false}
                showsVerticalScrollIndicator={false}
                renderItem={({ item }) => <ListingCard lead={item} onPress={() => navigation.navigate("ListingDetails", { leadID: item._id })} />}
            />
        </Screen>
    );
}

export default FindLeadScreen;
