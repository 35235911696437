// React Packages
import React, { useState } from "react";
import { View, Platform, ScrollView, KeyboardAvoidingView } from "react-native";

// Custom Components
import Container from "../components/Container";

// Hooks
import useApi from "../hooks/useApi";

// Styles and config
import colors from "../config/colors";
import Screen from "../components/Screen";
import useToast from "../hooks/useToast";
import usersApi from "../api/users";
import AppTextInput from "../components/AppTextInput";

export default function BlockUserScreen({ navigation, route }) {
    const blockUserApi = useApi(usersApi.blockUser);

    const { showToast } = useToast();
    const [reason, setReason] = useState(route.params?.name);

    const handleBlockUser = async () => {
        const response = await blockUserApi.request(route.params._id, reason);
        if (!response.ok) {
            showToast("Unable to block user", "error");
            return;
        }

        showToast("User Blocked", "success");
        navigation.goBack();
    };

    return (
        <Screen loading={blockUserApi.loading}>
            <KeyboardAvoidingView behavior={Platform.OS === "ios" ? "padding" : "height"} style={{ flex: 1, backgroundColor: colors.tertiary }} keyboardVerticalOffset={104}>
                <ScrollView>
                    <Container title={"Reason for the block"} subTitle={"users name - add context"} icon={"numeric-1-box"} animated={false}>
                        <View style={{ paddingTop: 8 }}>
                            <AppTextInput placeholder={"enter reason"} defaultValue={route.params.name} onChangeText={(value: string) => setReason(value)} />
                        </View>
                    </Container>

                    <Container title={"Block"} icon={"check"} onPress={handleBlockUser}></Container>
                </ScrollView>
            </KeyboardAvoidingView>
        </Screen>
    );
}
