import React, { useState } from "react";
import { Image } from "react-native";

import Screen from "../components/Screen";

import useApi from "../hooks/useApi";
import { ScrollView, View } from "react-native";
import usersApi from "../api/users";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import useUser from "../hooks/useUser";
import Container from "../components/Container";
import TabSelector from "../components/TabSelector";
import colors from "../config/colors";
import SubContainer from "../components/SubContainer";
import { OnApproveData } from "@paypal/paypal-js";
import AppButton from "../components/AppButton";
import Pill from "../components/Pill";
import useToast from "../hooks/useToast";

function NewSubscriptionScreen() {
    const { showToast } = useToast();
    const { user } = useUser();
    const [selection, setSelection] = useState("weekly");

    const [subscription, setSubscription] = useState({ plan_id: "", name: "", cost: "", type: "", transferLink: "", subName: "" });

    const getUserApi = useApi(usersApi.getUser);
    const addPaypalSubscriptionApi = useApi(usersApi.addPayPalSubscription);
    const abandonedCheckoutApi = useApi(usersApi.abandonedCheckout);
    const createSubscriptionApi = useApi(usersApi.createSubscription);

    //SUBSCRIPTIONS
    const plans = [
        {
            plan_id: "P-36Y819633V490232FMQS7XKI",
            name: "Recovery",
            subName: "vehicleRecovery",
            cost: "£40",
            icon: "tow-truck",
            type: "weekly",
            transferLink: "https://monzo.com/pay/r/make-me-busy-ltd_MNzEjEM5SzWI80/pay-by-bank-transfer/",
        },
        {
            plan_id: "P-89406822FU718644TMQS74BY",
            name: "Scrap",
            subName: "vehicleScrap",
            cost: "£60",
            icon: "recycle",
            type: "weekly",
            transferLink: "https://monzo.com/pay/r/make-me-busy-ltd_7Wz0l3ycDoKfMa/pay-by-bank-transfer/",
        },
        {
            plan_id: "P-2PE26469HS711533HMQS75MQ",
            name: "Salvage",
            subName: "vehicleSalvage",
            cost: "£40",
            icon: "car",
            type: "weekly",
            transferLink: "https://monzo.com/pay/r/make-me-busy-ltd_aRCwyPSsQSQ0dQ/pay-by-bank-transfer/",
        },
        /*    { plan_id: "P-36450386W8070135TMQS76TI", name: "Parts", cost: "£30", icon: "engine", type: "weekly" }, */
        {
            plan_id: "P-8WT89581HX890021HMQS7ZWY",
            name: "Recovery",
            subName: "vehicleRecovery",
            cost: "£120",
            icon: "tow-truck",
            type: "monthly",
            transferLink: "https://monzo.com/pay/r/make-me-busy-ltd_SL2qbje2EmxFlJ/pay-by-bank-transfer/",
        },
        {
            plan_id: "P-73B67177D3235931RMQS74JY",
            name: "Scrap",
            subName: "vehicleScrap",
            cost: "£180",
            icon: "recycle",
            type: "monthly",
            transferLink: "https://monzo.com/pay/r/make-me-busy-ltd_SKyTDOAObqgl2E/pay-by-bank-transfer/",
        },
        {
            plan_id: "P-9MR691928F375945AMQS756A",
            name: "Salvage",
            subName: "vehicleSalvage",
            cost: "£120",
            icon: "car",
            type: "monthly",
            transferLink: "https://monzo.com/pay/r/make-me-busy-ltd_bp0B7uA6PSMWch/pay-by-bank-transfer/",
        },
        /*       { plan_id: "P-7HX59443S9379663TMQS76ZI", name: "Parts", cost: "£90", icon: "engine", type: "monthly" }, */
    ];

    const handleCreateSubscription = async (subscriptionID: string, subType: string) => {
        if (!subscriptionID) {
            showToast("No Subscription ID found", "error");
            return false;
        }

        if (!subType) {
            showToast("No Subscription Type found", "error");
            return false;
        }

        const response = await createSubscriptionApi.request(subscriptionID, subType);
        if (!response.ok) {
            return false;
        }

        return true;
    };

    const handleAddSubscription = async (subscriptionData: OnApproveData) => {
        if (!subscriptionData.subscriptionID) {
            showToast("No Subscription ID found", "error");
            return;
        }

        const response = await addPaypalSubscriptionApi.request(subscriptionData.subscriptionID, subscription.plan_id);
        if (response.ok) {
            showToast("Payment Accepted, your account has been updated", "success");
            setSubscription({ plan_id: "", name: "", cost: "", type: "", transferLink: "", subName: "" });
        } else {
            showToast("Payment Failed, Please contact support", "error");
        }
    };

    const handleCancelled = async (error: string) => {
        await abandonedCheckoutApi.request(user._id, subscription.name, error);

        setSubscription({ plan_id: "", name: "", cost: "", type: "", transferLink: "", subName: "" });
    };

    return (
        <Screen loading={getUserApi.loading}>
            <PayPalScriptProvider
                options={{
                    "client-id": "ASRl2yMwaNuCHOdj4EpNOOPlSTS2U5idfV0_2xnyWX8l7BSFJSAjx6qaIPbgnFOBqf-G9oXJzyK4CiA4",
                    currency: "GBP",
                    intent: "subscription",
                    vault: "true",
                }}
            >
                <ScrollView>
                    {subscription.plan_id ? (
                        <Container
                            title={subscription.name + " Subscription"}
                            subTitle={"\nThis is a recurring payment of " + subscription.cost + "\nyou will be charged " + subscription.type + "\nyou can cancel at anytime"}
                            utility={<Image source={require("../assets/Devices.png")} style={{ width: 200, height: 200 }} />}
                            animated={false}
                        >
                            {subscription.type == "monthly" ? (
                                <SubContainer
                                    icon={"sale"}
                                    iconBackgroundColor={colors.light}
                                    iconColor={colors.green}
                                    title={"Monthly Subscriber"}
                                    subTitle={"25% discount applied"}
                                    utility={
                                        <Pill
                                            onPress={() => {
                                                const weeklySub = plans.filter((plan) => plan.type == "weekly" && plan.name == subscription.name)[0];
                                                if (!weeklySub) return;
                                                setSubscription(weeklySub);
                                            }}
                                            title="Switch to weekly"
                                        />
                                    }
                                />
                            ) : (
                                <SubContainer
                                    icon={"alert"}
                                    iconBackgroundColor={colors.light}
                                    iconColor={colors.red}
                                    title="Weekly Subscriber"
                                    subTitle={"Save 25% by paying monthly"}
                                    utility={
                                        <Pill
                                            onPress={() => {
                                                const monthlySub = plans.filter((plan) => plan.type == "monthly" && plan.name == subscription.name)[0];
                                                if (!monthlySub) return;
                                                setSubscription(monthlySub);
                                            }}
                                            title="Switch to monthly"
                                        />
                                    }
                                />
                            )}
                            <SubContainer
                                icon={"account"}
                                iconColor={colors.green}
                                iconBackgroundColor={colors.light}
                                title={"Instant Access"}
                                subTitle={"Get access immediately"}
                            />
                            <SubContainer
                                icon={"check"}
                                iconColor={colors.green}
                                iconBackgroundColor={colors.light}
                                title={"Cancel Anytime"}
                                subTitle={"No contract or cancellation fees"}
                            />

                            <View style={{ padding: 6, marginTop: 16 }}>
                                {/*                 <AppButton title="Pay by Bank Transfer" onPress={showBankTransfer} style={{ marginBottom: 16 }} /> */}
                                <PayPalButtons
                                    style={{ layout: "vertical", label: "subscribe" }}
                                    createSubscription={async (data, actions) => {
                                        try {
                                            const subscriptionPlan = await actions.subscription.create({
                                                plan_id: subscription.plan_id,
                                            });

                                            const success = await handleCreateSubscription(subscriptionPlan, subscription.subName);
                                            if (!success) throw new Error("Error creating subscription");

                                            // Link the subscription ID to the user in your database
                                            return subscriptionPlan;
                                        } catch (error) {
                                            throw error;
                                        }
                                    }}
                                    onApprove={(data) => handleAddSubscription(data)}
                                    onCancel={() => handleCancelled("User Cancelled")}
                                    onError={(error) => handleCancelled(error + "")}
                                    forceReRender={[subscription.plan_id]}
                                />
                            </View>
                        </Container>
                    ) : (
                        <>
                            <Container animated={false}>
                                <TabSelector
                                    tabs={[
                                        { name: "weekly", value: "weekly" },
                                        { name: "monthly", value: "monthly" },
                                    ]}
                                    onPress={(value) => {
                                        setSelection(value.value);
                                        setSubscription({ plan_id: "", name: "", cost: "", subName: "", type: "", transferLink: "" });
                                    }}
                                />
                                {selection == "weekly" && (
                                    <SubContainer
                                        icon={"alert"}
                                        iconColor={colors.red}
                                        iconBackgroundColor={colors.light}
                                        title={"Monthly Discount"}
                                        subTitle={"Save 25% by paying monthly instead of weekly"}
                                    />
                                )}
                            </Container>
                            <View style={{ flexDirection: "column" }}>
                                {plans
                                    .filter((plan) => plan.type == selection)
                                    .map((plan) => (
                                        <Container title={plan.name} animated={false}>
                                            <View style={{}}>
                                                {selection == "monthly" && subscription.plan_id && (
                                                    <SubContainer icon={"sale"} iconBackgroundColor={colors.light} iconColor={colors.green} title={"25% discount applied"} />
                                                )}
                                                <SubContainer title={"Total " + plan.cost} subTitle={"charged " + selection} />
                                                <AppButton
                                                    title={"More Info"}
                                                    style={{ color: colors.white, textAlign: "center" }}
                                                    onPress={() => {
                                                        if (user.subscriptions.includes(plan.subName)) {
                                                            showToast("You are already subscribed to this plan", "error");
                                                            return;
                                                        }
                                                        setSubscription(plan);
                                                    }}
                                                />
                                            </View>
                                        </Container>
                                    ))}
                            </View>
                        </>
                    )}
                </ScrollView>
            </PayPalScriptProvider>
        </Screen>
    );
}

export default NewSubscriptionScreen;
