// React
import React from "react";
import { ScrollView, View } from "react-native";
// Components
import Screen from "../components/Screen";
// Hooks
import useUser from "../hooks/useUser";
// Api's

import Container from "../components/Container";
import UserCardReskin from "../components/UserCardReskin";
import useNews from "../hooks/useNews";
import colors from "../config/colors";

export default function MyDashboardScreen({ navigation }) {
    const { user } = useUser();
    const { news } = useNews();

    const myStoreToolTip = "Add engines and gearboxes to your store and we'll send you a notification as soon as a customer requests a part that matches your stock.";

    return (
        <Screen>
            <ScrollView>
                <UserCardReskin user={user} />
                <Container
                    badge={news ? news.newNewsCount : "0"}
                    borderColor={news && news.newNewsCount > 0 ? colors.red : colors.medium}
                    title={"News and Updates"}
                    subTitle={"Latest news from Make Me Busy"}
                    icon={"bell"}
                    onPress={() => navigation.navigate("News")}
                />
                <Container title={"Posts"} subTitle={"View and Manage your posts"} icon={"plus-circle"} onPress={() => navigation.navigate("MyPosts", user._id)} />
                <Container
                    title={"Stats"}
                    subTitle={"Call history and relevant statistics"}
                    icon={"chart-bell-curve"}
                    onPress={() => navigation.navigate("MyStats", user._id)}
                />
                <Container title={"Messages"} subTitle={"Contact Support or View messages"} icon={"chat"} onPress={() => navigation.navigate("MyChats")} />
                <Container
                    title={"Parts System"}
                    subTitle={"match your stock with incoming leads"}
                    icon={"store"}
                    onPress={() => navigation.navigate("PartsSystem")}
                    toolTip={myStoreToolTip}
                />
                {user?.subscriptions?.includes("premiumSalvage") && (
                    <Container title={"Premium Salvage"} subTitle={"Premium Salvage Vehicles"} icon={"cash"} onPress={() => navigation.navigate("PremiumSalvage")} />
                )}
                {user?.isAdmin && <Container title="Admin Tools" icon={"star"} iconColor={colors.red} onPress={() => navigation.navigate("AdminTools")} />}
            </ScrollView>
        </Screen>
    );
}
