import * as SecureStore from "expo-secure-store";
import AsyncStorage from "@react-native-async-storage/async-storage";

import jwtDecode from "jwt-decode";
import { Platform } from "react-native";

const key = "authToken";

const storeToken = async (authToken: string) => {
    try {
        if (Platform.OS === "web") {
            await AsyncStorage.setItem(key, authToken);
        } else {
            await SecureStore.setItemAsync(key, authToken);
        }
    } catch (error) {
        console.log(error);
    }
};

const getToken = async () => {
    try {
        if (Platform.OS === "web") {
            return await AsyncStorage.getItem(key);
        } else {
            return await SecureStore.getItemAsync(key);
        }
    } catch (error) {
        if (Platform.OS !== "web") {
            await SecureStore.deleteItemAsync(key);
        }
        console.log(error);
        return null;
    }
};

const getUser = async () => {
    try {
        const token = await getToken();
        return token ? jwtDecode(token) : null;
    } catch (error) {
        return null;
    }
};

const removeToken = async () => {
    try {
        if (Platform.OS === "web") {
            await AsyncStorage.removeItem(key);
        } else {
            await SecureStore.deleteItemAsync(key);
        }
    } catch (error) {
        console.log(error);
    }
};

export default { getToken, getUser, removeToken, storeToken };
