import { Platform } from "react-native";
import colors from "./colors";

export default {
    //SUBSCRIPTIONS
    subscriptions: [
        {
            name: "vehicleRecovery",
            nameFormatted: "Vehicle Recovery",
            iconName: "tow-truck",
            subscriptionObject: "recoverySubscription",
            description: "Breakdown, A-B Transportation",
            weeklyPricing: "£40",
            monthlyPricing: "£120",
            weeklyTransferLink: "https://monzo.com/pay/r/make-me-busy-ltd_MNzEjEM5SzWI80",
            monthlyTransferLink: "https://monzo.com/pay/r/make-me-busy-ltd_SL2qbje2EmxFlJ",
        },
        {
            name: "vehicleScrap",
            nameFormatted: "Vehicle Scrap",
            iconName: "recycle",
            subscriptionObject: "scrapSubscription",
            description: "Low-value used vehicles for sale",
            weeklyPricing: "£60",
            monthlyPricing: "£180",
            weeklyTransferLink: "https://monzo.com/pay/r/make-me-busy-ltd_7Wz0l3ycDoKfMa",
            monthlyTransferLink: "https://monzo.com/pay/r/make-me-busy-ltd_SKyTDOAObqgl2E",
        },
        {
            name: "vehicleSalvage",
            nameFormatted: "Vehicle Salvage",
            iconName: "car",
            subscriptionObject: "salvageSubscription",
            description: "Mid-value used vehicles for sale",
            weeklyPricing: "£40",
            monthlyPricing: "£120",
            weeklyTransferLink: "https://monzo.com/pay/r/make-me-busy-ltd_aRCwyPSsQSQ0dQ",
            monthlyTransferLink: "https://monzo.com/pay/r/make-me-busy-ltd_bp0B7uA6PSMWch",
        },
        { name: "premiumSalvage", nameFormatted: "Premium Salvage", iconName: "cash", subscriptionObject: "", description: "High-value used vehicles for sale" },
        { name: "vehicleParts", nameFormatted: "Vehicle Parts", iconName: "engine", subscriptionObject: "partsSubscription", description: "Used part requests" },
        { name: "vehicleTrade", nameFormatted: "Vehicle Sale / Trade", iconName: "gavel", subscriptionObject: "", description: "In-App Trade / Sale Vehicles" },
    ],
    text: {
        color: colors.black,
        fontSize: 18,
        fontFamily: "SofiaProRegular",
    },

    versionNo: "1",
    latestBuildNo: Platform.OS === "android" ? "36" : "20.0.0",
    appStoreURL:
        Platform.OS === "android"
            ? "https://play.google.com/store/apps/details?id=com.brent.makemebusyapp&hl=en_GB&gl=US"
            : "https://apps.apple.com/gb/app/make-me-busy/id1562856411",
};
