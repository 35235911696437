import React, { useEffect, useState } from "react";
import { RefreshControl, ScrollView } from "react-native";
import * as Linking from "expo-linking";

import Screen from "../components/Screen";

import useUser from "../hooks/useUser";
import Container from "../components/Container";
import ChatCard from "../components/ChatCard";
import useChats from "../hooks/useChats";
import usePopUp from "../hooks/usePopUp";

const MMB_ACCOUNT_ID = "631a79abf97a8178ac41376a";

function MyChatsScreen({ navigation }) {
    const [loading, setLoading] = useState(false);

    const { user } = useUser();
    const { confirmDialog } = usePopUp();
    const { getChats, handleStartSupportChat, handleDeleteChat, chats, activeUser } = useChats();

    useEffect(() => {
        if (user.isAdmin) {
            getChats(MMB_ACCOUNT_ID);
            return;
        }

        getChats(user._id);
    }, []);

    const refreshChats = async () => {
        setLoading(true);
        if (user.isAdmin) {
            getChats(MMB_ACCOUNT_ID);
            setLoading(false);
            return;
        }

        getChats(user._id);
        setLoading(false);
    };

    return (
        <Screen loading={loading}>
            <ScrollView refreshControl={<RefreshControl onRefresh={() => refreshChats()} refreshing={loading} />} showsVerticalScrollIndicator={false}>
                {chats?.length < 1 && (
                    <>
                        <Container
                            subTitle={"Tap here for more info"}
                            onPress={() => Linking.openURL("https://makemebusy.com/how-it-works")}
                            icon={"help-circle-outline"}
                            title={"How it Works / Getting Started"}
                        />
                        <Container
                            onPress={() => confirmDialog(handleStartSupportChat, "Are you sure you want to start a chat with our support team?")}
                            icon={"chat"}
                            title={"Get Help / Contact Support"}
                        />
                    </>
                )}
                {chats &&
                    chats.map((chat) => (
                        <ChatCard
                            key={chat._id}
                            chat={chat}
                            handleDeleteChat={() => handleDeleteChat(chat._id)}
                            onPress={() => navigation.navigate("Chat", { chatID: chat._id, activeUser: activeUser })}
                        />
                    ))}
            </ScrollView>
        </Screen>
    );
}

export default MyChatsScreen;
