import { useState } from "react";
import chatsApi from "../api/chats";
import useApi from "./useApi";
import { useNavigation } from "@react-navigation/native";
import usePopUp from "./usePopUp";
import useUser from "./useUser";

const MMB_ACCOUNT_ID = "631a79abf97a8178ac41376a";

const useChats = () => {
    const navigation = useNavigation();

    const deleteChatApi = useApi(chatsApi.deleteChat);
    const getChatsApi = useApi(chatsApi.getChats);
    const newChatApi = useApi(chatsApi.newChat);
    const newCustomerChatApi = useApi(chatsApi.newCustomerChat);
    const getCustomerChatsApi = useApi(chatsApi.getCustomerChats);

    const { user } = useUser();
    const { setMessage } = usePopUp();

    const [chats, setChats] = useState([]);
    const [activeUser, setActiveUser] = useState(user?._id ? user._id : "");

    const getChats = async (userID) => {
        setActiveUser(userID);

        const response = await getChatsApi.request(userID);
        if (response.ok) {
            setChats(response.data);
        } else {
            setMessage("Error Getting Chats");
        }
    };
    const getCustomerChats = async (leadID: string) => {
        setActiveUser(leadID);

        const response = await getCustomerChatsApi.request(leadID);
        if (response.ok) {
            setChats(response.data);
        } else {
            setMessage("Error Getting Chats");
        }
    };

    const handleDeleteChat = async (chatID) => {
        const response = await deleteChatApi.request(chatID);
        if (response.ok) {
            getChats(activeUser);
        } else {
            setMessage("Error Deleting Chat");
        }
    };

    const handleNewChat = async (recipient) => {
        const response = await newChatApi.request([activeUser, recipient]);
        if (response.ok) {
            navigation.navigate("Chat", { chatID: response.data, activeUser: activeUser });
        } else {
            setMessage("Unable to create new chat");
        }
    };

    const handleStartSupportChat = async () => {
        const response = await newChatApi.request([user._id, MMB_ACCOUNT_ID]);
        if (response.ok) {
            getChats(user._id);
            navigation.navigate("Chat", { chatID: response.data, activeUser: user._id });
        } else {
            setMessage("Unable to create new chat");
        }
    };

    const handleStartCustomerChat = async (leadID: string) => {
        const response = await newCustomerChatApi.request(leadID, user._id);
        if (response.ok) {
            getChats(user._id);
            navigation.navigate("Chat", { chatID: response.data, activeUser: user._id });
        } else {
            setMessage("Unable to create new chat");
        }
    };

    return { handleDeleteChat, getChats, getCustomerChats, handleNewChat, handleStartSupportChat, handleStartCustomerChat, chats, activeUser };
};

export default useChats;
