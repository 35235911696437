// React
import React, { useEffect, useState, useRef } from "react";
import { Platform, StyleSheet, View } from "react-native";
import MapView, { Marker, Polyline } from "react-native-maps";
// Components
import IconTouchable from "../components/IconTouchable";
import Screen from "../components/Screen";
// Api
import useLeads from "../hooks/useLeads";
// Config
import colors from "../config/colors";
import config from "../config/config";
import Icon from "../components/Icon";
import { ILead } from "../interfaces/lead";
import SubContainer from "../components/SubContainer";
import ListItem from "../components/ListItem";

function ListingsMapScreen({ navigation }) {
    const { leads, leadTypeFilters, leadDistanceFilter, loadLeads } = useLeads();
    const [markers, setMarkers] = useState([]);
    const [lead, setLead] = useState();

    const mapRef = useRef();

    const [deliveryRoute, setDeliveryRoute] = useState();
    const [deliveryDestination, setDeliveryDestination] = useState();

    const setRoute = (lead) => {
        setLead(lead);

        if ("delivery" in lead.location) {
            if ("route" in lead.location.delivery) {
                setDeliveryRoute(lead.location.delivery.route);
            }
            setDeliveryDestination(lead.location.delivery.coords);
        }
    };

    function obfuscateLocation(coords) {
        const radius = 0.04; // change this value to adjust the obfuscation radius
        const randomOffset = () => Math.random() * radius - radius / 2;
        return {
            latitude: coords.latitude + randomOffset(),
            longitude: coords.longitude + randomOffset(),
        };
    }

    useEffect(() => {
        setMarkers([]);

        setTimeout(() =>
            setMarkers(
                leads.map((lead: ILead) => {
                    if (lead.type !== "vehicleRecovery") {
                        return {
                            ...lead,
                            location: {
                                ...lead.location,
                                coords: obfuscateLocation(lead.location.coords),
                            },
                        };
                    } else {
                        return lead;
                    }
                }, 250)
            )
        );
    }, [leadTypeFilters, leadDistanceFilter, leads]);

    return (
        <Screen>
            {markers && (
                <MapView
                    ref={mapRef}
                    style={styles.map}
                    mapType={"standard"}
                    initialRegion={{
                        latitude: 54,
                        longitude: -2.5,
                        latitudeDelta: 9.5,
                        longitudeDelta: 9.5,
                    }}
                    initialCamera={{
                        center: {
                            latitude: 54,
                            longitude: -2.5,
                        },
                        pitch: 0,
                        heading: 0,
                        altitude: 10000,
                        zoom: 7,
                    }}
                    googleMapsApiKey={"AIzaSyD86oxaEq4TTV77dO3hST84a2yc1q3BDKM"}
                    minZoomLevel={4}
                    pitchEnabled={false}
                    showsUserLocation={true}
                    rotateEnabled={false}
                    provider={Platform.OS === "web" || Platform.OS === "android" ? "google" : MapView.PROVIDER_APPLE}
                >
                    {markers.map((lead: ILead) => (
                        <View key={lead._id}>
                            {lead && (
                                <Marker
                                    coordinate={lead.location.coords}
                                    title={lead.workRequired ? lead.workRequired : lead.type}
                                    tappable={true}
                                    onCalloutPress={() => navigation.navigate("ListingDetails", { leadID: lead._id })}
                                    onPress={() => setRoute(lead)}
                                >
                                    {config.subscriptions.map((subscription) => {
                                        if (lead.type === subscription.name) {
                                            return (
                                                <Icon
                                                    key={subscription.name}
                                                    name={subscription.iconName}
                                                    iconColor={lead.boosted ? colors.green : colors.red}
                                                    backgroundColor={lead.boosted ? "black" : "white"}
                                                    borderColor={"black"}
                                                    borderWidth={1}
                                                    size={20}
                                                />
                                            );
                                        }
                                    })}
                                </Marker>
                            )}
                        </View>
                    ))}
                    {deliveryRoute && <Polyline coordinates={deliveryRoute} strokeColor={"red"} strokeWidth={3} />}
                    {deliveryDestination && (
                        <Marker coordinate={deliveryDestination} title={"Delivery"} pinColor={"blue"}>
                            <IconTouchable name="flag-checkered" iconColor={"blue"} backgroundColor={colors.white} size={25} />
                        </Marker>
                    )}
                </MapView>
            )}
            <View style={{ position: "absolute", top: 8, width: "100%", padding: 8 }}>
                {lead && (
                    <ListItem
                        icon={"car"}
                        title={lead.workRequired + "\n" + lead.vehicle.year + " " + lead.vehicle.make + " " + lead.vehicle.model}
                        subTitle={"Tap here for more info"}
                        iconRight="chevron-right"
                        onPress={() => navigation.navigate("ListingDetails", { leadID: lead._id })}
                    />
                )}
            </View>
            <View style={{ position: "absolute", bottom: 20, right: 16, opacity: 0.75 }}>
                <View style={{ paddingBottom: 8 }}>
                    <IconTouchable animated={false} size={40} onPress={() => navigation.navigate("ListingFilter")} name={"filter"} />
                </View>
                <View style={{ paddingBottom: 8 }}>
                    <IconTouchable animated={false} size={40} onPress={loadLeads} name={"refresh"} />
                </View>
            </View>
        </Screen>
    );
}

const styles = StyleSheet.create({
    map: {
        flex: 1,
    },
    text: {
        alignSelf: "center",
        fontSize: 20,
        fontWeight: "900",
    },
});

export default ListingsMapScreen;
