import React, { useMemo, useRef } from "react";
import { View, StyleSheet, Animated, Pressable, Platform } from "react-native";
import AppText from "./AppText";
import colors from "../config/colors";
import { formatDistanceStrict } from "date-fns";
import defaultStyles from "../config/defaultStyles";
import { ILead } from "../interfaces/lead";
import { getDistance } from "../utility/distance";
import useLocation from "../hooks/useLocation";
import AppButton from "./AppButton";

export default function ListingCardStockBasic({ lead, onPress, onUtilityPress }: { lead: ILead; onPress?: () => void; onUtilityPress?: () => void }) {
    const { location } = useLocation();

    let distanceFromLead = useMemo(() => {
        if (Platform.OS === "web") return null;
        if (!location) return null;
        if (!lead.location) return null;

        return getDistance(lead.location.coords.latitude, lead.location.coords.longitude, location.coords.latitude, location.coords.longitude).toFixed(1);
    }, [location]);

    const springValue = useRef(new Animated.Value(1)).current;

    const spring = () => {
        if (onPress) {
            Animated.sequence([
                Animated.timing(springValue, {
                    toValue: 0.95,
                    duration: 50,
                    useNativeDriver: true,
                }),
                Animated.timing(springValue, {
                    toValue: 1,
                    duration: 50,
                    useNativeDriver: true,
                }),
            ]).start(onPress);
        }
    };

    return (
        <Pressable
            style={{
                paddingVertical: 8,
            }}
            onPress={spring}
        >
            <Animated.View
                style={{
                    transform: [
                        {
                            scale: springValue,
                        },
                    ],
                }}
            >
                <View style={[styles.card]}>
                    <View
                        style={{
                            paddingVertical: 4,
                            flexDirection: "row",
                            justifyContent: "space-between",
                            paddingHorizontal: 8,
                            borderRadius: defaultStyles.borderRadius,
                            backgroundColor: colors.black,
                        }}
                    >
                        <View style={{ flexDirection: "row" }}>
                            <View>{onUtilityPress && <AppButton title={"Delete"} color={colors.red} onPress={onUtilityPress} />}</View>
                            <View style={{ flexDirection: "column" }}>
                                <View style={{ flexDirection: "row" }}>
                                    <AppText style={styles.textHighlight}>
                                        {lead.vehicle.year ? lead.vehicle.year : null} {lead.vehicle.make && lead.vehicle.make} {lead.vehicle.model && lead.vehicle.model}
                                    </AppText>
                                    <AppText style={styles.text}>
                                        {" | " + lead.vehicle.engineSize + "cc"} {" | " + lead.vehicle.engine.code} {" | " + lead.vehicle.transmission.type}
                                        {" | Reg: " + lead.vehicle.registration}
                                    </AppText>
                                </View>
                                <AppText style={styles.textHighlight}>{lead.workRequired}</AppText>
                            </View>
                        </View>
                        <View>
                            <AppText style={styles.text}>
                                {lead.created &&
                                    formatDistanceStrict(new Date(lead.created), new Date(), {
                                        addSuffix: true,
                                    })}
                            </AppText>
                            <AppText style={styles.text}>
                                {lead.location.postcode + " | "} {distanceFromLead ? distanceFromLead : "?"} mi.
                            </AppText>
                        </View>
                    </View>
                </View>
            </Animated.View>
        </Pressable>
    );
}

const styles = StyleSheet.create({
    card: {
        backgroundColor: colors.white,
        borderRadius: defaultStyles.borderRadius,
        justifyContent: "center",
        marginHorizontal: 8,
        shadowColor: colors.black,
        shadowOffset: {
            width: 0,
            height: 4,
        },
        shadowOpacity: 0.3,
        shadowRadius: 4.65,
        elevation: 8,
    },
    textLeft: {
        fontSize: 16,
        fontWeight: "bold",
        textAlign: "left",
    },
    text: {
        fontSize: 16,
        fontWeight: "bold",
        color: colors.white,
    },
    textHighlight: {
        fontSize: 16,
        paddingHorizontal: 4,
        fontWeight: "bold",
        color: colors.yellow,
        backgroundColor: colors.black,
    },
    footerText: {
        fontSize: 14,
        fontWeight: "bold",
    },
    requestText: {
        fontWeight: "bold",
        textAlign: "center",
    },
    textRight: {
        fontSize: 16,
        fontWeight: "bold",
        textAlign: "right",
    },
    title: {
        fontSize: 17,
        fontWeight: "bold",
        flex: 1,
        textAlign: "center",
        paddingTop: 4,
        marginHorizontal: 4,
    },
    subTitle: {
        fontSize: 15,
        fontWeight: "bold",
        flex: 1,
        color: colors.red,
        textAlign: "center",
        paddingTop: 8,
        marginHorizontal: 4,
    },

    column: {
        flexDirection: "row",
        justifyContent: "center",
    },
    columnRight: {
        marginLeft: 16,
    },
    columnLeft: {
        paddingLeft: 40,
    },
    row: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 8,
    },
    rowLeft: {
        flexDirection: "column",
        alignItems: "center",
    },
    rowRight: {
        flexDirection: "row",
        alignItems: "center",
    },
});
