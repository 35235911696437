import React, { useEffect, useRef } from "react";
import { View, StyleSheet, Animated, useWindowDimensions } from "react-native";
import colors from "../config/colors";
import AppText from "./AppText";
import useToast from "../hooks/useToast";
import defaultStyles from "../config/defaultStyles";

function Toast() {
    const { toast, setToast } = useToast();
    const translateY = useRef(new Animated.Value(-100)).current;
    const opacity = useRef(new Animated.Value(0)).current;

    const { width } = useWindowDimensions();

    useEffect(() => {
        if (!toast) return;

        if (toast.message) {
            // Animate the opacity and translateY values
            Animated.parallel([
                Animated.timing(opacity, {
                    toValue: 1, // Fade in
                    duration: 400,
                    useNativeDriver: true,
                }),
                Animated.timing(translateY, {
                    toValue: 100, // Move down
                    duration: 500,
                    useNativeDriver: true,
                }),
            ]).start();

            const timeout = setTimeout(() => {
                // Animate back up and fade out
                Animated.parallel([
                    Animated.timing(opacity, {
                        toValue: 0, // Fade out
                        duration: 400,
                        useNativeDriver: true,
                    }),
                    Animated.timing(translateY, {
                        toValue: -100, // Move back up
                        duration: 400,
                        useNativeDriver: true,
                    }),
                ]).start(() => {
                    setToast({});
                });
            }, toast.duration || 2500);

            return () => clearTimeout(timeout);
        }
    }, [toast]);

    if (!toast) {
        return null;
    }

    var toastColor = colors.green;

    if (toast.type === "error") {
        toastColor = colors.red;
    }

    if (toast.type === "success") {
        toastColor = colors.green;
    }

    return (
        <View style={{ position: "absolute", top: 20, left: 0, right: 0, alignItems: "center", zIndex: 9999, elevation: 8 }}>
            <Animated.View
                style={{
                    opacity,
                    transform: [{ translateY }],
                    width: width > 576 ? 600 : "90%",
                    backgroundColor: toastColor,
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: "visible",
                    borderRadius: defaultStyles.borderRadius,
                    padding: 8,
                    shadowColor: colors.black,
                    shadowOffset: { width: -2, height: 4 },
                    shadowOpacity: 0.9,
                    shadowRadius: 5,
                    elevation: 8,
                    position: "absolute",
                }}
            >
                <AppText style={styles.toastText}>{toast.message}</AppText>
            </Animated.View>
        </View>
    );
}

const styles = StyleSheet.create({
    toastText: {
        textAlign: "center",
        fontSize: 20,
        color: colors.white,
        fontWeight: "bold",
        paddingLeft: 4,
    },
});

export default Toast;
