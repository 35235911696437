import React from "react";
import { View, StyleSheet, Pressable, Image } from "react-native";
import Animated, { useSharedValue, withTiming, useAnimatedStyle, withSequence } from "react-native-reanimated";
import AppText from "./AppText";
import colors from "../config/colors";
import Icon from "./Icon";
import config from "../config/config";
import { formatDistanceStrict, isValid } from "date-fns";
import defaultStyles from "../config/defaultStyles";
import { ILead } from "../interfaces/lead";
import { MaterialCommunityIcons } from "@expo/vector-icons";

import { install } from "resize-observer";
import { useNavigation } from "@react-navigation/native";

if (!window.ResizeObserver) install();

function ListingCard({ lead, onPress, showDetails = false, showImages = true }: { lead: ILead; onPress?: () => void; showDetails?: boolean; showImages?: boolean }) {
    const springValue = useSharedValue(1);

    const navigation = useNavigation();

    const animatedStyle = useAnimatedStyle(() => {
        return {
            transform: [{ scale: springValue.value }],
        };
    });

    const spring = () => {
        if (onPress) {
            springValue.value = withSequence(withTiming(0.95, { duration: 100 }), withTiming(1, { duration: 100 }));
            setTimeout(() => {
                onPress();
            }, 200);
        }
    };

    let cardColor = colors.secondary;

    if (lead.status == "Quoted") {
        cardColor = colors.red;
    }

    if (lead.boosted) {
        cardColor = colors.green;
    }

    if (lead.status == "Booked") {
        cardColor = colors.primary;
    }

    if (lead.status == "inactive") {
        cardColor = colors.light;
    }

    if (lead.status == "cancelled") {
        cardColor = colors.light;
    }

    //switch statement to control background color of card

    return (
        <Pressable
            style={{
                paddingVertical: 8,
            }}
            onPress={spring}
        >
            <Animated.View
                style={[
                    animatedStyle,
                    {
                        maxWidth: 600,
                        backgroundColor: colors.white,
                        borderRadius: defaultStyles.borderRadius,
                        marginHorizontal: 8,
                        shadowColor: colors.black,
                        shadowOffset: {
                            width: 0,
                            height: 4,
                        },
                        shadowOpacity: 0.3,
                        shadowRadius: 4.65,
                        elevation: 8,
                    },
                ]}
            >
                <View style={[styles.typeContainer, { borderColor: cardColor, backgroundColor: cardColor }]}>
                    {lead.site == "app" && (
                        <>
                            <View style={{ position: "absolute", left: 8, top: 6 }}>
                                <MaterialCommunityIcons name={"account"} backgroundColor={"transparent"} size={24} color={colors.black} />
                            </View>
                            <AppText style={styles.text}>in-app referral</AppText>
                        </>
                    )}
                    <AppText style={styles.text}>{config.subscriptions.filter((sub) => sub.name == lead.type)[0]?.nameFormatted}</AppText>
                </View>
                <AppText style={styles.title}>{lead.workRequired}</AppText>
                {showImages && lead.images?.length > 0 ? (
                    <Pressable
                        style={{
                            alignItems: "center",
                            flexDirection: "row",
                            justifyContent: "center",
                            marginHorizontal: 10,
                        }}
                        onPress={() => {
                            navigation.navigate("ViewImages", { images: lead.images });
                        }}
                    >
                        {lead.images?.map((image, index) => (
                            <Image key={index} style={{ flex: 1, height: 110 }} source={{ uri: image }} resizeMode="contain" />
                        ))}
                    </Pressable>
                ) : null}
                <View style={{ justifyContent: "center", paddingBottom: 8 }}>
                    <AppText style={styles.title} numberOfLines={1}>
                        {lead.vehicle.year ? lead.vehicle.year : null} {lead.vehicle.make && lead.vehicle.make} {lead.vehicle.model && lead.vehicle.model}
                    </AppText>
                </View>
                <View style={styles.column}>
                    <View style={styles.columnLeft}>
                        <AppText style={styles.textRight}>Registration:</AppText>
                        <AppText style={styles.textRight}>Postcode:</AppText>
                        {lead.location?.delivery?.postcode && lead.location?.distanceBetween ? <AppText style={styles.textRight}>Destination:</AppText> : null}
                        {!isNaN(lead.numPassengers) ? <AppText style={styles.textRight}>Passengers:</AppText> : null}
                        {lead.vehicle.weight ? <AppText style={styles.textRight}>Weight:</AppText> : null}
                        {lead.type != "vehicleRecovery" && (
                            <>
                                {lead.vehicle.engine.code ? <AppText style={styles.textRight}>Engine Code:</AppText> : null}
                                {lead.vehicle.engineSize ? <AppText style={styles.textRight}>Engine Size:</AppText> : null}
                                {lead.vehicle.transmission?.type ? <AppText style={styles.textRight}>Transmission:</AppText> : null}
                            </>
                        )}
                        {lead.price ? <AppText style={styles.textRight}>Quoted Price:</AppText> : null}
                        {showDetails && (
                            <View>
                                {lead.vehicle.partexPrice && <AppText style={styles.textRight}>Part-Ex Value:</AppText>}
                                {isValid(new Date(lead.vehicle.motDueDate)) && <AppText style={styles.textRight}>MOT Due:</AppText>}
                                {lead.vehicle.isRunning && <AppText style={styles.textRight}>Engine Running:</AppText>}
                                {lead.vehicle.problems && <AppText style={styles.textRight}>Comments:</AppText>}
                                {lead.vehicle.length && <AppText style={styles.textRight}>Length:</AppText>}
                            </View>
                        )}
                    </View>
                    <View style={styles.columnRight}>
                        <AppText style={styles.textLeft}>{lead.vehicle.registration}</AppText>
                        {lead.location?.postcode ? <AppText style={styles.textLeft}>{lead.location.postcode}</AppText> : null}
                        {lead.location?.delivery?.postcode && lead.location?.distanceBetween ? (
                            <AppText style={styles.textLeft}>{`${lead.location.delivery.postcode} - ${lead.location.distanceBetween}mi.`}</AppText>
                        ) : null}
                        {!isNaN(lead.numPassengers) ? <AppText style={styles.textLeft}>{lead.numPassengers}</AppText> : null}

                        {lead.vehicle.weight ? <AppText style={styles.textLeft}>{lead.vehicle.weight ? lead.vehicle.weight : 0} kg</AppText> : null}
                        {lead.type != "vehicleRecovery" && (
                            <>
                                {lead.vehicle.engine.code ? <AppText style={styles.textLeft}>{lead.vehicle.engine.code}</AppText> : null}
                                {lead.vehicle.engineSize ? <AppText style={styles.textLeft}>{lead.vehicle.engineSize}</AppText> : null}
                                {lead.vehicle.transmission?.type ? <AppText style={styles.textLeft}>{lead.vehicle.transmission.type}</AppText> : null}
                            </>
                        )}
                        {lead.price ? <AppText style={styles.textLeft}>£{lead.price}</AppText> : null}
                        {showDetails && (
                            <View>
                                {lead.vehicle.partexPrice && <AppText style={styles.textLeft}>£{lead.vehicle.partexPrice}</AppText>}
                                {isValid(new Date(lead.vehicle.motDueDate)) && (
                                    <AppText style={styles.textLeft}>
                                        {formatDistanceStrict(new Date(lead.vehicle.motDueDate), new Date(), {
                                            addSuffix: true,
                                        })}
                                    </AppText>
                                )}
                                {lead.vehicle.isRunning && <AppText style={styles.textLeft}>Yes</AppText>}
                                {lead.vehicle.problems && <AppText style={styles.textLeft}>{lead.vehicle.problems}</AppText>}
                                {lead.vehicle.length && <AppText style={styles.textLeft}>{lead.vehicle.length / 1000}m</AppText>}
                            </View>
                        )}
                    </View>
                </View>
                <View style={styles.row}>
                    <View style={styles.rowLeft}>
                        <Icon name="map-marker" backgroundColor={"transparent"} iconColor={colors.black} />
                        <AppText style={styles.footerText}>{lead.location?.distanceFromLead ? lead.location.distanceFromLead : "?"} mi.</AppText>
                    </View>
                    <View style={styles.rowLeft}>
                        <Icon name="timer" backgroundColor={"transparent"} iconColor={colors.black} />
                        <AppText style={styles.footerText}>
                            {lead.created &&
                                formatDistanceStrict(new Date(lead.created), new Date(), {
                                    addSuffix: true,
                                })}
                        </AppText>
                    </View>
                    <View style={styles.rowLeft}>
                        <Icon name="eye" backgroundColor={"transparent"} iconColor={colors.black} />
                        <AppText style={styles.footerText}>{lead.viewedBy?.length > 0 ? lead.viewedBy.length : 0}</AppText>
                    </View>
                </View>
            </Animated.View>
        </Pressable>
    );
}

const styles = StyleSheet.create({
    typeContainer: {
        paddingVertical: 8,
        flexDirection: "row",
        justifyContent: "space-around",
        borderTopLeftRadius: defaultStyles.borderRadius,
        borderTopRightRadius: defaultStyles.borderRadius,
    },
    textLeft: {
        fontSize: 16,
        fontWeight: "bold",
        textAlign: "left",
    },
    text: {
        fontSize: 16,
        fontWeight: "bold",
    },
    footerText: {
        fontSize: 14,
        fontWeight: "bold",
    },
    requestText: {
        fontWeight: "bold",
        textAlign: "center",
    },
    textRight: {
        fontSize: 16,
        fontWeight: "bold",
        textAlign: "right",
    },
    title: {
        fontSize: 17,
        fontWeight: "bold",
        flex: 1,
        textAlign: "center",
        paddingTop: 4,
        marginHorizontal: 4,
    },
    column: {
        flexDirection: "row",
        justifyContent: "center",
    },
    columnRight: {
        marginLeft: 16,
    },
    columnLeft: {
        paddingLeft: 40,
    },
    row: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 8,
    },
    rowLeft: {
        flexDirection: "column",
        alignItems: "center",
    },
    rowRight: {
        flexDirection: "row",
        alignItems: "center",
    },
});

export default ListingCard;
