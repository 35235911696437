import React, { useEffect, useState } from "react";
import Screen from "../components/Screen";
import reportingApi from "../api/reporting";
import useApi from "../hooks/useApi";
import Container from "../components/Container";
import SubContainer from "../components/SubContainer";
import { ScrollView, View } from "react-native";
import { formatDistance, formatDistanceStrict, isValid } from "date-fns";
import colors from "../config/colors";

function PremiumSalvageReportingScreen({ navigation }) {
    const getPremiumSalvageStatsApi = useApi(reportingApi.getPremiumSalvageStats);

    const [users, setUsers] = useState([]);

    useEffect(() => {
        getPremiumSalvageStats();
    }, []);

    const getPremiumSalvageStats = async () => {
        const response = await getPremiumSalvageStatsApi.request();
        if (!response.ok) {
            return;
        }

        //add a new field to the response data total calls / total booked

        response.data.forEach((user) => {
            if (!user.totalCalls || !user.totalBooked) {
                user.ratio = 200;
                return;
            }

            user.ratio = parseInt(user.totalCalls) / parseInt(user.totalBooked);
            user.ratio = Math.round(user.ratio);
        });

        setUsers(response.data.sort((a, b) => a.ratio - b.ratio));
    };

    return (
        <Screen>
            <ScrollView>
                {users?.map((user) => (
                    <Container
                        icon="account"
                        key={user._id}
                        title={user.name}
                        subTitle={user.email}
                        iconColor={user.ratio > 30 ? colors.red : colors.green}
                        onPress={() => navigation.navigate("UserDetails", { userID: user._id })}
                    >
                        <View style={{ flexDirection: "row" }}>
                            <View style={{ flex: 1 }}>
                                <SubContainer title={"Calls"} subTitle={"Total: " + user.totalCalls + "\nLast 7 Days: " + user.totalCallsLast7Days} />
                                <SubContainer
                                    title={"Last Call"}
                                    subTitle={
                                        isValid(new Date(user.lastCallDate)) ? formatDistanceStrict(new Date(user.lastCallDate), new Date(), { addSuffix: true }) : "No Calls"
                                    }
                                />
                                <SubContainer title={"C/B Ratio"} subTitle={"Books 1 Car per " + user.ratio + " calls"} />
                            </View>
                            <View style={{ flex: 1 }}>
                                <SubContainer title={"Booked"} subTitle={"Total: " + user.totalBooked + "\nLast 7 Days: " + user.totalBookedLast7Days} />
                                <SubContainer
                                    title={"Last Booked"}
                                    subTitle={
                                        isValid(new Date(user.lastBookedDate))
                                            ? formatDistanceStrict(new Date(user.lastBookedDate), new Date(), { addSuffix: true })
                                            : "No Bookings"
                                    }
                                />
                            </View>
                        </View>
                    </Container>
                ))}
            </ScrollView>
        </Screen>
    );
}

export default PremiumSalvageReportingScreen;
