import React, { useEffect, useState, useRef } from "react";
import { StyleSheet, AppState, AppStateStatus, Platform, Pressable } from "react-native";
import { HeaderBackButton, createStackNavigator } from "@react-navigation/stack";

import useLocation from "../hooks/useLocation";
import useUser from "../hooks/useUser";
import useNotifications from "../hooks/useNotifications";
import useNews from "../hooks/useNews";

import ListingDetailsScreen from "../screens/ListingDetailsScreen";
import UsersScreen from "../screens/UsersScreen";
import UserDetailsScreen from "../screens/UserDetailsScreen";
import ChangePasswordScreen from "../screens/ChangePasswordScreen";
import ReportingScreen from "../screens/ReportingScreen";
import AdminToolsScreen from "../screens/AdminToolsScreen";
import SendNewsScreen from "../screens/SendNewsScreen";
import ListingRouteScreen from "../screens/ListingRouteScreen";
import FindLeadScreen from "../screens/FindLeadScreen";
import ChatScreen from "../screens/ChatScreen";
import UpdatePaypalIDScreen from "../screens/UpdatePaypalIDScreen";
import TransactionHistoryScreen from "../screens/TransactionHistoryScreen";
import PartsSystemScreen from "../screens/PartsSystemScreen";
import useUpdates from "../hooks/useUpdates";
import AppTabNavigator from "./AppTabNavigator";
import MyStatsScreen from "../screens/MyStatsScreen";
import WelcomeScreen from "../screens/WelcomeScreen";
import NewsScreen from "../screens/NewsScreen";
import MOTHistoryScreen from "../screens/MOTHistoryScreen";
import SubscriptionsScreen from "../screens/SubscriptionsScreen";
import UnavailableScreen from "../screens/UnavailableScreen";
import BrowseStockScreen from "../screens/BrowseStockScreen";
import PremiumSalvageScreen from "../screens/PremiumSalvageScreen";
import MyChatsScreen from "../screens/MyChatsScreen";
import BookedPremiumSalvageScreen from "../screens/BookedPremiumSalvageScreen";
import PremiumSalvageReportingScreen from "../screens/PremiumSalvageReporting";
import NewSubscriptionScreen from "../screens/NewSubscriptionScreen";
import colors from "../config/colors";
import ListingFilterScreen from "../screens/ListingFilterScreen";
import AppText from "../components/AppText";
import { useNavigation } from "@react-navigation/native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import ViewImagesScreen from "../screens/ViewImagesScreen";
import MyStockScreen from "../screens/MyStockScreen";
import MatchingDevicesScreen from "../screens/MatchingDevicesScreen";
import SuspiciousActivityScreen from "../screens/SuspiciousActivityScreen";
import VehicleValuationScreen from "../screens/VehicleValuationScreen";
import NewPostScreen from "../screens/NewPostScreen";
import TransactionsScreen from "../screens/TransactionsScreen";
import MyPostsScreen from "../screens/MyPostsScreen";
import BlockUserScreen from "../screens/BlockUserScreen";
import NewRecoveryPostScreen from "../screens/NewRecoveryPostScreen";
import AddToHistoryScreen from "../screens/AddToHistoryScreen";
import OnboardingScreen from "../screens/OnboardingScreen";
import useLeads from "../hooks/useLeads";
import { UsersMapScreen } from "../screens/UsersMapScreen";
import BlockScreen from "../screens/BlockScreen";
import CompleteOnboardingScreen from "../screens/CompleteOnboardingScreen";
import ViewCustomerChatsScreen from "../screens/ViewCustomerChatsScreen";

const Stack = createStackNavigator();

const AppNavigator = () => {
    const appState = useRef(AppState.currentState);
    const [appStateVisible, setAppStateVisible] = useState(appState.current);
    const { getConfig } = useUser();
    const { getLocation } = useLocation();
    const { checkForUpdates } = useUpdates();
    const { getNotificationPushToken, getNotifications } = useNotifications();
    const { getNews } = useNews();
    const { loadLeads } = useLeads();

    useEffect(() => {
        getConfig();
        getLocation();
        checkForUpdates();
        getNotificationPushToken();
        getNotifications();
        getNews();

        const appStateSubscription = AppState.addEventListener("change", _handleAppStateChange);
        return () => {
            if (appStateSubscription) {
                appStateSubscription.remove();
            }
        };
    }, []);

    const _handleAppStateChange = (nextAppState: AppStateStatus) => {
        if (appState.current == "background" && nextAppState === "active") {
            getConfig();
            getLocation();
            checkForUpdates();
            getNotificationPushToken();
            getNotifications();
            getNews();
        }

        appState.current = nextAppState;
        setAppStateVisible(appState.current);
    };

    return (
        <Stack.Navigator
            screenOptions={{
                //@ts-ignore
                headerShown: true,
                headerStyle: {
                    backgroundColor: colors.tertiary,
                },
                headerTitle: "",
                headerBackTitle: "Back",
                headerBackTitleVisible: true,
                headerBackTitleStyle: { color: colors.white },
                headerTintColor: colors.red,
                headerRightContainerStyle: {
                    paddingRight: 8,
                },
            }}
        >
            {Platform.OS === "web" && <Stack.Screen name="Welcome" component={WelcomeScreen} options={{ headerShown: false }} />}
            <Stack.Screen name="AgentStack" component={AppTabNavigator} options={{ headerShown: false }} />
            <Stack.Screen name="ListingDetails" component={ListingDetailsScreen} />
            <Stack.Screen name="ListingRoute" component={ListingRouteScreen} />
            <Stack.Screen name="Unavailable" component={UnavailableScreen} />
            <Stack.Screen name="NewRecoveryPost" component={NewRecoveryPostScreen} />
            <Stack.Screen name="Onboarding" component={OnboardingScreen} />
            <Stack.Screen name="CompleteOnboarding" component={CompleteOnboardingScreen} />
            <Stack.Screen name="Users" component={UsersScreen} />
            <Stack.Screen name="UserDetails" component={UserDetailsScreen} />
            <Stack.Screen name="Subscriptions" component={SubscriptionsScreen} />
            <Stack.Screen name="MatchingDevices" component={MatchingDevicesScreen} />
            <Stack.Screen name="ViewCustomerChats" component={ViewCustomerChatsScreen} />

            <Stack.Screen
                name="PartsSystem"
                component={PartsSystemScreen}
                options={{
                    headerRight() {
                        const navigation = useNavigation();

                        return (
                            <Pressable onPress={() => navigation.navigate("MyStock")} style={styles.headerRight}>
                                <AppText style={styles.headerRightText}>Add Stock</AppText>
                                <MaterialCommunityIcons name={"store"} size={24} color={colors.red} style={{ marginLeft: 4 }} />
                            </Pressable>
                        );
                    },
                }}
            />
            <Stack.Screen name="MyStock" component={MyStockScreen} />
            <Stack.Screen name="NewPost" component={NewPostScreen} />
            <Stack.Screen name="NewSubscription" component={NewSubscriptionScreen} />
            <Stack.Screen name="UpdatePaypalID" component={UpdatePaypalIDScreen} />
            <Stack.Screen name="TransactionHistory" component={TransactionHistoryScreen} />
            <Stack.Screen name="Transactions" component={TransactionsScreen} />
            <Stack.Screen name="FindLead" component={FindLeadScreen} />
            <Stack.Screen name="ChangePassword" component={ChangePasswordScreen} />
            <Stack.Screen name="AddToHistory" component={AddToHistoryScreen} />
            <Stack.Screen name="Reporting" component={ReportingScreen} />
            <Stack.Screen name="AdminTools" component={AdminToolsScreen} />
            <Stack.Screen name="SendNews" component={SendNewsScreen} />
            <Stack.Screen name="MyChats" component={MyChatsScreen} />
            <Stack.Screen name="Chat" component={ChatScreen} />
            <Stack.Screen name="MyStats" component={MyStatsScreen} />
            <Stack.Screen name="BrowseStock" component={BrowseStockScreen} options={{ headerShown: false }} />
            <Stack.Screen
                name="ListingFilter"
                component={ListingFilterScreen}
                options={() => {
                    return {
                        headerLeft: (props) => (
                            <HeaderBackButton
                                {...props}
                                onPress={() => {
                                    loadLeads();
                                    if (props.onPress) {
                                        props.onPress();
                                    }
                                }}
                            />
                        ),
                    };
                }}
            />
            <Stack.Screen name="News" component={NewsScreen} />
            <Stack.Screen
                name="BookedPremiumSalvage"
                component={BookedPremiumSalvageScreen}
                options={{
                    headerRight() {
                        const navigation = useNavigation();

                        return (
                            <Pressable onPress={() => navigation.navigate("PremiumSalvageReporting")} style={styles.headerRight}>
                                <AppText style={styles.headerRightText}>Reporting</AppText>
                                <MaterialCommunityIcons name={"chart-bell-curve"} size={24} color={colors.red} style={{ marginLeft: 4 }} />
                            </Pressable>
                        );
                    },
                }}
            />
            <Stack.Screen name="MOTHistory" component={MOTHistoryScreen} />
            <Stack.Screen name="PremiumSalvage" component={PremiumSalvageScreen} />
            <Stack.Screen name="PremiumSalvageReporting" component={PremiumSalvageReportingScreen} />
            <Stack.Screen name="ViewImages" component={ViewImagesScreen} />
            <Stack.Screen name="SuspiciousActivity" component={SuspiciousActivityScreen} />
            <Stack.Screen name="VehicleValuation" component={VehicleValuationScreen} />
            <Stack.Screen name="MyPosts" component={MyPostsScreen} />
            <Stack.Screen name="BlockUser" component={BlockUserScreen} />
            <Stack.Screen name="Block" component={BlockScreen} />
            <Stack.Screen name="UsersMap" component={UsersMapScreen} />
        </Stack.Navigator>
    );
};

const styles = StyleSheet.create({
    headerRightText: {
        color: colors.white,
        fontWeight: "500",
        fontSize: 18,
        letterSpacing: 0.35,
    },
    headerRight: {
        flexDirection: "row",
        alignItems: "center",
    },
});

export default AppNavigator;
