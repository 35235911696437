import React, { useEffect } from "react";
import { ScrollView } from "react-native";

import Screen from "../../components/Screen";

import ChatCard from "../../components/ChatCard";
import useChats from "../../hooks/useChats";
import jwtDecode from "jwt-decode";
import authStorage from "../../auth/storage";

function CustomerChatsScreen({ navigation, leadID }) {
    const { getCustomerChats, chats } = useChats();

    useEffect(() => {
        getChats();
    }, []);

    const getChats = async () => {
        let lead = await authStorage.getToken();
        if (lead) {
            lead = jwtDecode(lead);
        }

        if (!lead || !lead._id) {
            return;
        }

        getCustomerChats(lead._id);
    };

    return (
        <Screen>
            <ScrollView showsVerticalScrollIndicator={false}>
                {chats && chats.map((chat) => <ChatCard key={chat._id} chat={chat} onPress={() => navigation.navigate("CustomerChat", { chatID: chat._id })} />)}
            </ScrollView>
        </Screen>
    );
}

export default CustomerChatsScreen;
