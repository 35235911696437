import React, { useState } from "react";
import { FlatList } from "react-native";

import Screen from "../components/Screen";
import useApi from "../hooks/useApi";
import usersApi from "../api/users";
import ListItem from "../components/ListItem";
import { IUser } from "../interfaces/user";
import { formatDistanceStrict } from "date-fns";
import { useFocusEffect } from "@react-navigation/native";
import TabSelectorNew from "../components/TabSelectorNew";
import useToast from "../hooks/useToast";

export default function OnboardingScreen({ navigation }) {
    const [users, setUsers] = useState<IUser | null>(null);
    const [userType, setUserType] = useState("new");
    const { showToast } = useToast();

    const getOnboardingUsersApi = useApi(usersApi.getOnboardingUsers);
    const myOnboardedUsers = useApi(usersApi.myOnboardedUsers);
    const selectUserForOnboarding = useApi(usersApi.selectUserForOnboarding);

    useFocusEffect(
        React.useCallback(() => {
            getUsers();
        }, [userType])
    );

    const getUsers = async () => {
        if (userType == "new") {
            const response = await getOnboardingUsersApi.request();
            if (!response.ok) {
                console.log("No Users Found");
                return;
            }

            response.data.length > 2 ? setUsers(response.data) : setUsers(null);
        }

        if (userType == "active") {
            const response = await myOnboardedUsers.request();
            if (!response.ok) {
                console.log("No Users Found");
                return;
            }
            response.data.length > 0 ? setUsers(response.data) : setUsers(null);
        }
        return;
    };

    const handleSelectUser = async (userID: IUser) => {
        if (userType === "new") {
            const response = await selectUserForOnboarding.request(userID);
            if (!response.ok) {
                return;
            }
            showToast("User selected for onboarding", "success");
            //remove user from list
            const updatedUsers = users.filter((user) => user._id !== userID);
            setUsers(updatedUsers);
        } else {
            navigation.navigate("CompleteOnboarding", { userID });
        }
    };

    return (
        <Screen style={{ margin: 16, rowGap: 8 }}>
            <TabSelectorNew
                tabs={[
                    { name: "New", value: "new" },
                    { name: "My Users", value: "active" },
                ]}
                onPress={(value) => setUserType(value.value)}
            />

            <FlatList
                data={users}
                contentContainerStyle={{ rowGap: 8 }}
                keyExtractor={(item) => item._id}
                showsVerticalScrollIndicator={true}
                renderItem={({ item }) => (
                    <>
                        {item.contact.phone && (
                            <ListItem
                                icon={"account"}
                                title={item.name}
                                subTitle={
                                    item.contact.phone +
                                    "\nJoined: " +
                                    formatDistanceStrict(new Date(item.created), new Date(), { addSuffix: true }) +
                                    "\n\nInterested In:\n" +
                                    item.leadTypeFilters.join(" ") +
                                    (item.note ? "\n\nNotes:\n" + item.note : "")
                                }
                                onPress={() => handleSelectUser(item._id)}
                                iconRight="chevron-right"
                            />
                        )}
                    </>
                )}
            />
        </Screen>
    );
}
