// React
import React, { useEffect, useState } from "react";
import { View, Modal, ScrollView, Platform } from "react-native";
import * as Linking from "expo-linking";
import { debounce } from "lodash";

// Components
import Container from "../components/Container";
import ListingCard from "../components/ListingCard";
import Picker from "../components/Picker";
import Screen from "../components/Screen";
// API
import leadsApi from "../api/leads";
import usePopUp from "../hooks/usePopUp";
import useUser from "../hooks/useUser";
import useApi from "../hooks/useApi";
import useLeads from "../hooks/useLeads";
// Config
import colors from "../config/colors";
import usersApi from "../api/users";
import SubContainer from "../components/SubContainer";
import { ILead } from "../interfaces/lead";
import ListingCardStock from "../components/ListingCardStock";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import useToast from "../hooks/useToast";
import useContact from "../hooks/useContact";
import defaultStyles from "../config/defaultStyles";
import useScreenshot from "../hooks/useScreenshot";
import useMedia from "../hooks/useMedia";
import mime from "mime";
import IconTouchable from "../components/IconTouchable";
import Icon from "../components/Icon";
import useChats from "../hooks/useChats";
import AppTextInput from "../components/AppTextInput";
import { HeaderBackButton } from "@react-navigation/stack";
import ListItem from "../components/ListItem";
import AppButton from "../components/AppButton";
import ContainerNew from "../components/ContainerNew";

function ListingDetailsScreen({ navigation, route }) {
    const { user } = useUser();
    const { setMessage } = usePopUp();
    const { handleStartCustomerChat } = useChats();
    const { showToast } = useToast();
    const { updateStatusBooked, updateStatusQuoted } = useLeads();
    const { sendSMS, callNumber, sendWhatsapp } = useContact();
    const getLeadApi = useApi(leadsApi.getLead);
    const getContactInfoApi = useApi(leadsApi.getContactInfo);
    const addNoteApi = useApi(leadsApi.addNote);
    const addReportApi = useApi(leadsApi.addReport);
    const getUsersBySubscriptionApi = useApi(usersApi.getUsersBySubscription);
    const updateStatusCompletedApi = useApi(leadsApi.updateStatusCompleted);
    const changeLeadTypeApi = useApi(leadsApi.changeType);

    const [selectedAgent, setSelectedAgent] = useState(null);

    const sendQuoteApi = useApi(leadsApi.sendQuote);
    const { selectImageFromLibrary } = useMedia();

    const [lead, setLead] = useState<ILead | undefined>();

    const [quote, setQuote] = useState("");
    const [quoteModalVisible, setQuoteModalVisible] = useState(false);
    const [error, setError] = useState("");
    const [currentUserQuote, setCurrentUserQuote] = useState(0);
    const deleteLeadApi = useApi(leadsApi.deleteLead);
    const uploadLeadImageApi = useApi(leadsApi.uploadImageUri);
    const addContactedAgentApi = useApi(leadsApi.addContactedAgent);

    const [customerContacted, setCustomerContacted] = useState(false);

    const [selectedUsers, setSelectedUsers] = useState([]);

    const [contactCustomerModalVisible, setContactCustomerModalVisible] = useState(false);
    const [assignUserModalVisible, setAssignUserModalVisible] = useState(false);

    const [reportListingModalVisible, setReportListingModalVisible] = useState(false);
    const [confirmBookingModalVisible, setConfirmBookingModalVisible] = useState(false);

    const [noteModalVisible, setNoteModalVisible] = useState(false);
    const [note, setNote] = useState("");

    useScreenshot(route.params.leadID);

    useEffect(() => {
        getLead();
    }, []);

    const handleAddNote = async () => {
        const response = await addNoteApi.request(lead._id, note);
        if (response.ok) {
            setNoteModalVisible(false);
            setMessage("Note Added");
            getLead();
        } else {
            setMessage("Unable to add note");
        }
    };

    const handleDeleteLead = async () => {
        const response = await deleteLeadApi.request(lead._id);
        if (response.ok) {
            setMessage("Lead Deleted");
            navigation.goBack();
        } else {
            setMessage("Unable to delete lead");
        }
    };

    const getLead = async () => {
        const response = await getLeadApi.request(route.params.leadID);
        if (response.ok) {
            setLead(response.data);

            const currentUserQuote = response.data.quotes?.find((quote) => quote.user == user._id);
            if (currentUserQuote) {
                setCurrentUserQuote(currentUserQuote.amount);
            }
        } else {
            setMessage("Unable to load lead \n Please try again");
            navigation.goBack();
        }
    };

    const handleAddReport = async (report) => {
        const response = await addReportApi.request(lead._id, report.reason);
        if (response.ok) {
            setMessage("Report Sent");
        } else {
            setMessage(response.data);
        }
    };

    const handleOpenGoogleMaps = () => {
        try {
            if (lead.type == "vehicleRecovery") {
                Linking.openURL(
                    `https://www.google.com/maps/dir/?api=1&destination=${lead.location.delivery.coords.latitude},${lead.location.delivery.coords.longitude}&travelmode=driving&waypoints=${lead.location.coords.latitude},${lead.location.coords.longitude}`
                );
                return;
            }

            Linking.openURL(`https://www.google.com/maps/dir/?api=1&destination=${lead.location.coords.latitude},${lead.location.coords.longitude}&travelmode=driving`);
        } catch (error) {
            setMessage("unable to open google maps");
        }
    };

    const handleUpdateStatusBooked = async (userID) => {
        const updatedLead = await updateStatusBooked(lead._id, userID);
        if (updatedLead) {
            setLead(updatedLead);
        }
    };

    const debouncedHandleGetContactInfo = debounce(async () => {
        const response = await getContactInfoApi.request(lead._id);
        if (!response.ok) {
            showToast(response.data, "error");
            return;
        }

        setCustomerContacted(true);
        setContactCustomerModalVisible(true);
    }, 500);

    const handleAssignUser = async () => {
        const response = await getUsersBySubscriptionApi.request(lead.type);
        if (!response.ok) {
            setMessage("Error finding users");
        }

        setSelectedUsers(response.data);
        setAssignUserModalVisible(true);
    };

    const handleSetQuote = async (quote) => {
        if (/^\d+$/.test(quote)) {
            setQuote(quote);
            setError("");
        } else {
            setError("Please enter only whole numbers");
            setQuote("");
            return;
        }
    };

    const handleUpdateQuote = async () => {
        if (!quote) {
            setError("Please enter a quote");
            return;
        }

        const response = await sendQuoteApi.request(lead._id, quote);
        if (response.ok) {
            setError("");
            setQuoteModalVisible(false);
            getLead();
        } else {
            setError(response.data);
        }
    };

    const handleUpdateStatusQuoted = async (leadID: string) => {
        updateStatusQuoted(leadID);
        getLead();
    };

    const handleCallNumber = async (phone: string) => {
        setContactCustomerModalVisible(false);
        const response = await callNumber(phone);
        if (!response) {
            showToast("Unable to call number", "error");
        }
    };

    const handleSendWhatsapp = async (phone: string, lead: object) => {
        setContactCustomerModalVisible(false);
        const response = await sendWhatsapp(phone, lead);
        if (!response) {
            showToast("Unable to send Whatsapp", "error");
        }
    };

    const handleSendSMS = async (phone: string, lead: object) => {
        setContactCustomerModalVisible(false);
        const response = await sendSMS(phone, lead);
        if (!response) {
            showToast("Unable to send SMS", "error");
        }
    };

    const upLoadImageFromLibrary = async () => {
        const image = await selectImageFromLibrary();

        if (!image) {
            return;
        }

        let formdata = new FormData();
        const extension = image.uri.split(".").pop();

        // IF YOU DONT HAVE THE CURRENT MIME TYPE HERE IT WONT WORK ON ANDROID
        //AND IT WONT TELL YOU WHY, CHANGE AT YOUR OWN PERIL.
        const mimeType = mime.getType(image);

        formdata.append("file", {
            uri: image.uri,
            type: mimeType,
            name: "vehicleImage." + extension,
        });

        formdata.append("leadID", lead._id);

        const response = await uploadLeadImageApi.request(formdata);
        if (!response.ok) {
            showToast(response.data, "error");
            return;
        }

        //add images to lead state
        const updatedLead = { ...lead };
        updatedLead.images.push(response.data);
        setLead(updatedLead);
    };

    const handleMarkAsCompleted = async () => {
        const response = await updateStatusCompletedApi.request(lead._id, 5, "Trade Vehicle Marked Complete");
        if (!response.ok) {
            showToast("Cannot update lead, please contact support", "error");
            return;
        }

        showToast("Lead Marked as Complete", "success");
        navigation.goBack();
    };

    const handleSetSelectedAgent = async (agentID) => {
        const response = await addContactedAgentApi.request(agentID, lead._id);
        if (response.ok) {
            setSelectedAgent(agentID);
        } else {
            showToast("Something went wrong", "error");
        }
    };

    const handleChangeLeadType = async (type: string) => {
        const response = await changeLeadTypeApi.request(lead._id, type);
        if (!response.ok) {
            showToast("Unable to change lead type", "error");
            return;
        }
        getLead();
    };

    const handleConfirmBooking = async () => {
        const response = await updateStatusBooked(lead._id, user._id);
        if (!response) {
            showToast("Unable to confirm booking", "error");
            return;
        }

        setConfirmBookingModalVisible(false);
        getLead();
    };

    return (
        <Screen loading={uploadLeadImageApi.loading}>
            {lead && (
                <View style={{ flexDirection: "column" }}>
                    <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={{ flexDirection: "column" }}>
                        {lead.type == "vehicleParts" ? <ListingCardStock lead={lead} /> : <ListingCard lead={lead} status={lead.status} showDetails={true} />}
                        {lead.createdBy == user._id ? (
                            <>
                                <Container title={"Add Images"} icon={"camera"} onPress={upLoadImageFromLibrary} />
                                <Container title={"Mark as Completed"} subTitle={"Remove Listing"} icon={"check"} onPress={handleMarkAsCompleted} />
                                {lead.quotes?.length > 0 && (
                                    <Container title={"Bids / Quotes"} subTitle={"Select a Quote"} icon={"clipboard"} animated={false}>
                                        {lead.quotes.map((quote) => {
                                            return (
                                                <SubContainer
                                                    title={quote.user.name + " - £" + quote.amount}
                                                    subTitle={selectedAgent == quote.user._id && quote.user.contact.phone}
                                                    icon={"account"}
                                                    key={quote.user._id}
                                                    onPress={() => handleSetSelectedAgent(quote.user._id)}
                                                    utility={
                                                        selectedAgent == quote.user._id ? (
                                                            <IconTouchable name="phone" size={40} onPress={() => handleCallNumber(quote.user.contact.phone)} />
                                                        ) : (
                                                            <Icon name={"arrow-right"} iconColor={colors.tertiary} backgroundColor={colors.light} />
                                                        )
                                                    }
                                                />
                                            );
                                        })}
                                    </Container>
                                )}
                            </>
                        ) : (
                            <>
                                <Container
                                    title={"Contact Customer"}
                                    subTitle={lead?.contact.phone.substring(0, 5) + "******"}
                                    icon={"phone"}
                                    onPress={debouncedHandleGetContactInfo}
                                    utility={<MaterialCommunityIcons name={"arrow-right"} color={colors.tertiary} size={24} />}
                                />
                                {user?.subscriptions.includes(lead.type) && (
                                    <>
                                        <Container
                                            title={"Send Quote"}
                                            subTitle={currentUserQuote ? "Current Quote - £" + currentUserQuote : "Send a quote to the customer"}
                                            icon={"clipboard"}
                                            onPress={() => setQuoteModalVisible(true)}
                                            utility={<MaterialCommunityIcons name={"arrow-right"} color={colors.tertiary} size={24} />}
                                        />
                                        {lead.type == "premiumSalvage" && lead.status == "Quoted" && (
                                            <Container title={"Confirm Booking"} icon={"check"} onPress={() => setConfirmBookingModalVisible(true)} />
                                        )}
                                        {lead.type !== "vehicleTrade" && (
                                            <Container
                                                title={"Message Customer"}
                                                subTitle={"Start an In-App Chat"}
                                                icon={"chat"}
                                                onPress={
                                                    user?.isAdmin
                                                        ? () => navigation.navigate("ViewCustomerChats", { leadID: lead._id })
                                                        : () => handleStartCustomerChat(lead._id)
                                                }
                                                utility={<MaterialCommunityIcons name={"arrow-right"} color={colors.tertiary} size={24} />}
                                            />
                                        )}
                                        {!lead.agentsCompleted?.some((x) => x.userID == user._id) && lead.type == "vehicleRecovery" && (
                                            <Container
                                                title={"Mark as Completed"}
                                                subTitle={"Add this work to your history"}
                                                icon={"check"}
                                                onPress={() => navigation.navigate("AddToHistory", { leadID: lead._id })}
                                                utility={<MaterialCommunityIcons name={"arrow-right"} color={colors.tertiary} size={24} />}
                                            />
                                        )}
                                        <Container title={"Add a note"} icon={"pencil"} onPress={() => setNoteModalVisible(true)}>
                                            {lead.notes?.length > 0 &&
                                                lead.notes.map(
                                                    (note, index) =>
                                                        (user._id == note.userID[0]._id || user.isAdmin) && (
                                                            <SubContainer key={index} title={note.note} subTitle={note.userID[0].name} />
                                                        )
                                                )}
                                        </Container>
                                    </>
                                )}
                            </>
                        )}
                        {["vehicleScrap", "vehicleSalvage", "premiumSalvage"].includes(lead.type) && user.subscriptions.includes(lead.type) && (
                            <Container
                                title={"Valuation Data"}
                                subTitle={"Latest Valuation Data"}
                                icon={"calculator"}
                                onPress={() => navigation.navigate("VehicleValuation", lead.vehicle)}
                            />
                        )}
                        <Container
                            title={"MOT History"}
                            icon={"car"}
                            onPress={
                                Platform.OS === "web"
                                    ? () => Linking.openURL("https://www.check-mot.service.gov.uk/results?registration=" + lead.vehicle.registration)
                                    : () => navigation.navigate("MOTHistory", lead.vehicle.registration)
                            }
                        />
                        {customerContacted && (
                            <Container
                                title={"Report Listing"}
                                subTitle={"Help us improve listings"}
                                icon={"close"}
                                iconBackgroundColor={colors.red}
                                onPress={() => setReportListingModalVisible(true)}
                            >
                                <Picker
                                    title={"Select Reason"}
                                    onChange={(selection) => handleAddReport(selection[0])}
                                    displayProps={["reason", "details"]}
                                    modalVisible={reportListingModalVisible}
                                    setModalVisible={setReportListingModalVisible}
                                    hideSearch={true}
                                    dataItems={[
                                        { reason: "Bad Number", details: "Customer Provided a bad number" },
                                        { reason: "Cheaper Quote", details: "Customer found a cheaper quote" },
                                        { reason: "Quote Only", details: "Customer only wanted a quote" },
                                        { reason: "Cancelled", details: "Customer cancelled job after it was booked" },
                                        { reason: "Other", details: "Other Reason, please report this to the support team" },
                                    ]}
                                    filters={undefined}
                                    defaultFilter={undefined}
                                />
                            </Container>
                        )}
                        {lead.type == "vehicleRecovery" && (
                            <>
                                <Container title={"Maps"} subTitle={"View on Google Maps"} icon={"navigation"} onPress={() => handleOpenGoogleMaps()} />
                                <Container title={"Route"} subTitle={"View Offline Route"} icon={"map"} onPress={() => navigation.navigate("ListingRoute", lead)} />
                            </>
                        )}
                        {user?.isAdmin && (
                            <>
                                <Container title={"Delete"} icon={"trash-can"} iconBackgroundColor={colors.red} onPress={() => handleDeleteLead()} />
                                <Container
                                    title={"Block Number"}
                                    icon={"close"}
                                    iconBackgroundColor={colors.red}
                                    onPress={() => navigation.navigate("Block", { itemToBlock: lead.contact.phone })}
                                />
                                {lead.type == "vehicleScrap" && (
                                    <>
                                        <Container
                                            title={"Change to Salvage"}
                                            icon={"swap-horizontal"}
                                            iconBackgroundColor={colors.red}
                                            onPress={() => handleChangeLeadType("vehicleSalvage")}
                                        />
                                        <Container
                                            title={"Change to Premium"}
                                            icon={"swap-horizontal"}
                                            iconBackgroundColor={colors.red}
                                            onPress={() => handleChangeLeadType("premiumSalvage")}
                                        />
                                    </>
                                )}
                                {lead.agentID ? (
                                    <Container
                                        title={"Unassign Agent"}
                                        subTitle={"Unbook Job"}
                                        icon={"account"}
                                        iconBackgroundColor={colors.red}
                                        onPress={() => handleUpdateStatusQuoted(lead._id)}
                                    />
                                ) : (
                                    <Container
                                        title={"Assign Agent"}
                                        subTitle={"Assign an Agent to this Job"}
                                        icon={"account"}
                                        iconBackgroundColor={colors.red}
                                        onPress={handleAssignUser}
                                    />
                                )}
                                {lead.availableAgents.length > 0 && (
                                    <Container title={"Agents who have called"} icon={"phone"} iconBackgroundColor={colors.red}>
                                        {lead.availableAgents.map((agent) => (
                                            <SubContainer
                                                onPress={() => navigation.navigate("UserDetails", { userID: agent._id })}
                                                key={agent._id}
                                                title={agent.name}
                                                utility={<MaterialCommunityIcons name={"arrow-right"} />}
                                            />
                                        ))}
                                    </Container>
                                )}
                            </>
                        )}
                        <Picker
                            title={"Select User"}
                            onChange={(selection) => handleUpdateStatusBooked(selection[0]._id)}
                            displayProps={["name", "email"]}
                            modalVisible={assignUserModalVisible}
                            setModalVisible={setAssignUserModalVisible}
                            dataItems={selectedUsers}
                            filters={undefined}
                            defaultFilter={undefined}
                        />
                    </ScrollView>
                </View>
            )}

            <Modal animationType={"fade"} visible={noteModalVisible} transparent={true}>
                <View style={{ backgroundColor: colors.tertiary, flex: 1, paddingTop: 47 }}>
                    <HeaderBackButton onPress={() => setNoteModalVisible(false)} tintColor={colors.red} labelStyle={{ color: colors.white }} />
                    <View style={{ marginHorizontal: 16, paddingTop: 40 }}>
                        <AppTextInput style={{ height: 200 }} multiline={true} placeholder={"Enter Note"} onChangeText={setNote} />
                        <AppButton style={{ marginTop: 12 }} title={"Confirm"} onPress={handleAddNote} />
                    </View>
                </View>
            </Modal>

            <Modal animationType={"fade"} visible={quoteModalVisible}>
                <View style={{ backgroundColor: colors.tertiary, flex: 1, paddingTop: 47 }}>
                    <View style={{ marginHorizontal: 16 }}>
                        <HeaderBackButton onPress={() => setQuoteModalVisible(false)} tintColor={colors.red} labelStyle={{ color: colors.white }} />
                        <ContainerNew title={"Quoting System"}>
                            <ListItem icon={"information-variant"} iconRight={"check"} title={"Every quote is sent to the customer with your contact information"} />
                        </ContainerNew>
                        <View style={{ paddingTop: 80 }} />
                        <AppTextInput icon={"currency-gbp"} keyboardType={"numeric"} placeholder={"Enter your quote"} onChangeText={handleSetQuote} />
                        <AppButton style={{ marginTop: 12 }} title={"Confirm"} onPress={handleUpdateQuote} />
                    </View>
                </View>
            </Modal>
            <Modal animationType={"fade"} visible={confirmBookingModalVisible}>
                <View style={{ backgroundColor: colors.tertiary, flex: 1, paddingTop: 47 }}>
                    <View style={{ marginHorizontal: 16 }}>
                        <HeaderBackButton onPress={() => setConfirmBookingModalVisible(false)} tintColor={colors.red} labelStyle={{ color: colors.white }} />
                        <ContainerNew iconColor={colors.green} title={"Confirm Booking for\n" + lead?.vehicle?.make + " - " + lead?.vehicle?.model} icon="check">
                            <ListItem iconRightColor={colors.redNew} iconRight="alert" title={"No new agents will be able to contact this listing"} />
                            <ListItem iconRightColor={colors.redNew} iconRight="alert" title={"A charge will be applied to your account at the end of the week"} />
                        </ContainerNew>

                        <View style={{ paddingTop: 80 }} />
                        <AppButton style={{ marginTop: 12 }} title={"Confirm"} onPress={handleConfirmBooking} />
                    </View>
                </View>
            </Modal>

            <Modal animationType="fade" transparent={true} visible={contactCustomerModalVisible}>
                <View
                    style={{
                        justifyContent: "flex-end",
                        flex: 1,
                    }}
                >
                    <View
                        style={{
                            position: "absolute",
                            alignSelf: "center",
                            width: "100%",
                            height: "100%",
                            backgroundColor: colors.tertiary,
                            opacity: 0.85,
                        }}
                    ></View>
                    <View
                        style={{
                            justifyContent: "flex-start",
                            backgroundColor: colors.tertiary,
                            borderRadius: defaultStyles.borderRadius,
                            paddingBottom: 64,
                            paddingTop: 20,
                            borderTopColor: colors.white,
                            borderTopWidth: 2,
                            padding: 8,
                        }}
                    >
                        <Container
                            title={"Call"}
                            subTitle={lead?.contact?.phone}
                            icon={"phone"}
                            iconColor={colors.green}
                            onPress={() => handleCallNumber(lead.contact.phone)}
                        />
                        <Container title={"Send Whatsapp"} icon={"whatsapp"} iconColor={colors.green} onPress={() => handleSendWhatsapp(lead.contact.phone, lead)} />
                        <Container title={"Send Text or iMessage"} icon={"chat"} iconColor={colors.green} onPress={() => handleSendSMS(lead.contact.phone, lead)} />
                        <Container title={"Close"} icon={"close"} iconColor={colors.red} onPress={() => setContactCustomerModalVisible(false)} />
                    </View>
                </View>
            </Modal>
        </Screen>
    );
}

export default ListingDetailsScreen;
