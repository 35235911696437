import { useContext } from "react";
import PopUpContext from "../context/popUpContext";

const usePopUp = () => {
    const { message, setMessage, popUpOnConfirm, setPopUpOnConfirm } = useContext(PopUpContext);

    //Provide a function to set the popUpOnConfirm function and message
    const confirmDialog = (onConfirm, message) => {
        setPopUpOnConfirm(() => () => {
            onConfirm();
        });
        setMessage(message);
    };

    return { message, setMessage, popUpOnConfirm, setPopUpOnConfirm, confirmDialog };
};

export default usePopUp;
