import { Platform } from "react-native";
import * as Linking from "expo-linking";
import * as Device from "expo-device";
import { ILead } from "../interfaces/lead";

//SUSBSCRIPTIONS
const setupMessage = (lead) => {
    let message = " ";

    if (lead.type == "vehicleRecovery") {
        message = `Hi, This is your local Cheap Car Recovery agent. Would you like to book in the recovery of ${lead.vehicle.registration} today?`;
    }

    if (["vehicleSalvage", "vehicleScrap", "premiumSalvage"].includes(lead.type)) {
        message = `Hi, This is your local Car Salvage Group agent. Would you like to book in ${lead.vehicle.registration} today?`;
    }

    return message;
};

export const callNumber = async (phoneNumber: string) => {
    try {
        let phoneLink = `tel:${phoneNumber}`;

        //check if iframe
        if (window.self !== window.top) {
            const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
            if (isSafari) {
                phoneLink = `telprompt:${phoneNumber}`;
            }
        }

        return await Linking.openURL(phoneLink);
    } catch (error) {
        return false;
    }
};

export const sendWhatsapp = async (phoneNumber: string, lead?: ILead) => {
    var message = "";
    if (lead) {
        message = setupMessage(lead);
    }

    let WhatsAppLink = `whatsapp://send?text=${message}&phone=+44${phoneNumber}`;

    try {
        return await Linking.openURL(WhatsAppLink);
    } catch (error) {
        return false;
    }
};

export const sendWhatsappMessage = async (phoneNumber: string, message: string) => {
    let WhatsAppLink = `whatsapp://send?text=${message || ""}&phone=+44${phoneNumber}`;

    try {
        return await Linking.openURL(WhatsAppLink);
    } catch (error) {
        return false;
    }
};

export const sendSMSMessage = async (phoneNumber: string, message: string) => {
    let smsLink = "";
    if (Platform.OS === "ios") {
        smsLink = `sms:${phoneNumber}&body=${message}`;
    }

    if (Platform.OS === "android") {
        smsLink = `sms:${phoneNumber}?body=${message}`;
    }

    if (Platform.OS == "web") {
        smsLink = `sms:${phoneNumber}`;

        if (Device.osName == "iOS") {
            smsLink = `sms:${phoneNumber}&body=${message}`;
        }

        if (Device.osName == "Android") {
            smsLink = `sms:${phoneNumber}?body=${message}`;
        }
    }

    if (!smsLink) {
        return;
    }

    try {
        return await Linking.openURL(smsLink);
    } catch (error) {
        return false;
    }
};

export const sendSMS = async (phoneNumber: string, lead: object) => {
    var message = "";

    if (lead) {
        message = setupMessage(lead);
    }

    message = encodeURIComponent(message);

    var smsLink = "";
    if (Platform.OS === "ios") {
        smsLink = `sms:${phoneNumber}&body=${message}`;
    }

    if (Platform.OS === "android") {
        smsLink = `sms:${phoneNumber}?body=${message}`;
    }

    if (Platform.OS == "web") {
        smsLink = `sms:${phoneNumber}`;

        if (Device.osName == "iOS") {
            smsLink = `sms:${phoneNumber}&body=${message}`;
        }

        if (Device.osName == "Android") {
            smsLink = `sms:${phoneNumber}?body=${message}`;
        }
    }

    if (!smsLink) {
        return;
    }

    try {
        return await Linking.openURL(smsLink);
    } catch (error) {
        return false;
    }
};
