import client from "./client";

const register = (name: string, email: string, phone: string, postcode: string, password: string, filterTypes: [string]) =>
    client.post("/users", { name, email, phone, postcode, password, filterTypes });
const getConfig = (appVersion: string, buildVersion: string, deviceID: string, deviceModel: string) =>
    client.post("/users/getConfig", { appVersion, buildVersion, deviceID, deviceModel });
const getUsersBySubscription = (subscripton: string) => client.get("/users/getUsersBySubscription/" + subscripton);

const sendTestPushNotification = (userID: string) => client.post("/users/sendTestPushNotification", { userID });

const getUser = (userID: string) => client.get("/users/getUser/" + userID);
const getUsers = () => client.get("/users");
const getMismatchUsers = () => client.get("/users/mismatchUsers");
const refreshMismatchUsers = () => client.get("/users/refreshMismatchUsers");
const getBankUsers = () => client.get("/users/bankUsers");
const getSuspiciousUsers = () => client.get("/users/suspiciousUsers");
const getDuplicatedNumbers = () => client.get("/users/duplicatedNumbers");
const getSharedAccounts = () => client.get("/users/sharedAccounts");
const getBlockedItems = (type: string) => client.get("/users/blocked/" + type);
const removeBlockedItem = (itemID: string) => client.post("/users/removeBlockedItem", { itemID });
const blockUser = (userID: string, reason: string) => client.post("/users/block", { userID, reason });
const setName = (userID: string, name: string) => client.post("/users/setName", { userID, name });
const setEmail = (userID: string, email: string) => client.post("/users/setEmail", { userID, email });
const setPhone = (userID: string, phone: string) => client.post("/users/setPhone", { userID, phone });
const setLandline = (userID: string, phone: string) => client.post("/users/setLandline", { userID, phone });
const setNote = (userID: string, note: string) => client.post("/users/setNote", { userID, note });
const setPostcode = (userID: string, postcode: string) => client.post("/users/setPostcode", { userID, postcode });
const addVehicle = (userID: string, registration: string) => client.post("/users/addVehicle/", { userID, registration });
const getVehicles = (userID: string) => client.get("/users/getVehicles/" + userID);
const deleteUserVehicle = (registration: string) => client.post("/users/removeVehicle", { registration });
const setPushToken = (token) => client.post("/users/setPushToken", { token });
const setPaypalID = (userID: string, paypalID: string, subType: string, nextPaymentDate: string, lastPaymentAmount: number) =>
    client.post("/users/setPaypalID", { userID, paypalID, subType, nextPaymentDate, lastPaymentAmount });
const getPaypalTransactionHistory = (paypalID: string) => client.get("/users/getTransactionHistory/" + paypalID);
const setUserReviewDate = (userID: string) => client.post("/users/setUserReviewDate", { userID });
const setLastContactedDate = (agentID: string) => client.post("/users/setLastContactedDate", { agentID });
const addPayPalSubscription = (subscription: string, plan: string) => client.post("/users/addPaypalSubscription", { subscription, plan });
const cancelPayPalSubscription = (subscriptionID: string, subType: string, userID: string, agentFeedback: string) =>
    client.post("/users/cancelPaypalSubscription", { subscriptionID, subType, userID, agentFeedback });
const getUserReviewDate = () => client.get("/users/getUserReviewDate");
const setLocation = (location) => client.post("/users/setLocation", { location: location });
const addSubscription = (userID: string, subscription) => client.post("/users/addSubscription", { userID, subscription });
const removeSubscription = (userID: string, subscription) => client.post("/users/removeSubscription", { userID, subscription });
const sendPushNotification = (messageInfo) => client.post("/users/sendPushNotification", messageInfo);
const sendMessage = (messageInfo) => client.post("/users/sendMessage", messageInfo);
const validatePostcode = (postcode: string) => client.get("/users/validatePostcode/" + postcode);
const setNextPaymentDate = (userID: string, date, subType: string) => client.post("/users/setNextPaymentDate", { userID, date, subType });
const getMessages = () => client.get("/users/messages");
const setPassword = (password: string, newPassword: string) => client.post("/users/setPassword", { password, newPassword });
const userSearch = (query: string) => client.get("/users/search/" + query);
const screenshotDetected = (leadID: string) => client.post("/users/screenshotDetected/", { leadID });
const getMatchingUsers = (deviceID: string) => client.get("/users/getMatchingUsers/" + deviceID);
const setLeadTypeFilters = (type: string) => client.post("/users/setLeadTypeFilters", { type });
const setLeadDistanceFilter = (distance: number) => client.post("/users/setLeadDistanceFilter", { distance });
const abandonedCheckout = (userID: string, subscriptionName: string, error: string) => client.post("/users/abandonedCheckout", { userID, subscriptionName, error });
const setIsVerified = (userID: string) => client.post("/users/setIsVerified", { userID });
const promoteDevice = (userID: string, deviceID: string) => client.post("/users/promoteDevice", { userID, deviceID });
const createSubscription = (subscriptionID: string, subType: string) => client.post("/users/createSubscription", { subscriptionID, subType });

const sendCrashReport = (error, platform) => client.post("/users/sendCrashReport", { error, platform });
const uploadProfileImage = (formData) => client.post("/users/uploadImage", formData);

const getOnboardingUsers = () => client.get("/users/onboardingUsers/");
const selectUserForOnboarding = (userID: string) => client.post("/users/selectUserForOnboarding", { userID });
const myOnboardedUsers = () => client.get("/users/myOnboardedUsers");
const completeOnboarding = (userID: string, reason: string) => client.post("/users/completeOnboarding", { userID, reason });
const onboardingDetails = (userID: string, type: string) => client.post("/users/onboardingDetails", { userID, type });
const onboardingDiscountMessageSent = (userID: string) => client.put("/users/onboardingDiscountMessageSent", { userID });
const onboardingWelcomeMessageSent = (userID: string) => client.put("/users/onboardingWelcomeMessageSent", { userID });

export default {
    register,
    getConfig,
    getVehicles,
    addVehicle,
    deleteUserVehicle,
    addSubscription,
    removeSubscription,
    setPushToken,
    setLocation,
    getUsers,
    sendPushNotification,
    validatePostcode,
    sendMessage,
    setIsVerified,
    setUserReviewDate,
    getUserReviewDate,
    setPaypalID,
    getMessages,
    getUser,
    getPaypalTransactionHistory,
    setNextPaymentDate,
    addPayPalSubscription,
    cancelPayPalSubscription,
    promoteDevice,
    setName,
    setPhone,
    setLandline,
    setPostcode,
    setPassword,
    getMismatchUsers,
    getBankUsers,
    setNote,
    uploadProfileImage,
    getBlockedItems,
    setLastContactedDate,
    sendCrashReport,
    userSearch,
    getUsersBySubscription,
    refreshMismatchUsers,
    screenshotDetected,
    setEmail,
    getMatchingUsers,
    getSuspiciousUsers,
    getDuplicatedNumbers,
    getSharedAccounts,
    setLeadTypeFilters,
    removeBlockedItem,
    blockUser,
    setLeadDistanceFilter,
    abandonedCheckout,
    sendTestPushNotification,

    //onboarding
    selectUserForOnboarding,
    myOnboardedUsers,
    completeOnboarding,
    getOnboardingUsers,
    onboardingDetails,
    onboardingDiscountMessageSent,
    onboardingWelcomeMessageSent,
    createSubscription,
};
