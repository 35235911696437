import React, { useRef } from "react";
import { View, Animated, TouchableWithoutFeedback } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import AppText from "./AppText";
import colors from "../config/colors";

interface Props {
    name: keyof typeof MaterialCommunityIcons.glyphMap;
    size?: number;
    backgroundColor?: string;
    iconColor?: string;
    borderColor?: string;
    borderWidth?: number;
    iconText?: string;
    onPress?: any;
    animated?: boolean;
    actionBeforeAnimate?: boolean;
    counter?: number;
    text?: string;
    textColor?: string;
}

const IconTouchable: React.FC<Props> = ({
    name,
    size = 55,
    textColor = colors.white,
    backgroundColor = colors.tertiary,
    iconColor = colors.white,
    onPress = () => {},
    borderColor = colors.silver,
    text,
    counter = 0,
    animated = true,
    iconText,
    actionBeforeAnimate = false,
}) => {
    if (typeof counter == "undefined") {
        counter = 0;
    }

    const glowValue = useRef(new Animated.Value(0)).current;
    const springValue = useRef(new Animated.Value(1)).current;

    const spring = () => {
        if (!animated) {
            onPress();
            return;
        }

        if (actionBeforeAnimate) {
            onPress();
            glowValue.setValue(0);
            Animated.timing(glowValue, {
                toValue: 2,
                duration: 200,
                useNativeDriver: false,
            }).start();

            springValue.setValue(0.7);
            Animated.spring(springValue, {
                toValue: 1,
                friction: 4,
                useNativeDriver: false,
            }).start();
            return;
        }

        glowValue.setValue(0);
        Animated.timing(glowValue, {
            toValue: 2,
            duration: 200,
            useNativeDriver: false,
        }).start(() => onPress());

        springValue.setValue(0.7);
        Animated.spring(springValue, {
            toValue: 1,
            friction: 4,
            useNativeDriver: false,
        }).start();
    };

    return (
        <TouchableWithoutFeedback onPress={spring}>
            <View style={{ justifyContent: "center", alignItems: "center" }}>
                <Animated.View
                    style={{
                        width: size,
                        height: size,
                        borderRadius: size / 2,
                        backgroundColor,
                        justifyContent: "center",
                        alignItems: "center",
                        borderColor: glowValue.interpolate({
                            inputRange: [0, 0.1, 2],
                            outputRange: [borderColor, colors.red, borderColor],
                        }),
                        borderWidth: 2,
                        transform: [{ scale: springValue }],
                    }}
                >
                    {iconText ? (
                        <AppText style={{ color: colors.white, fontWeight: "bold", textTransform: "uppercase" }}>
                            {iconText
                                .split(" ")
                                .map((n) => n[0])
                                .join("")}
                        </AppText>
                    ) : (
                        <MaterialCommunityIcons name={name} color={iconColor} size={size * 0.5} />
                    )}
                </Animated.View>
                {text && <AppText style={{ fontSize: 13, color: textColor, fontWeight: "900" }}>{text}</AppText>}
            </View>
        </TouchableWithoutFeedback>
    );
};

export default IconTouchable;
