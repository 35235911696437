import React, { useEffect, useState } from "react";

import Screen from "../components/Screen";
import AppText from "../components/AppText";
import colors from "../config/colors";
import TabSelectorNew from "../components/TabSelectorNew";
import { ScrollView, View } from "react-native";
import AppButton from "../components/AppButton";
import Pill from "../components/Pill";
import AppTextInput from "../components/AppTextInput";
import usersApi from "../api/users";
import useApi from "../hooks/useApi";
import useToast from "../hooks/useToast";
import ListItem from "../components/ListItem";
import config from "../config/config";
import ContainerNew from "../components/ContainerNew";
import { callNumber, sendSMSMessage, sendWhatsappMessage } from "../utility/contactUser";

export default function CompleteOnboardingScreen({ navigation, route }) {
    const [user, setUser] = useState();
    const [selectedReason, setSelectedReason] = useState("subscribed");
    const { showToast } = useToast();
    const [leadData, setLeadData] = useState();
    const [selectedLeadType, setSelectedLeadType] = useState("");
    const [note, setNote] = useState("");

    const completeOnboardingApi = useApi(usersApi.completeOnboarding);
    const onboardingDetailsApi = useApi(usersApi.onboardingDetails);
    const getUserApi = useApi(usersApi.getUser);
    const setUserNoteApi = useApi(usersApi.setNote);
    const onboardingDiscountMessageSentApi = useApi(usersApi.onboardingDiscountMessageSent);
    const onboardingWelcomeMessageSentApi = useApi(usersApi.onboardingWelcomeMessageSent);

    useEffect(() => {
        getUser();
    }, []);

    useEffect(() => {
        if (selectedLeadType) {
            getOnboardingDetails();
        }
    }, [selectedLeadType]);

    const getUser = async () => {
        const response = await getUserApi.request(route.params.userID);
        if (!response.ok) {
            showToast("Something went wrong, please try again", "error");
            return;
        }

        setUser(response.data);
    };

    const reasons = ["no response", "too expensive", "needs more time"];

    const completeOnboarding = async () => {
        if (!selectedReason) {
            showToast("Please select a reason", "error");
            return;
        }

        const response = await completeOnboardingApi.request(user._id, "not interested");
        if (!response.ok) {
            showToast("Something went wrong, please try again", "error");
            return;
        }

        navigation.goBack();
    };

    const getOnboardingDetails = async () => {
        const response = await onboardingDetailsApi.request(route.params.userID, selectedLeadType);
        if (!response.ok) {
            console.log(response.data);
            showToast("Something went wrong, please try again", "error");
            return;
        }

        setLeadData({ leadsWithin30: response.data.leadsWithin30 });
    };

    const handleUpdateNote = async () => {
        if (!note) {
            showToast("Please enter a valid Note", "error");
            return;
        }

        const response = await setUserNoteApi.request(route.params.userID, note);
        if (!response.ok) {
            showToast(response.data, "error");
            return;
        }
    };

    const sendDiscountMessage = async () => {
        const response = await onboardingDiscountMessageSentApi.request(route.params.userID);
        if (!response.ok) {
            showToast("Something went wrong, please contact support again", "error");
            return;
        }

        setUser({ ...user, onboardingDiscountMessageSent: true });

        sendWhatsappMessage(user.contact.phone, "Hi, Darren @ makemebusy. Still not sure? We can offer a 48 hr trial. If interested please reply TRIAL");
    };

    const sendWelcomeMessage = async () => {
        const response = await onboardingWelcomeMessageSentApi.request(route.params.userID);
        if (!response.ok) {
            showToast("Something went wrong, please contact support again", "error");
            return;
        }

        setUser({ ...user, onboardingWelcomeMessageSent: true });

        sendWhatsappMessage(
            user.contact.phone,
            "Darren @ Make Me Busy. Hi, You recently registered on Make Me Busy. If you have any questions please call or reply.\n\n" +
                "We currently have " +
                leadData?.leadsWithin30 +
                " leads within 30 miles of " +
                user.location.postcode +
                "\n\nWeekly Cost- " +
                config.subscriptions.find((subscription) => subscription.name == selectedLeadType)?.weeklyPricing +
                "\n" +
                "Monthly Cost- " +
                config.subscriptions.find((subscription) => subscription.name == selectedLeadType)?.monthlyPricing +
                " ( 25 percent discount )"
        );
    };

    return (
        <Screen style={{ margin: 16 }}>
            <ScrollView contentContainerStyle={{ rowGap: 16, flex: 1 }}>
                <AppTextInput multiline={true} defaultValue={user?.note || ""} onChangeText={setNote} placeholder={"Enter new Note"} onBlur={handleUpdateNote} />
                <AppText style={{ color: colors.white }}>Which Subscription?</AppText>
                <View>
                    <View style={{ flexDirection: "row" }}>
                        <Pill
                            title={"Recovery"}
                            onPress={() => setSelectedLeadType("vehicleRecovery")}
                            style={{ backgroundColor: selectedLeadType == "vehicleRecovery" ? colors.top2 : colors.mid }}
                        />
                        <Pill
                            title={"Scrap"}
                            onPress={() => setSelectedLeadType("vehicleScrap")}
                            style={{ backgroundColor: selectedLeadType == "vehicleScrap" ? colors.top2 : colors.mid }}
                        />
                        <Pill
                            title={"Salvage"}
                            onPress={() => setSelectedLeadType("vehicleSalvage")}
                            style={{ backgroundColor: selectedLeadType == "vehicleSalvage" ? colors.top2 : colors.mid }}
                        />
                    </View>
                </View>

                {selectedLeadType && (
                    <>
                        <ContainerNew
                            title={config.subscriptions.find((subscription) => subscription.name == selectedLeadType)?.description}
                            icon={config.subscriptions.find((subscription) => subscription.name == selectedLeadType)?.iconName}
                        >
                            <ListItem title={"Weekly - " + config.subscriptions.find((subscription) => subscription.name == selectedLeadType)?.weeklyPricing} />
                            <ListItem
                                title={"Monthly - " + config.subscriptions.find((subscription) => subscription.name == selectedLeadType)?.monthlyPricing + " ( 25% discount )"}
                            />
                            {leadData && (
                                <ListItem
                                    title={
                                        onboardingDetailsApi.loading ? "Loading Results..." : leadData?.leadsWithin30 + " Leads within 30 miles of " + user?.location.postcode
                                    }
                                />
                            )}
                        </ContainerNew>
                        <View style={{ flexDirection: "row" }}>
                            <Pill
                                style={{
                                    justifyContent: "space-evenly",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    borderColor: user?.onboardingWelcomeMessageSent ? colors.green : colors.red,
                                    borderWidth: 2,
                                }}
                                title={"Welcome"}
                                onPress={sendWelcomeMessage}
                                icon="whatsapp"
                            />
                            <Pill
                                style={{
                                    justifyContent: "space-evenly",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    borderColor: user?.onboardingDiscountMessageSent ? colors.green : colors.red,
                                    borderWidth: 2,
                                }}
                                title={"Trial"}
                                onPress={sendDiscountMessage}
                                icon="whatsapp"
                            />
                            <Pill
                                style={{ justifyContent: "space-evenly", alignItems: "center", flexDirection: "row" }}
                                title={user?.contact.phone}
                                onPress={() => callNumber(user?.contact.phone)}
                                icon="phone"
                            />
                        </View>
                        <View style={{ flexDirection: "row" }}>
                            <Pill
                                style={{
                                    justifyContent: "space-evenly",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    borderColor: user?.onboardingWelcomeMessageSent ? colors.green : colors.red,
                                    borderWidth: 2,
                                }}
                                title={"Welcome"}
                                onPress={() =>
                                    sendSMSMessage(
                                        user.contact.phone,
                                        "Darren @ Make Me Busy. Hi, You recently registered on Make Me Busy. If you have any questions please call or reply.\n\n" +
                                            "We currently have " +
                                            leadData?.leadsWithin30 +
                                            " leads within 30 miles of " +
                                            user.location.postcode +
                                            "\n\nWeekly Cost- " +
                                            config.subscriptions.find((subscription) => subscription.name == selectedLeadType)?.weeklyPricing +
                                            "\n" +
                                            "Monthly Cost- " +
                                            config.subscriptions.find((subscription) => subscription.name == selectedLeadType)?.monthlyPricing +
                                            " ( 25 percent discount )"
                                    )
                                }
                                icon="message-text"
                            />
                            <Pill
                                style={{
                                    justifyContent: "space-evenly",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    borderColor: user?.onboardingDiscountMessageSent ? colors.green : colors.red,
                                    borderWidth: 2,
                                }}
                                title={"Trial"}
                                onPress={() =>
                                    sendSMSMessage(user.contact.phone, "Hi, Darren @ makemebusy. Still not sure? We can offer a 48 hr trial. If interested please reply TRIAL")
                                }
                                icon="message-text"
                            />
                        </View>
                        {/*          <AppText style={{ color: colors.white }}>Did the agent subscribe?</AppText>
                        <TabSelectorNew
                            tabs={[
                                { name: "Yes", value: "subscribed" },
                                { name: "No", value: "not subscribed" },
                            ]}
                            onPress={(value) => setSelectedReason(value.value)}
                        /> */}
                    </>
                )}
                {/*       {selectedReason !== "subscribed" && (
                    <>
                        <AppText style={{ color: colors.white }}>Why weren't they interested?</AppText>
                        <AppTextInput
                            multiline={true}
                            style={{ height: 56 }}
                            placeholder={"enter reason"}
                            onChangeText={setSelectedReason}
                            returnKeyType="done"
                            blurOnSubmit={true}
                            value={selectedReason}
                        />
                        <View style={{ flexDirection: "row" }}>
                            {reasons.map((reason) => {
                                return <Pill key={reason} title={reason} onPress={() => setSelectedReason(reason)} />;
                            })}
                        </View>
                    </>
                )} */}
            </ScrollView>
            <View style={{ justifyContent: "flex-end" }}>
                <AppButton title={"Complete Onboarding"} onPress={completeOnboarding} />
            </View>
        </Screen>
    );
}
