import React, { useEffect, useState } from "react";
import { FlatList } from "react-native";

import Screen from "../components/Screen";
import useApi from "../hooks/useApi";
import leadsApi from "../api/leads";
import usePopUp from "../hooks/usePopUp";
import Container from "../components/Container";
import IconTouchable from "../components/IconTouchable";
import colors from "../config/colors";
import { subDays } from "date-fns";

function BookedPremiumSalvageScreen({ navigation }) {
    const getBookedPremiumSalvageApi = useApi(leadsApi.getBookedPremiumSalvage);
    const updateStatusCompletedApi = useApi(leadsApi.updateStatusCompleted);

    const { setMessage } = usePopUp();

    const [leads, setLeads] = useState(null);

    useEffect(() => {
        if (!leads) {
            getLeads();
        }
    }, [leads]);

    const getLeads = async () => {
        const response = await getBookedPremiumSalvageApi.request();
        if (!response.ok) {
            setMessage("No Jobs Found");
            return;
        }

        response.data.sort((a, b) => {
            if (a.agentID.name < b.agentID.name) {
                return -1;
            }
            if (a.agentID.name > b.agentID.name) {
                return 1;
            }
            return 0;
        });
        setLeads(response.data);
    };

    const handleMarkAsCompleted = async (leadID: string) => {
        const response = await updateStatusCompletedApi.request(leadID, 5, "Premium Salvage Paid");
        if (!response.ok) {
            setMessage(response.data);
            return;
        }

        //remove lead from lead array
        const newLeads = leads.filter((lead) => lead._id !== leadID);
        setLeads(newLeads);

        setMessage("Lead Marked as Paid");
    };

    return (
        <Screen loading={getBookedPremiumSalvageApi.loading}>
            <FlatList
                data={leads && leads}
                keyExtractor={(item) => item._id}
                showsVerticalScrollIndicator={true}
                renderItem={({ item }) => (
                    <Container
                        onPress={() => navigation.navigate("ListingDetails", { leadID: item._id })}
                        title={item.vehicle.registration}
                        backgroundColor={new Date(item.created) < subDays(new Date(), 7) && colors.red}
                        subTitle={item.agentID.name}
                        utility={<IconTouchable onPress={() => handleMarkAsCompleted(item._id)} name={"check"} size={40} />}
                    />
                )}
            />
        </Screen>
    );
}

export default BookedPremiumSalvageScreen;
