// React
import React from "react";
import { ScrollView } from "react-native";

// Components
import Screen from "../../components/Screen";
// Hooks
import usePopUp from "../../hooks/usePopUp";
// Api's
import Container from "../../components/Container";
import SubContainer from "../../components/SubContainer";
import useApi from "../../hooks/useApi";
import leadsApi from "../../api/leads";
import { useEffect } from "react";
import { useState } from "react";
import colors from "../../config/colors";
import IconTouchable from "../../components/IconTouchable";
import { callNumber } from "../../utility/contactUser";
import Icon from "../../components/Icon";
import jwtDecode from "jwt-decode";
import authStorage from "../../auth/storage";

export default function QuotesScreen({ navigation }) {
    const { setMessage } = usePopUp();

    const [selectedAgent, setSelectedAgent] = useState(null);
    const [lead, setLead] = useState(null);

    const getLeadApi = useApi(leadsApi.getLeadCustomer);
    const addContactedAgentApi = useApi(leadsApi.addContactedAgent);

    useEffect(() => {
        getLead();
    }, []);

    const handleCallAgent = async (phone) => {
        callNumber(phone);
    };

    const handleSetSelectedAgent = async (agentID) => {
        const response = await addContactedAgentApi.request(agentID, lead._id);
        if (response.ok) {
            setSelectedAgent(agentID);
        } else {
            setMessage("Something went wrong");
        }
    };

    const getLead = async () => {
        let lead = await authStorage.getToken();
        if (lead) {
            lead = jwtDecode(lead);
        }

        if (!lead || !lead._id) {
            setMessage("Unable to load lead \n Please try again");
            return;
        }

        const response = await getLeadApi.request(lead._id);
        if (response.ok) {
            setLead(response.data);
        } else {
            setMessage("Unable to load lead \n Please try again");
            navigation.goBack();
        }
    };

    return (
        <Screen loading={getLeadApi.loading}>
            {lead && (
                <ScrollView style={{ flex: 1 }} bounces={false} refreshControl={null}>
                    <Container title={"Refresh Quotes"} icon={"refresh"} onPress={getLead}></Container>
                    {!lead.agentID && (
                        <Container title={"Quotes"} subTitle={"Select a Quote"} icon={"clipboard"} iconColor={colors.green} animated={false}>
                            {lead.quotes?.length > 0 ? (
                                lead.quotes.map((quote) => {
                                    return (
                                        <SubContainer
                                            title={quote.user.name + " - £" + quote.amount}
                                            subTitle={selectedAgent == quote.user._id && quote.user.contact.phone}
                                            backgroundColor={selectedAgent == quote.user._id ? colors.honeyComb : colors.light}
                                            icon={"account"}
                                            key={quote.user._id}
                                            onPress={() => handleSetSelectedAgent(quote.user._id)}
                                            utility={
                                                selectedAgent == quote.user._id ? (
                                                    <IconTouchable name="phone" onPress={() => handleCallAgent(quote.user.contact.phone)} />
                                                ) : (
                                                    <Icon name={"arrow-right"} iconColor={colors.tertiary} backgroundColor={colors.light} />
                                                )
                                            }
                                        />
                                    );
                                })
                            ) : (
                                <SubContainer title={"Check back - quotes are updated frequently."} />
                            )}
                        </Container>
                    )}
                    {!lead.agentID && (
                        <Container title={"Available Agents"} subTitle={"Select an Agent"} icon={"phone"} animated={false}>
                            {lead.availableAgents?.length > 0 ? (
                                lead.availableAgents.map((user) => {
                                    return (
                                        <SubContainer
                                            title={user.name}
                                            subTitle={selectedAgent == user._id && user.contact.phone}
                                            backgroundColor={selectedAgent == user._id ? colors.honeyComb : colors.light}
                                            icon={"account"}
                                            key={user._id}
                                            onPress={() => handleSetSelectedAgent(user._id)}
                                            utility={
                                                selectedAgent == user._id ? (
                                                    <IconTouchable size={40} name="phone" onPress={() => handleCallAgent(user.contact.phone)} />
                                                ) : (
                                                    <Icon name={"arrow-right"} iconColor={colors.tertiary} backgroundColor={colors.light} />
                                                )
                                            }
                                        />
                                    );
                                })
                            ) : (
                                <SubContainer title={"We have notified all of our available agents. You should receive a call shortly."} />
                            )}
                        </Container>
                    )}
                </ScrollView>
            )}
        </Screen>
    );
}
