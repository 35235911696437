import React from "react";
import { View, Pressable, PressableProps, StyleSheet } from "react-native";

import AppText from "./AppText";
import colors from "../config/colors";
import { MaterialCommunityIcons } from "@expo/vector-icons";

interface Props extends PressableProps {
    title: string;
    subTitle?: string;
    icon?: keyof typeof MaterialCommunityIcons.glyphMap;
    iconRight?: keyof typeof MaterialCommunityIcons.glyphMap;
    style?: object;
    iconRightColor?: string;
    iconColor?: string;
}

export default function Pill({ title, subTitle, icon, iconColor, iconRight, iconRightColor, style, ...otherProps }: Props) {
    return (
        <Pressable style={[styles.container, style]} {...otherProps}>
            {icon && <MaterialCommunityIcons name={icon || "account"} size={20} color={iconColor || colors.white} style={{ paddingRight: 4 }} />}
            <View style={{ alignItems: "center" }}>
                <AppText style={{ color: colors.white, fontSize: 14 }}>{title}</AppText>
            </View>
            {iconRight && <MaterialCommunityIcons name={iconRight || "chevron-right"} size={20} color={iconRightColor || colors.white} />}
        </Pressable>
    );
}

const styles = StyleSheet.create({
    container: {
        padding: 8,
        borderRadius: 32,
        backgroundColor: colors.mid,
    },
});
