import React from "react";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";

import ListingsScreen from "../screens/ListingsScreen";
import NotificationsScreen from "../screens/NotificationsScreen";
import MyDashboardScreen from "../screens/MyDashboardScreen";
import ListingsMapScreen from "../screens/ListingsMapScreen";

import useNotifications from "../hooks/useNotifications";
import useNews from "../hooks/useNews";
import colors from "../config/colors";
import { MaterialCommunityIcons } from "@expo/vector-icons";

const AppTabNavigator = () => {
    const { notifications } = useNotifications();
    const { news } = useNews();

    const Tab = createBottomTabNavigator();

    return (
        <Tab.Navigator
            tabBarOptions={{
                activeTintColor: colors.red,
                inactiveTintColor: colors.white,
                activeBackgroundColor: colors.tertiary,
                inactiveBackgroundColor: colors.tertiary,
                labelStyle: {
                    fontSize: 13,
                },
                style: {
                    backgroundColor: colors.tertiary,
                },
            }}
            screenOptions={({ route }) => ({
                tabBarIcon: ({ focused, color }) => {
                    let iconName;

                    if (route.name === "Listings") {
                        iconName = focused ? "clipboard" : "clipboard-outline";
                    }
                    if (route.name === "ListingsMap") {
                        iconName = focused ? "map-marker" : "map-marker-outline";
                    }
                    if (route.name === "MyDashboard") {
                        iconName = "monitor-dashboard";
                    }
                    if (route.name === "Notifications") {
                        iconName = focused ? "bell" : "bell-outline";
                    }

                    return <MaterialCommunityIcons name={iconName} color={color} size={24} />;
                },
            })}
        >
            <Tab.Screen name="Listings" component={ListingsScreen} />
            <Tab.Screen name="ListingsMap" component={ListingsMapScreen} options={{ unmountOnBlur: true, title: "Map" }} />
            <Tab.Screen
                name="MyDashboard"
                component={MyDashboardScreen}
                options={{ unmountOnBlur: true, title: "Dashboard", tabBarBadge: news?.newNewsCount > 0 ? news.newNewsCount : null }}
            />
            <Tab.Screen
                name="Notifications"
                component={NotificationsScreen}
                options={{ unmountOnBlur: true, title: "Notifications", tabBarBadge: notifications?.length > 0 ? notifications.length : null }}
            />
        </Tab.Navigator>
    );
};

export default AppTabNavigator;
