import client from "./client";

const getStock = (userID: string) => client.get("/stock/" + userID);
const addStock = (formData) => client.post("/stock/addStock", formData);
const addStockWeb = (image, userID: string, registration: string) => client.post("/stock/addStock", { image, userID, registration });
const removeStock = (stockID: string) => client.post("/stock/removeStock", { stockID });
const getMatchingPartRequestsEngine = (userID: string) => client.get("/stock/matchingPartRequestsEngine/" + userID);
const getMatchingPartRequestsMakeModelYear = (userID: string) => client.get("/stock/matchingPartRequestsMakeModelYear/" + userID);
const getMatchingStock = (registration: string) => client.get("/stock/matchingStock/" + registration);
const getMatchingStockForDashboard = (leadID: string) => client.get("/stock/matchingStockForDashboard/" + leadID);
const addContactedAgent = (stockID: string, agentID: string) => client.post("/stock/addContactedAgent", { stockID, agentID });
const getAvailableStock = () => client.get("/stock/getAvailableStock");

export default {
    addStock,
    addStockWeb,
    getStock,
    removeStock,
    getMatchingPartRequestsEngine,
    getMatchingPartRequestsMakeModelYear,
    getMatchingStock,
    getMatchingStockForDashboard,
    addContactedAgent,
    getAvailableStock,
};
