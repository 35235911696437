import React, { useState, createContext } from "react";
import { LeadContextType, ILead } from "../interfaces/lead";

const LeadsContext = createContext<LeadContextType | null>(null);

const LeadsProvider = ({ children }: { children: React.ReactNode }) => {
    const [leads, setLeads] = useState<ILead[]>([]);
    const [leadTypeFilters, setLeadTypeFilters] = useState<string[]>([]);
    const [leadDistanceFilter, setLeadDistanceFilter] = useState<number>(30);

    return (
        <LeadsContext.Provider value={{ leads, setLeads, leadTypeFilters, setLeadTypeFilters, leadDistanceFilter, setLeadDistanceFilter }}>{children}</LeadsContext.Provider>
    );
};

export { LeadsContext, LeadsProvider };
