// React Packages
import React, { useState } from "react";
import { View, Platform, ScrollView, KeyboardAvoidingView } from "react-native";

// Custom Components
import Container from "../components/Container";

// Hooks
import useApi from "../hooks/useApi";

// Styles and config
import colors from "../config/colors";
import Screen from "../components/Screen";
import useToast from "../hooks/useToast";
import SubContainer from "../components/SubContainer";
import leadsApi from "../api/leads";
import IconTouchable from "../components/IconTouchable";
import AnimatedFullScreenCheck from "../components/AnimatedFullScreenCheck";
import AppTextInput from "../components/AppTextInput";

export default function AddToHistoryScreen({ navigation, route }) {
    const addToCompletedHistoryApi = useApi(leadsApi.addToCompletedHistory);

    const { showToast } = useToast();

    const [earnings, setEarnings] = useState("");
    const [rating, setRating] = useState(3);

    const [showAnimation, setShowAnimation] = useState(false);

    const completeWork = async () => {
        if (!earnings.length) {
            showToast("Please enter a price", "error");
            return;
        }

        if (parseInt(earnings) < 1) {
            showToast("Please enter a valid price", "error");
            return;
        }

        //check if not a number
        if (isNaN(parseInt(earnings))) {
            showToast("Please enter a valid price", "error");
            return;
        }

        const response = await addToCompletedHistoryApi.request(route.params.leadID, earnings, rating);
        if (!response.ok) {
            showToast("Something went wrong, please try again later", "error");
            return;
        }

        setShowAnimation(true);
    };

    return (
        <Screen>
            {showAnimation && (
                <AnimatedFullScreenCheck title={"Work Completed"} line1={"£" + earnings + " earned"} line2={rating + " / 5"} onPress={() => navigation.pop(2)} />
            )}
            <KeyboardAvoidingView behavior={Platform.OS === "ios" ? "padding" : "height"} style={{ flex: 1, backgroundColor: colors.tertiary }} keyboardVerticalOffset={104}>
                <ScrollView>
                    <Container>
                        <SubContainer icon={"check"} iconColor={colors.green} iconBackgroundColor="transparent" title={"Track earnings and completed work"} />
                        <SubContainer icon={"alert"} iconColor={colors.red} iconBackgroundColor="transparent" title={"This will not remove the listing for other agents"} />
                    </Container>
                    <Container title={"Enter the amount you charged the customer"} animated={false}>
                        <AppTextInput
                            icon={"currency-gbp"}
                            keyboardType={"numeric"}
                            placeholder={"What was the final price?"}
                            onChangeText={(value: string) => setEarnings(value)}
                        />
                    </Container>
                    <Container title={"Customer Rating"} animated={false}>
                        <View style={{ flexDirection: "row", justifyContent: "space-evenly" }}>
                            <IconTouchable name={"star"} iconColor={colors.green} size={40} onPress={() => setRating(1)} />
                            <IconTouchable name={"star"} iconColor={rating > 1 ? colors.green : colors.white} size={40} onPress={() => setRating(2)} />
                            <IconTouchable name={"star"} iconColor={rating > 2 ? colors.green : colors.white} size={40} onPress={() => setRating(3)} />
                            <IconTouchable name={"star"} iconColor={rating > 3 ? colors.green : colors.white} size={40} onPress={() => setRating(4)} />
                            <IconTouchable name={"star"} iconColor={rating > 4 ? colors.green : colors.white} size={40} onPress={() => setRating(5)} />
                        </View>
                    </Container>
                    <Container title={"Confirm Completed"} icon={"check"} onPress={completeWork}></Container>
                </ScrollView>
            </KeyboardAvoidingView>
        </Screen>
    );
}
