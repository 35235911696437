import React, { useEffect, useState } from "react";
import { View, ScrollView } from "react-native";

import Container from "../components/Container";
import { format, formatDistanceStrict, isValid, subDays } from "date-fns";
import { LineChart } from "react-native-chart-kit";
import colors from "../config/colors";
import reportingApi from "../api/reporting";
import useApi from "../hooks/useApi";
import usePopUp from "../hooks/usePopUp";
import SubContainer from "../components/SubContainer";
import Screen from "../components/Screen";

export default function MyStatsScreen({ route }) {
    const { setMessage } = usePopUp();

    const [totalCallsFromCustomer, setTotalCallsFromCustomer] = useState("0");
    const [totalCallsFromAgent, setTotalCallsFromAgent] = useState("0");
    const [totalQuotes, setTotalQuotes] = useState("0");
    const [totalIncome, setTotalIncome] = useState("0");

    const [callsFromCustomerInLast7Days, setCallsFromCustomerInLast7Days] = useState([]);
    const [callsFromAgentInLast7Days, setCallsFromAgentInLast7Days] = useState([]);
    const [quotesInLast7Days, setQuotesInLast7Days] = useState([]);
    const [incomeInLast7Days, setIncomeInLast7Days] = useState([]);

    const [lastQuoteDate, setLastQuoteDate] = useState("No Quotes");
    const [lastCallFromCustomerDate, setLastCallFromCustomerDate] = useState("No Calls");
    const [lastCallFromAgentDate, setLastCallFromAgentDate] = useState("No Calls");
    const [lastNotificationDate, setLastNotificationDate] = useState("No Notifications");

    const getAgentStatisticsApi = useApi(reportingApi.getAgentStatistics);

    useEffect(() => {
        if (!route.params) return;

        getStats(route.params);
    }, []);

    const getStats = async (userID) => {
        const response = await getAgentStatisticsApi.request(userID);
        if (!response.ok) {
            setMessage("Error getting stats");
            return;
        }

        setTotalCallsFromCustomer(response.data.totalCallsFromCustomer.toString());
        setTotalCallsFromAgent(response.data.totalCallsFromAgent.toString());
        setTotalQuotes(response.data.totalQuotes.toString());
        setTotalIncome(response.data.totalIncome[0]?.totalEarnings.toString());

        setCallsFromCustomerInLast7Days(response.data.totalCallsFromCustomerLast7Days.map((dateString) => new Date(dateString)));
        setCallsFromAgentInLast7Days(response.data.totalCallsFromAgentLast7Days.map((dateString) => new Date(dateString)));
        setQuotesInLast7Days(response.data.totalQuotesLast7Days.map((dateString) => new Date(dateString)));
        setIncomeInLast7Days(response.data.totalIncomeLast7Days[0]?.totalEarnings.toString());

        if (isValid(new Date(response.data.lastCallFromCustomerDate))) {
            setLastCallFromCustomerDate(formatDistanceStrict(new Date(response.data.lastCallFromCustomerDate), new Date(), { addSuffix: true }));
        }

        if (isValid(new Date(response.data.lastNotificationDate))) {
            setLastNotificationDate(formatDistanceStrict(new Date(response.data.lastNotificationDate), new Date(), { addSuffix: true }));
        }

        if (isValid(new Date(response.data.lastCallFromAgentDate))) {
            setLastCallFromAgentDate(formatDistanceStrict(new Date(response.data.lastCallFromAgentDate), new Date(), { addSuffix: true }));
        }

        if (isValid(new Date(response.data.lastQuoteDate))) {
            setLastQuoteDate(formatDistanceStrict(new Date(response.data.lastQuoteDate), new Date(), { addSuffix: true }));
        }
    };
    return (
        <Screen>
            <ScrollView>
                <Container title={"Calls and Quotes"} subTitle={"Last 7 Days"} animated={false}>
                    <LineChart
                        data={{
                            legend: ["Calls In", "Calls Out", "Quotes"],
                            labels: [
                                format(subDays(new Date(), 6), "dd-MMM"),
                                format(subDays(new Date(), 5), "dd"),
                                format(subDays(new Date(), 4), "dd"),
                                format(subDays(new Date(), 3), "dd"),
                                format(subDays(new Date(), 2), "dd"),
                                format(subDays(new Date(), 1), "dd"),
                                format(new Date(), "dd-MMM"),
                            ],
                            datasets: [
                                {
                                    data: callsFromCustomerInLast7Days
                                        ? [
                                              callsFromCustomerInLast7Days.filter((call) => call > subDays(new Date(), 6) && call < subDays(new Date(), 5)).length,
                                              callsFromCustomerInLast7Days.filter((call) => call > subDays(new Date(), 5) && call < subDays(new Date(), 4)).length,
                                              callsFromCustomerInLast7Days.filter((call) => call > subDays(new Date(), 4) && call < subDays(new Date(), 3)).length,
                                              callsFromCustomerInLast7Days.filter((call) => call > subDays(new Date(), 3) && call < subDays(new Date(), 2)).length,
                                              callsFromCustomerInLast7Days.filter((call) => call > subDays(new Date(), 2) && call < subDays(new Date(), 1)).length,
                                              callsFromCustomerInLast7Days.filter((call) => call > subDays(new Date(), 1) && call < subDays(new Date(), 0)).length,
                                              callsFromCustomerInLast7Days.filter((call) => call > subDays(new Date(), 0)).length,
                                          ]
                                        : [0, 1, 2, 3, 4, 5, 6],
                                    color: (opacity = 1) => `rgba(0, 255, 0, ${opacity})`,
                                    strokeWidth: 5,
                                },
                                {
                                    data: callsFromAgentInLast7Days
                                        ? [
                                              callsFromAgentInLast7Days.filter((call) => call > subDays(new Date(), 6) && call < subDays(new Date(), 5)).length,
                                              callsFromAgentInLast7Days.filter((call) => call > subDays(new Date(), 5) && call < subDays(new Date(), 4)).length,
                                              callsFromAgentInLast7Days.filter((call) => call > subDays(new Date(), 4) && call < subDays(new Date(), 3)).length,
                                              callsFromAgentInLast7Days.filter((call) => call > subDays(new Date(), 3) && call < subDays(new Date(), 2)).length,
                                              callsFromAgentInLast7Days.filter((call) => call > subDays(new Date(), 2) && call < subDays(new Date(), 1)).length,
                                              callsFromAgentInLast7Days.filter((call) => call > subDays(new Date(), 1) && call < subDays(new Date(), 0)).length,
                                              callsFromAgentInLast7Days.filter((call) => call > subDays(new Date(), 0)).length,
                                          ]
                                        : [0, 1, 2, 3, 4, 5, 6],
                                    color: (opacity = 1) => `rgba(0, 0, 255, ${opacity})`,
                                    strokeWidth: 5,
                                },
                                {
                                    data: quotesInLast7Days
                                        ? [
                                              quotesInLast7Days.filter((call) => call > subDays(new Date(), 6) && call < subDays(new Date(), 5)).length,
                                              quotesInLast7Days.filter((call) => call > subDays(new Date(), 5) && call < subDays(new Date(), 4)).length,
                                              quotesInLast7Days.filter((call) => call > subDays(new Date(), 4) && call < subDays(new Date(), 3)).length,
                                              quotesInLast7Days.filter((call) => call > subDays(new Date(), 3) && call < subDays(new Date(), 2)).length,
                                              quotesInLast7Days.filter((call) => call > subDays(new Date(), 2) && call < subDays(new Date(), 1)).length,
                                              quotesInLast7Days.filter((call) => call > subDays(new Date(), 1) && call < subDays(new Date(), 0)).length,
                                              quotesInLast7Days.filter((call) => call > subDays(new Date(), 0)).length,
                                          ]
                                        : [0, 1, 2, 3, 4, 5, 6],
                                    color: (opacity = 1) => `rgba(255, 0, 0, ${opacity})`,
                                    strokeWidth: 5,
                                },
                            ],
                        }}
                        width={350}
                        height={200}
                        fromZero={true}
                        withShadow={true}
                        chartConfig={{
                            backgroundGradientFrom: colors.tertiary,
                            backgroundGradientFromOpacity: 0,
                            backgroundGradientTo: colors.secondary,
                            backgroundGradientToOpacity: 0,
                            color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
                            decimalPlaces: 0,
                            useShadowColorFromDataset: true,
                        }}
                        segments={8}
                        bezier
                        style={{ marginLeft: -16 }}
                    />
                    <View style={{ flexDirection: "row" }}>
                        <View style={{ flexDirection: "column", flex: 1 }}>
                            <SubContainer title={"_"} />
                            <SubContainer title={"Calls In"} />
                            <SubContainer title={"Calls O"} />
                            <SubContainer title={"Quotes"} />
                            <SubContainer title={"Income"} />
                        </View>
                        <View style={{ flexDirection: "column", flex: 1 }}>
                            <SubContainer title={"Total"} />
                            <SubContainer title={totalCallsFromCustomer} />
                            <SubContainer title={totalCallsFromAgent} />
                            <SubContainer title={totalQuotes} />
                            <SubContainer title={"£" + totalIncome} />
                        </View>
                        <View style={{ flexDirection: "column", flex: 1 }}>
                            <SubContainer title={"7 Days"} />
                            <SubContainer title={callsFromCustomerInLast7Days ? callsFromCustomerInLast7Days.length.toString() : "0"} />
                            <SubContainer title={callsFromAgentInLast7Days ? callsFromAgentInLast7Days.length.toString() : "0"} />
                            <SubContainer title={quotesInLast7Days ? quotesInLast7Days.length.toString() : "0"} />
                            <SubContainer title={incomeInLast7Days ? "£" + incomeInLast7Days.toString() : "0"} />
                        </View>
                        <View style={{ flexDirection: "column", flex: 1 }}></View>
                    </View>
                    <SubContainer title={"Last Call From Customer"} subTitle={lastCallFromCustomerDate} />
                    <SubContainer title={"Last Call From Agent"} subTitle={lastCallFromAgentDate} />
                    <SubContainer title={"Last Quote From Agent"} subTitle={lastQuoteDate} />
                    <SubContainer title={"Last Notification"} subTitle={lastNotificationDate} />
                </Container>
            </ScrollView>
        </Screen>
    );
}
