import React, { useEffect, useState } from "react";
import Screen from "../components/Screen";
import * as Clipboard from "expo-clipboard";

import Container from "../components/Container";
import useApi from "../hooks/useApi";
import usersApi from "../api/users";
import { format, isValid } from "date-fns";
import useToast from "../hooks/useToast";
import SubContainer from "../components/SubContainer";
import { FlatList, View } from "react-native";
import AnimatedIcon from "../components/AnimatedIcon";

type SubscriptionDetails = {
    status: string;
    status_update_time: string;
    id: string;
    plan_id: string;
    start_time: string;
    quantity: string;
    shipping_amount: {
        currency_code: string;
        value: string;
    };
    subscriber: {
        email_address: string;
        payer_id: string;
        name: {
            given_name: string;
            surname: string;
        };
        shipping_address: {
            address: {
                address_line_1: string;
                admin_area_2: string;
                postal_code: string;
                country_code: string;
            };
        };
    };
    billing_info: {
        outstanding_balance: {
            currency_code: string;
            value: string;
        };
        cycle_executions: {
            tenure_type: string;
            sequence: number;
            cycles_completed: number;
            cycles_remaining: number;
            current_pricing_scheme_version: number;
            total_cycles: number;
        }[];
        last_payment: {
            amount: {
                currency_code: string;
                value: string;
            };
            time: string;
        };
        next_billing_time: string;
        failed_payments_count: number;
    };
    create_time: string;
    update_time: string;
    plan_overridden: boolean;
    links: {
        href: string;
        rel: string;
        method: string;
    }[];
};

function TransactionHistoryScreen({ navigation, route }) {
    const { showToast } = useToast();

    const [transactions, setTransactions] = useState([]);
    const [subscriptionDetails, setSubscriptionDetails] = useState<SubscriptionDetails | null>(null);

    const getPaypalTransactionHistoryApi = useApi(usersApi.getPaypalTransactionHistory);

    useEffect(() => {
        getTransactionHistory();
    }, []);

    const getTransactionHistory = async () => {
        const response = await getPaypalTransactionHistoryApi.request(route.params.subscriptionID);
        if (!response.ok) {
            showToast("Unable to get transaction history", "error");
            return;
        }

        setTransactions(response.data.transactionHistory.transactions);
        setSubscriptionDetails(response.data.subscriptionDetails);
    };

    const copyToClipboard = async () => {
        const billingDate = subscriptionDetails.billing_info.next_billing_time?.split("T")[0] || "N/A";

        let formattedText = `Subscription Details:\n- Status: ${subscriptionDetails.status}\n- Last Payment: ${subscriptionDetails.billing_info.last_payment.amount.value}\n- Next Billing Date: ${billingDate}\n\nTransaction History:\n`;

        transactions.forEach((transaction) => {
            if (isValid(new Date(transaction.time))) {
                transaction.time = format(new Date(transaction.time), "dd-MM-yy HH:mm");
            }

            formattedText += `Status: ${transaction.status}\n- Amount: ${transaction.amount_with_breakdown.gross_amount.value} \n- Time: ${transaction.time}\n\n`;
        });

        try {
            await Clipboard.setStringAsync(formattedText);
            showToast("Copied to clipboard", "success");
            navigation.goBack();
        } catch (error) {
            showToast("Unable to copy to clipboard", "error");
        }
    };

    return (
        <Screen loading={getPaypalTransactionHistoryApi.loading}>
            <FlatList
                data={transactions}
                keyExtractor={(transaction) => transaction.id}
                ListHeaderComponent={
                    subscriptionDetails && (
                        <>
                            <Container title={"Paypal Subscriber Details"} utility={<AnimatedIcon name={"content-copy"} type={"confirm"} onPress={copyToClipboard} />}>
                                <SubContainer
                                    title={subscriptionDetails.subscriber.name.given_name + " " + subscriptionDetails.subscriber.name.surname}
                                    subTitle={subscriptionDetails.subscriber.email_address}
                                />
                                <View style={{ flexDirection: "row" }}>
                                    <View style={{ flex: 1 }}>
                                        <SubContainer
                                            title={subscriptionDetails.subscriber.shipping_address.address.address_line_1}
                                            subTitle={
                                                subscriptionDetails.subscriber.shipping_address.address.admin_area_2 +
                                                " " +
                                                subscriptionDetails.subscriber.shipping_address.address.postal_code
                                            }
                                        />
                                    </View>
                                    <View style={{ flex: 1 }}>
                                        <SubContainer title={"App Postcode"} subTitle={route.params.user.location.postcode} />
                                    </View>
                                </View>
                            </Container>
                            <Container title={"Paypal Subscription Details"}>
                                <View style={{ flexDirection: "row" }}>
                                    <View style={{ flex: 1 }}>
                                        <SubContainer title={"Balance"} subTitle={`£${subscriptionDetails.billing_info.outstanding_balance.value}`} />
                                    </View>
                                    <View style={{ flex: 1 }}>
                                        <SubContainer title={"Failed Payments"} subTitle={`${subscriptionDetails.billing_info.failed_payments_count}`} />
                                    </View>
                                </View>
                                <View style={{ flexDirection: "row" }}>
                                    <View style={{ flex: 1 }}>
                                        <SubContainer title={"Status"} subTitle={`${subscriptionDetails.status}`} />
                                    </View>
                                    <View style={{ flex: 1 }}>
                                        <SubContainer title={"Next Payment"} subTitle={`${subscriptionDetails.billing_info.next_billing_time?.split("T")[0] || "N/A"}`} />
                                    </View>
                                </View>
                            </Container>
                        </>
                    )
                }
                renderItem={({ item: transaction }) => (
                    <Container
                        title={"£" + transaction.amount_with_breakdown.gross_amount.value + " - " + transaction.status}
                        subTitle={format(new Date(transaction.time), "dd-MM-yyyy")}
                        icon={"cash"}
                    />
                )}
                ListEmptyComponent={<Container title={"No transactions found for"} subTitle={route.params.subscriptionID} icon={"cash"} />}
            />
        </Screen>
    );
}

export default TransactionHistoryScreen;
