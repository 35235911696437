// React Packages
import React, { useState } from "react";
import { View, Platform, ScrollView, KeyboardAvoidingView } from "react-native";

// Custom Components
import Container from "../components/Container";

// APIs

// Hooks
import useUser from "../hooks/useUser";
import useApi from "../hooks/useApi";

// Styles and config
import colors from "../config/colors";
import Screen from "../components/Screen";
import useToast from "../hooks/useToast";
import SubContainer from "../components/SubContainer";
import leadsApi from "../api/leads";
import AppTextInput from "../components/AppTextInput";

export default function NewPostScreen({ navigation }) {
    const newVehiclePostApi = useApi(leadsApi.newVehiclePost);

    const { showToast } = useToast();

    const [registration, setRegistration] = useState("");
    const [price, setPrice] = useState("");

    const handleSubmitVehicle = async () => {
        //validate inputs
        if (registration.length < 1) {
            showToast("Please enter a registration", "error");
            return;
        }

        if (price.length < 1) {
            showToast("Please enter a price", "error");
            return;
        }

        if (parseInt(price) < 1) {
            showToast("Please enter a valid price", "error");
            return;
        }

        const response = await newVehiclePostApi.request(registration, price);
        if (!response.ok) {
            showToast("Something went wrong, please try again later", "error");
            return;
        }

        setRegistration("");
        setPrice("");

        navigation.navigate("ListingDetails", { leadID: response.data });
    };

    return (
        <Screen loading={newVehiclePostApi.loading}>
            <KeyboardAvoidingView behavior={Platform.OS === "ios" ? "padding" : "height"} style={{ flex: 1, backgroundColor: colors.tertiary }} keyboardVerticalOffset={104}>
                <ScrollView>
                    <Container icon={"car"} title={"Complete the form below to Advertise your vehicle on Make Me Busy"}>
                        <SubContainer icon={"phone"} title={"we'll use your in-app contact information and location to complete the listing."} />
                    </Container>
                    <Container title={"Registration"} icon={"numeric-1-box"} animated={false}>
                        <View style={{ paddingTop: 8 }}>
                            <AppTextInput placeholder={"YC18HMV"} onChangeText={(value) => setRegistration(value)} />
                        </View>
                    </Container>
                    <Container title={"Price"} icon={"numeric-2-box"} animated={false}>
                        <View style={{ paddingTop: 8 }}>
                            <AppTextInput keyboardType={"numeric"} placeholder={"300"} onChangeText={(value) => setPrice(value)} />
                        </View>
                    </Container>
                    <Container title={"Confirm Details"} icon={"check"} onPress={handleSubmitVehicle}></Container>
                </ScrollView>
            </KeyboardAvoidingView>
        </Screen>
    );
}
