import { useState } from "react";

const useApi = <T>(apiFunc) => {
    const [data, setData] = useState<T | undefined>();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const request = async (...args) => {
        setLoading(true);

        const response = await apiFunc(...args);
        setError(!response.ok);
        setData(response.data);
        setLoading(false);
        return response;
    };

    return { data, error, loading, request };
};

export default useApi;
