import React from "react";
import { useState } from "react";
import { View, StyleSheet, Pressable, Animated } from "react-native";

import AppText from "./AppText";
import Icon from "./Icon";
import { useRef } from "react";
import defaultStyles from "../config/defaultStyles";
import colors from "../config/colors";

type Props = {
    onPress?: any;
    children?: any;
    title?: string;
    titleColor?: string;
    subTitle?: string;
    subTitleColor?: string;
    icon?: string;
    backgroundColor?: string;
    iconColor?: string;
    toolTip?: string;
    badge?: string;
    utility?: any;
    iconBackgroundColor?: string;
    enabled?: boolean;
    animated?: boolean;
    onLongPress?: any;
};

export default function SubContainer({
    onPress,
    children,
    title,
    titleColor = colors.black,
    subTitle,
    subTitleColor = colors.medium,
    icon,
    iconColor,
    badge,
    utility,
    iconBackgroundColor,
    enabled = true,
    animated = true,
    onLongPress,
    backgroundColor = colors.light,
}: Props) {
    const [hovered, setHovered] = useState(false);

    const springValue = useRef(new Animated.Value(1)).current;

    const handleHoverIn = () => {
        if (!enabled) return;
        if (!animated) return;

        setHovered(true);

        Animated.spring(springValue, {
            toValue: 1.025,
            bounciness: 2,
            useNativeDriver: true,
        }).start();
    };

    const handleHoverOut = () => {
        if (!enabled) return;
        if (!animated) return;

        setHovered(false);

        Animated.spring(springValue, {
            toValue: 1,
            bounciness: 2,
            useNativeDriver: true,
        }).start();
    };

    return (
        <Pressable
            onPress={enabled && onPress}
            onPressIn={() => handleHoverIn()}
            onPressOut={() => handleHoverOut()}
            onHoverIn={() => handleHoverIn()}
            onHoverOut={() => handleHoverOut()}
            onLongPress={onLongPress}
            style={{ maxWidth: 600, width: "100%" }}
        >
            <View
                style={{
                    backgroundColor,
                    borderRadius: defaultStyles.borderRadius,
                    padding: 8,
                    flexDirection: "column",
                    margin: 4,
                }}
            >
                <View style={styles.header}>
                    {icon && (
                        <View style={{ paddingRight: 8 }}>
                            {badge ? (
                                <View
                                    style={{
                                        position: "absolute",
                                        width: 16,
                                        height: 16,
                                        backgroundColor: colors.red,
                                        borderRadius: defaultStyles.borderRadius,
                                        zIndex: 1,
                                    }}
                                >
                                    <AppText style={{ fontSize: 12, fontWeight: "bold", color: colors.white, textAlign: "center", top: 0 }}>{badge ? badge : ""}</AppText>
                                </View>
                            ) : null}
                            <Icon name={icon} iconColor={iconColor} backgroundColor={iconBackgroundColor} />
                        </View>
                    )}
                    <View
                        style={{
                            flexDirection: "column",
                            flex: 1,
                        }}
                    >
                        {title && <AppText style={{ fontWeight: "bold", color: titleColor }}>{title}</AppText>}
                        {subTitle && <AppText style={{ fontWeight: "600", color: subTitleColor }}>{subTitle}</AppText>}
                    </View>
                    {utility && utility}
                </View>
                {children}
            </View>
        </Pressable>
    );
}

const styles = StyleSheet.create({
    header: {
        flexDirection: "row",
        alignItems: "center",
    },
});
