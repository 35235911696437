import React from "react";
import { View, StyleSheet, Pressable, Platform } from "react-native";
import * as Linking from "expo-linking";
import colors from "../config/colors";
import AppText from "./AppText";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { nativeBuildVersion } from "expo-application";
import config from "../config/config";

import * as Device from "expo-device";

export default function VersionIndicator() {
    if (nativeBuildVersion == config.latestBuildNo || Platform.OS === "web") {
        return null;
    }

    //Device expo
    if (!Device.isDevice) {
        return null;
    }

    return (
        <Pressable
            onPress={() => {
                Linking.openURL(config.appStoreURL);
            }}
        >
            <View style={styles.component}>
                <MaterialCommunityIcons name={"download"} size={32} color={colors.red} style={{ paddingRight: 8 }} />
                <View>
                    <AppText style={{ fontSize: 20, fontWeight: "bold" }}>New Update Available</AppText>
                    <AppText style={{ fontSize: 20, fontWeight: "bold" }}>Tap here to update</AppText>
                </View>
            </View>
        </Pressable>
    );
}

const styles = StyleSheet.create({
    component: {
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: colors.honeyComb,
        flexDirection: "row",
    },
});
