import client from "./client";

const getLeads = () => client.get("/leads");

const getCustomerLeads = (userID: string) => client.get("/leads/getCustomerLeads/" + userID);

const newLead = (registration: string, postcode: string, phone: string, workRequired: string, type: string, deliveryPostcode: string, site: string, passengers: number) =>
    client.post("/leads", {
        vehicle: { registration },
        location: { postcode, delivery: { postcode: deliveryPostcode } },
        contact: { phone },
        workRequired,
        type,
        site,
        passengers,
    });
const getPreviousJobs = (type: string) => client.get("/leads/getPreviousJobs/" + type);

const getPendingAuctions = () => client.get("/leads/getPendingAuctions");
const getBookedPremiumSalvage = () => client.get("/leads/bookedPremiumSalvage");
const getAddress = (address: string) => client.get("/leads/getAddress/" + address);
const addContactedAgent = (agentID: string, leadID: string) => client.post("/leads/addContactedAgent", { agentID, leadID });
const getParts = () => client.get("/leads/getParts");
const find = (registration: string) => client.get("/leads/find/" + registration);
const getLead = (leadID: string) => client.post("/leads/getLead", { leadID });
const getPremiumSalvageLeads = () => client.get("/leads/getPremiumSalvageLeads");
const notInterested = (leadID: string) => client.post("/leads/notInterested", { leadID });

const browseLead = () => client.post("/leads/browseLead");

const newVehiclePost = (registration: string, price) => client.post("/leads/newVehiclePost", { registration, price });

const getPartRequests = () => client.get("/leads/partRequests");
const getPosts = () => client.get("/leads/getPosts");

const getLeadCustomer = (leadID: string) => client.get("/leads/getLeadCustomer/" + leadID);
const cancelLead = (leadID: string, customerFeedback: string, customerRating: string) => client.post("/leads/cancelLead", { leadID, customerRating, customerFeedback });
const completeLead = (leadID: string, customerFeedback: string, customerRating: string) => client.post("/leads/completeLead", { leadID, customerRating, customerFeedback });
const markAsSold = (leadID: string) => client.post("/leads/markAsSold", { leadID });
const acceptLead = (leadID: string) => client.post("/leads/acceptLead", { leadID });
const setPrice = (leadID: string, price: string) => client.post("/leads/setPrice", { leadID, price });
const addReport = (leadID: string, reason: string) => client.post("/leads/addReport", { leadID, reason });
const addNote = (leadID: string, note: string) => client.post("/leads/addNote", { leadID, note });
const uploadImage = (leadID: string, image) => client.post("/leads/uploadImage", { leadID, image });
const uploadImageUri = (FormData) => client.post("/leads/uploadImage", FormData);
const deleteImage = (itemID: string, image) => client.post("/leads/deleteImage", { itemID, image });
const setLocation = (leadID: string, location) => client.post("/leads/setLocation", { leadID, location });
const sendReminder = (leadID: string) => client.post("/leads/sendReminder", { leadID });
const restartAuction = (leadID: string) => client.post("/leads/restartAuction", { leadID });
const boostLead = (leadID: string) => client.post("/leads/boostLead", { leadID });
const deleteLead = (leadID: string) => client.post("/leads/deleteLead", { leadID });

const getContactInfo = (leadID: string) => client.post("/leads/getContactInfo/", { leadID });
const updateStatusBooked = (leadID: string, agentID: string) => client.post("/leads/updateStatusBooked", { leadID, agentID });
const updateStatusQuoted = (leadID: string) => client.post("/leads/updateStatusQuoted", { leadID });
const updateStatusCompleted = (leadID: string, agentRating: string, agentFeedback: string) =>
    client.post("/leads/updateStatusCompleted", { leadID, agentRating, agentFeedback });
const getRecoveryQuote = (pickup: string, dropoff: string, registration: string) =>
    client.post("/leads/getRecoveryQuote", {
        pickup,
        dropoff,
        registration,
    });
const otherServicesClicked = (leadType: string, site: string) => client.post("/leads/otherServicesClicked", { leadType, site });
const updateBid = (leadID: string, amount: string) => client.post("/leads/updateBid", { leadID, amount });
const sendQuote = (leadID: string, amount: string) => client.post("/leads/sendQuote", { leadID, amount });
const removeBid = (leadID: string, agentID: string) => client.post("/leads/removeBid", { leadID, agentID });
const acceptOffer = (leadID: string, agentID: string) => client.post("/leads/acceptOffer", { leadID, agentID });
const sendContactInfo = (leadID: string) => client.post("/leads/sendContactInfo", { leadID });
const addToCompletedHistory = (leadID: string, earnings: string, rating: string) => client.post("/leads/addToCompletedHistory", { leadID, earnings, rating });
const getLeadsByType = (type: string) => client.post("/leads/getLeadsByType/", { type });
const changeType = (leadID: string, type: string) => client.post("/leads/changeType", { leadID, type });

export default {
    boostLead,
    getLeads,
    updateStatusBooked,
    getPreviousJobs,
    getContactInfo,
    addToCompletedHistory,
    uploadImage,
    uploadImageUri,
    deleteImage,
    find,
    getLead,
    getLeadCustomer,
    getLeadsByType,
    getParts,
    setPrice,
    getAddress,
    getRecoveryQuote,
    addReport,
    newLead,
    cancelLead,
    acceptLead,
    getCustomerLeads,
    setLocation,
    updateStatusCompleted,
    markAsSold,
    addContactedAgent,
    otherServicesClicked,
    updateBid,
    removeBid,
    acceptOffer,
    getPendingAuctions,
    getBookedPremiumSalvage,
    sendContactInfo,
    sendReminder,
    restartAuction,
    getPremiumSalvageLeads,
    addNote,
    completeLead,
    sendQuote,
    deleteLead,
    getPartRequests,
    notInterested,
    browseLead,
    newVehiclePost,
    getPosts,
    updateStatusQuoted,
    changeType,
};
