import * as Updates from "expo-updates";
import { Platform } from "react-native";
import { useNetwork } from "./useNetwork";
import { isDevice } from "expo-device";

const useUpdates = () => {
    const { isInternetReachable } = useNetwork();

    const checkForUpdates = async () => {
        //No updates in web or emulators
        if (Platform.OS == "web") return;
        if (!isDevice) return;
        if (!isInternetReachable) return;

        try {
            const update = await Updates.checkForUpdateAsync();

            if (update.isAvailable) {
                const { isNew } = await Updates.fetchUpdateAsync();
                if (isNew) await Updates.reloadAsync();
            }
        } catch (error) {
            console.log(error);
        }
    };

    return {
        checkForUpdates,
    };
};

export default useUpdates;
