import client from "./client";

const addNews = (audience: string, title: string, body: string, url: string) => client.post("/news/addNews", { audience, title, body, url });
const editNews = (newsID: string, updatedNewsTitle: string, updatedNewsBody: string) => client.post("/news/editNews", { newsID, updatedNewsTitle, updatedNewsBody });
const getNews = () => client.get("/news");
const setLastViewedNews = (userID: string) => client.post("/news/setLastViewedNews", { userID });
const setFeedback = (newsID: string, feedback: string) => client.post("/news/setFeedback", { newsID, feedback });

export default {
    addNews,
    editNews,
    getNews,
    setFeedback,
    setLastViewedNews,
};
