import React, { useEffect, useRef, useState } from "react";
import { createStackNavigator } from "@react-navigation/stack";

import LoginScreen from "../screens/LoginScreen";
import CustomerTabNavigator from "./CustomerTabNavigator";
import CustomerLoginScreen from "../screens/Customer/CustomerLoginScreen";
import RegisterScreen from "../screens/RegisterScreen";
import BrowseStockScreen from "../screens/BrowseStockScreen";
import ViewImagesScreen from "../screens/ViewImagesScreen";
import colors from "../config/colors";
import CustomerChatScreen from "../screens/Customer/CustomerChatScreen";
import { AppState, AppStateStatus } from "react-native";
import useUpdates from "../hooks/useUpdates";

const Stack = createStackNavigator();

const AuthNavigator = () => {
    const appState = useRef(AppState.currentState);
    const [appStateVisible, setAppStateVisible] = useState(appState.current);
    const { checkForUpdates } = useUpdates();

    useEffect(() => {
        checkForUpdates();

        const appStateSubscription = AppState.addEventListener("change", _handleAppStateChange);
        return () => {
            if (appStateSubscription) {
                appStateSubscription.remove();
            }
        };
    }, []);

    const _handleAppStateChange = (nextAppState: AppStateStatus) => {
        if (appState.current == "background" && nextAppState === "active") {
            checkForUpdates();
        }

        appState.current = nextAppState;
        setAppStateVisible(appState.current);
    };

    return (
        <Stack.Navigator
            screenOptions={{
                headerShown: false,
                headerStyle: {
                    backgroundColor: colors.tertiary,
                },
                headerTitle: "",
                headerBackTitle: "Back",
                headerBackTitleVisible: true,
                headerBackTitleStyle: { color: colors.white },
                headerTintColor: colors.red,
                headerRightContainerStyle: {
                    paddingRight: 8,
                },
            }}
        >
            <Stack.Screen name="Login" component={LoginScreen} />
            <Stack.Screen name="Register" component={RegisterScreen} options={{ headerShown: true, headerBackTitle: "Back to Login" }} />
            <Stack.Screen name="CustomerLogin" component={CustomerLoginScreen} />
            <Stack.Screen name="CustomerTabNavigator" component={CustomerTabNavigator} />
            <Stack.Screen name="BrowseStock" component={BrowseStockScreen} />
            <Stack.Screen name="CustomerChat" component={CustomerChatScreen} options={{ headerShown: true }} />
            <Stack.Screen
                name="ViewImages"
                component={ViewImagesScreen}
                options={{
                    headerShown: true,
                    headerStyle: {
                        backgroundColor: colors.tertiary,
                    },
                    headerTitle: "",
                    headerBackTitle: "Back",
                    headerBackTitleVisible: true,
                    headerBackTitleStyle: { color: colors.white },
                    headerTintColor: colors.red,
                    headerRightContainerStyle: {
                        paddingRight: 8,
                    },
                }}
            />
        </Stack.Navigator>
    );
};

export default AuthNavigator;
