import React, { useMemo, useRef } from "react";
import { View, StyleSheet, Animated, Image, Pressable, Platform } from "react-native";
import AppText from "./AppText";
import colors from "../config/colors";
import Icon from "./Icon";
import { formatDistanceStrict } from "date-fns";
import defaultStyles from "../config/defaultStyles";
import { ILead } from "../interfaces/lead";
import IconTouchable from "./IconTouchable";
import { getDistance } from "../utility/distance";
import useLocation from "../hooks/useLocation";
import { useNavigation } from "@react-navigation/native";

export default function ListingCardStock({ lead, onPress, onUtilityPress }: { lead: ILead; onPress?: () => void; onUtilityPress?: () => void }) {
    const { location } = useLocation();

    const navigation = useNavigation();

    let distanceFromLead = useMemo(() => {
        if (Platform.OS === "web") return null;
        if (!location) return null;
        if (!lead.location) return null;

        return getDistance(lead.location.coords.latitude, lead.location.coords.longitude, location.coords.latitude, location.coords.longitude).toFixed(1);
    }, [location]);

    const springValue = useRef(new Animated.Value(1)).current;

    const spring = () => {
        if (onPress) {
            Animated.sequence([
                Animated.timing(springValue, {
                    toValue: 0.95,
                    duration: 50,
                    useNativeDriver: true,
                }),
                Animated.timing(springValue, {
                    toValue: 1,
                    duration: 50,
                    useNativeDriver: true,
                }),
            ]).start(onPress);
        }
    };

    return (
        <Pressable
            style={{
                paddingVertical: 16,
                maxWidth: 600,
                width: "100%",
            }}
            onPress={spring}
        >
            <Animated.View
                style={{
                    transform: [
                        {
                            scale: springValue,
                        },
                    ],
                }}
            >
                <View style={[styles.card]}>
                    <View style={[styles.typeContainer]}>
                        <AppText style={styles.text}>{lead.workRequired}</AppText>
                        {onUtilityPress && <IconTouchable name={"trash-can"} iconColor={colors.red} size={32} onPress={onUtilityPress} />}
                    </View>
                    {lead.images?.length > 0 ? (
                        <Pressable
                            style={{
                                alignItems: "center",
                                flexDirection: "row",
                                justifyContent: "center",
                                marginHorizontal: 10,
                            }}
                            onPress={() => {
                                if (lead.images?.length > 0) {
                                    navigation.navigate("ViewImages", { images: lead.images });
                                } else {
                                    spring();
                                }
                            }}
                        >
                            {lead.images.map((image, index) => (
                                <Image key={index} style={{ flex: 1, height: 110 }} source={{ uri: image }} resizeMode={"contain"} />
                            ))}
                        </Pressable>
                    ) : null}
                    <View>
                        <View style={{ justifyContent: "center", paddingBottom: 8 }}>
                            <AppText style={styles.title} numberOfLines={1}>
                                {lead.vehicle.year ? lead.vehicle.year : null} {lead.vehicle.make && lead.vehicle.make} {lead.vehicle.model && lead.vehicle.model}
                            </AppText>
                        </View>
                        <View style={styles.column}>
                            <View style={styles.columnLeft}>
                                <AppText style={styles.textRight}>Registration:</AppText>
                                {lead.location?.postcode ? <AppText style={styles.textRight}>Postcode:</AppText> : null}
                                {lead.vehicle.engine.code ? <AppText style={styles.textRight}>Engine Code:</AppText> : null}
                                {lead.vehicle.engineSize ? <AppText style={styles.textRight}>Engine Size:</AppText> : null}
                                {lead.vehicle.transmission ? <AppText style={styles.textRight}>Transmission:</AppText> : null}
                            </View>
                            <View style={styles.columnRight}>
                                <AppText style={styles.textLeft}>{lead.vehicle.registration}</AppText>
                                {lead.location?.postcode ? <AppText style={styles.textLeft}>{lead.location.postcode}</AppText> : null}
                                {lead.vehicle.engine.code ? <AppText style={styles.textLeft}>{lead.vehicle.engine.code}</AppText> : null}
                                {lead.vehicle.engineSize ? <AppText style={styles.textLeft}>{lead.vehicle.engineSize}</AppText> : null}
                                {lead.vehicle.transmission ? <AppText style={styles.textLeft}>{lead.vehicle.transmission.type}</AppText> : null}
                            </View>
                        </View>
                    </View>
                    {!lead.contact && <AppText style={styles.subTitle}>Got this car? Add to MY STOCK for more instant calls!</AppText>}
                    <View style={{ flexDirection: "row", justifyContent: "space-between", paddingTop: 4 }}>
                        <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <Icon name="map-marker" backgroundColor={"transparent"} iconColor={colors.black} />
                            <AppText style={{ fontSize: 15, fontWeight: "bold" }}>{distanceFromLead ? distanceFromLead : "?"} mi.</AppText>
                        </View>

                        <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <AppText style={{ fontSize: 15, fontWeight: "bold" }}>
                                {lead.created &&
                                    formatDistanceStrict(new Date(lead.created), new Date(), {
                                        addSuffix: true,
                                    })}
                            </AppText>
                            <Icon name="timer" backgroundColor={"transparent"} iconColor={colors.black} />
                        </View>
                    </View>
                </View>
            </Animated.View>
        </Pressable>
    );
}

const styles = StyleSheet.create({
    card: {
        backgroundColor: colors.white,
        borderRadius: defaultStyles.borderRadius,
        justifyContent: "center",
        marginHorizontal: 8,
        shadowColor: colors.black,
        shadowOffset: {
            width: 0,
            height: 4,
        },
        shadowOpacity: 0.3,
        shadowRadius: 4.65,
        elevation: 8,
    },
    typeContainer: {
        paddingVertical: 8,
        flexDirection: "row",
        justifyContent: "space-between",
        paddingHorizontal: 16,
        alignItems: "center",
        borderTopLeftRadius: defaultStyles.borderRadius,
        borderTopRightRadius: defaultStyles.borderRadius,
        backgroundColor: colors.light,
    },
    textLeft: {
        fontSize: 16,
        fontWeight: "bold",
        textAlign: "left",
    },
    text: {
        fontSize: 16,
        fontWeight: "bold",
    },
    footerText: {
        fontSize: 14,
        fontWeight: "bold",
    },
    requestText: {
        fontWeight: "bold",
        textAlign: "center",
    },
    textRight: {
        fontSize: 16,
        fontWeight: "bold",
        textAlign: "right",
    },
    title: {
        fontSize: 17,
        fontWeight: "bold",
        flex: 1,
        textAlign: "center",
        paddingTop: 4,
        marginHorizontal: 4,
    },
    subTitle: {
        fontSize: 15,
        fontWeight: "bold",
        flex: 1,
        color: colors.red,
        textAlign: "center",
        paddingTop: 8,
        marginHorizontal: 4,
    },

    column: {
        flexDirection: "row",
        justifyContent: "center",
    },
    columnRight: {
        marginLeft: 16,
    },
    columnLeft: {
        paddingLeft: 40,
    },
    row: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 8,
    },
    rowLeft: {
        flexDirection: "column",
        alignItems: "center",
    },
    rowRight: {
        flexDirection: "row",
        alignItems: "center",
    },
});
