import { create } from "apisauce";

const apiClient = create({
    baseURL: "https://makemebusy.com/wp-json/wp/v2/posts",
});

const getCMS = () => apiClient.get("/1488");

export default {
    getCMS,
};
