import React from "react";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";

import colors from "../config/colors";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import RequestScreen from "../screens/Customer/RequestScreen";
import QuotesScreen from "../screens/Customer/QuotesScreen";
import CustomerChatsScreen from "../screens/Customer/CustomerChatsScreen";

const CustomerTabNavigator = ({ navigation }) => {
    const Tab = createBottomTabNavigator();

    return (
        <Tab.Navigator
            tabBarOptions={{
                activeTintColor: colors.red,
                inactiveTintColor: colors.white,
                activeBackgroundColor: colors.tertiary,
                inactiveBackgroundColor: colors.tertiary,
                labelStyle: {
                    fontSize: 13,
                },
                style: {
                    backgroundColor: colors.tertiary,
                },
            }}
            screenOptions={({ route }) => ({
                tabBarIcon: ({ focused, color }) => {
                    let iconName;

                    if (route.name === "Request") {
                        iconName = focused ? "car" : "car-outline";
                    }

                    if (route.name === "Quotes") {
                        iconName = focused ? "clipboard-text" : "clipboard-text-outline";
                    }

                    if (route.name === "CustomerChats") {
                        iconName = focused ? "chat" : "chat-outline";
                    }

                    return <MaterialCommunityIcons name={iconName} color={color} size={24} />;
                },
            })}
        >
            <Tab.Screen name="Quotes" options={{ unmountOnBlur: true }} component={QuotesScreen} />
            <Tab.Screen name="Request" options={{ unmountOnBlur: true }} component={RequestScreen} />
            <Tab.Screen name="CustomerChats" options={{ unmountOnBlur: true, title: "Messages" }} component={CustomerChatsScreen} />
        </Tab.Navigator>
    );
};

export default CustomerTabNavigator;
