import { formatDistance } from "date-fns";
import React from "react";
import { View } from "react-native";

import AppText from "./AppText";
import useUser from "../hooks/useUser";
import Container from "./Container";
import colors from "../config/colors";
import defaultStyles from "../config/defaultStyles";
import AnimatedIcon from "./AnimatedIcon";

function ChatCard({ chat, onPress, handleDeleteChat }) {
    const { user } = useUser();
    const MMB_ACCOUNT_ID = "631a79abf97a8178ac41376a";

    return (
        <Container
            title={chat?.users?.map((member) => member.name)}
            subTitle={chat.title}
            icon={"chat-plus"}
            iconColor={chat?.messages[chat.messages.length - 1].sender != MMB_ACCOUNT_ID ? colors.red : colors.green}
            onPress={onPress}
            utility={user?.isAdmin && <AnimatedIcon type={"confirm"} name={"trash-can"} onPress={() => handleDeleteChat()} backgroundColor={colors.red} size={30} />}
        >
            <View
                style={{
                    borderRadius: defaultStyles.borderRadius,
                    padding: 8,
                    marginTop: 4,
                    backgroundColor: colors.light,
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <AppText>
                    {chat.messages.filter((message) => !message.isNote).length > 0 &&
                        chat.messages.filter((message) => !message.isNote)[chat.messages.filter((message) => !message.isNote).length - 1].body}
                </AppText>
            </View>
            <View
                style={{
                    borderRadius: defaultStyles.borderRadius,
                    padding: 8,
                    marginTop: 8,
                    backgroundColor: colors.light,
                    alignItems: "center",
                }}
            >
                {user?.isAdmin && chat.messages.filter((message) => message.isNote).length > 0 && (
                    <AppText style={{ color: colors.red }}>
                        {chat.messages.filter((message) => message.isNote)[chat.messages.filter((message) => message.isNote).length - 1].body}
                    </AppText>
                )}
                <AppText>
                    {chat.messages.length > 0 && formatDistance(new Date(chat.messages[chat.messages.length - 1].createdDate), new Date(Date.now()), { addSuffix: true })}
                </AppText>
            </View>
        </Container>
    );
}

export default ChatCard;
