import React, { useState } from "react";
import { FlatList } from "react-native";

import Screen from "../components/Screen";
import Container from "../components/Container";
import usersApi from "../api/users";
import useApi from "../hooks/useApi";
import usePopUp from "../hooks/usePopUp";
import UserCardReskin from "../components/UserCardReskin";
import colors from "../config/colors";

export default function SuspiciousActivityScreen({ navigation }) {
    const { setMessage } = usePopUp();

    const getSuspiciousUsersApi = useApi(usersApi.getSuspiciousUsers);
    const getDuplicatedNumbersApi = useApi(usersApi.getDuplicatedNumbers);
    const getSharedAccountsApi = useApi(usersApi.getSharedAccounts);

    const [selectedUsers, setSelectedUsers] = useState([]);

    const handleGetUsers = async () => {
        const response = await getSuspiciousUsersApi.request();
        if (!response.ok) {
            setMessage("No Users Found");
            return;
        }
        setSelectedUsers(response.data);
        return;
    };

    const handleGetDuplicatedNumbers = async () => {
        const response = await getDuplicatedNumbersApi.request();
        if (!response.ok) {
            setMessage("No Users Found");
            return;
        }
        setSelectedUsers(response.data);
        return;
    };

    const handleGetSharedAccounts = async () => {
        const response = await getSharedAccountsApi.request();
        if (!response.ok) {
            setMessage("No Users Found");
            return;
        }
        setSelectedUsers(response.data);
        return;
    };

    return (
        <Screen>
            <FlatList
                data={selectedUsers}
                keyExtractor={(item) => item._id}
                showsVerticalScrollIndicator={true}
                ListHeaderComponent={
                    <>
                        <Container
                            title={"Block Item"}
                            subTitle="Block a new Phone or Email"
                            icon={"close"}
                            iconColor={colors.red}
                            onPress={() => navigation.navigate("Block")}
                        />
                        <Container title={"Scan for Duplicate Devices"} subTitle="User accounts that have the same deviceID" icon={"magnify"} onPress={handleGetUsers} />
                        <Container
                            title={"Scan for Duplicate Numbers"}
                            subTitle="User accounts that have the same Phone Number"
                            icon={"magnify"}
                            onPress={handleGetDuplicatedNumbers}
                        />
                        <Container
                            title={"Scan for Shared Accounts"}
                            subTitle="User accounts that are used on more than 3 devices"
                            icon={"magnify"}
                            onPress={handleGetSharedAccounts}
                        />
                        <Container title={"User Map"} subTitle="View Users on the Map" icon={"map-marker"} onPress={() => navigation.navigate("UsersMap")} />
                    </>
                }
                renderItem={({ item }) => (
                    <Container title={item._id} subTitle={item.users[0].reason}>
                        {item.users.map((user, index) => (
                            <UserCardReskin key={index} user={user} />
                        ))}
                    </Container>
                )}
            />
        </Screen>
    );
}
