import React, { useEffect, useState } from "react";
import { View, Switch } from "react-native";
import colors from "../config/colors";

export default function AppSwitch({ onChange, value }) {
    const [switchValue, setSwitchValue] = useState(value);

    const toggleSwitchValue = (value: boolean) => {
        setSwitchValue(value);
        onChange(value);
    };

    useEffect(() => {
        setSwitchValue(value);
    }, [value]);

    return (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Switch onValueChange={(value) => toggleSwitchValue(value)} value={switchValue} trackColor={{ false: colors.red, true: colors.green }} />
        </View>
    );
}
