// React
import React, { useEffect, useState, useRef } from "react";
import { KeyboardAvoidingView, ScrollView, View, Platform, Pressable, Modal, TextInput } from "react-native";
import * as Linking from "expo-linking";

import { format } from "date-fns";
// Components
import AppText from "../components/AppText";
import Container from "../components/Container";
import Icon from "../components/Icon";
import IconTouchable from "../components/IconTouchable";
import Screen from "../components/Screen";
// API
import chatsApi from "../api/chats";
import useUser from "../hooks/useUser";
import useApi from "../hooks/useApi";
import usersApi from "../api/users";
// Config
import colors from "../config/colors";
import config from "../config/config";
import defaultStyles from "../config/defaultStyles";
import useToast from "../hooks/useToast";
import usePopUp from "../hooks/usePopUp";

const MMB_ACCOUNT_ID = "631a79abf97a8178ac41376a";

function ChatScreen({ navigation, route }) {
    const [height, setHeight] = useState(0);

    const quickLinks = [
        {
            question: "How do i subscribe?",
            response:
                "Login to our web portal here and select the Manage Subscriptions option to Subscribe\n\nhttps://makemebusynode.herokuapp.com/Login\n\nIf you have any further questions, please let us know.",
        },
        {
            question: "How do I unsubscribe?",
            response:
                "Login to our web portal here and select the Manage Subscriptions option to cancel your subscription\n\nhttps://makemebusynode.herokuapp.com/Login\n\nIf you have any further questions, please let us know.",
        },
        {
            question: "How does it work?",
            response: "You can find tutorial videos and information about how the app works at this link - https://makemebusy.com/how-it-works",
        },
        {
            question: "Review - Android",
            response:
                "If you were happy with our service today, please take a moment to leave a review here - https://play.google.com/store/apps/details?id=com.brent.makemebusyapp&hl=en_GB&gl=US",
        },
        {
            question: "Review - Apple",
            response:
                "If you were happy with our service today, please take a moment to leave a review here - https://apps.apple.com/gb/app/make-me-busy/id1562856411?action=write-review",
        },
    ];

    const { user } = useUser();

    const sendMessageApi = useApi(chatsApi.sendMessage);
    const deleteMessageApi = useApi(chatsApi.deleteMessage);
    const getChatApi = useApi(chatsApi.getChat);
    const reactToMessageApi = useApi(chatsApi.reactToMessage);
    const sendTestPushNotificationApi = useApi(usersApi.sendTestPushNotification);
    const saveChatResponseApi = useApi(chatsApi.saveChatResponse);
    const getChatResponsesApi = useApi(chatsApi.getChatResponses);

    const { showToast } = useToast();
    const { confirmDialog } = usePopUp();

    const [newMessage, setNewMessage] = useState("");
    const [chat, setChat] = useState();
    const [quickResponseDisplay, setQuickResponseDisplay] = useState(false);

    const scrollView = useRef(null);

    useEffect(() => {
        refreshChat();
        const autoRefresh = setInterval(() => {
            refreshChat();
        }, 10000);

        return () => clearInterval(autoRefresh);
    }, []);

    const refreshChat = async () => {
        const response = await getChatApi.request(route.params.chatID);
        if (response.ok) {
            //Filter notes if not admin
            if (user?.isAdmin) {
                setChat(response.data);
            } else {
                const filteredMessage = response.data.messages.filter((message) => !message.isNote);
                response.data.messages = filteredMessage;
                setChat(response.data);
            }
        }
    };

    const handleDeleteMessage = async (messageID) => {
        const response = await deleteMessageApi.request(chat._id, messageID);
        if (response.ok) {
            refreshChat();
        }
    };

    const handleSendMessage = async (message) => {
        if (!message) {
            return;
        }

        const response = await sendMessageApi.request(message, chat._id, route.params.activeUser, false);
        if (response.ok) {
            setNewMessage("");
            refreshChat();
            if (!scrollView) return;

            scrollView?.current?.scrollToEnd({ animated: true });
        }
    };

    const handleSendNote = async () => {
        if (!newMessage) {
            return;
        }

        const response = await sendMessageApi.request(newMessage, chat._id, route.params.activeUser, true);
        if (response.ok) {
            setNewMessage("");
            refreshChat();
        }
    };

    const handleReactToMessage = async (messageID, reaction) => {
        const response = await reactToMessageApi.request(chat._id, messageID, reaction);
        if (response.ok) {
            refreshChat();
        }
    };

    const getMessageSenderName = (senderID) => {
        if (senderID == MMB_ACCOUNT_ID) {
            return "Support";
        }

        const index = chat.users.findIndex((x) => x._id == senderID);
        if (index >= 0) {
            return chat.users[index].name;
        } else {
            return "Customer";
        }
    };

    const handleSendTestPushNotification = async () => {
        const response = await sendTestPushNotificationApi.request(chat.users[0]._id);
        if (!response.ok) {
            showToast("Error sending push notification", "error");
            return;
        }

        showToast("Push notification\nSend Status: " + response.data[0].sendStatus + "\nReceipt Status: " + response.data[0].receiptStatus, "success");
    };

    const handleSaveChatResponse = async (text) => {
        const response = await saveChatResponseApi.request(text);
        if (response.ok) {
            showToast("Message saved", "success");
        }
    };

    const handleOpenQuickResponseModel = async () => {
        /*   const response = await getChatResponsesApi.request();
        if (!response.ok) {
            showToast("Error getting quick responses", "error");
            return;
        }

        setQuickResponses(response.data);
 */
        setQuickResponseDisplay(true);
    };

    return (
        <KeyboardAvoidingView behavior={Platform.OS === "ios" ? "padding" : "height"} style={{ flex: 1, backgroundColor: colors.tertiary }} keyboardVerticalOffset={104}>
            <Screen backgroundColor={colors.tertiary} loading={sendTestPushNotificationApi.loading}>
                <ScrollView showsVerticalScrollIndicator={false} ref={scrollView} onContentSizeChange={() => scrollView?.current?.scrollToEnd({ animated: true })}>
                    {chat?.messages &&
                        chat.messages.map((message) => (
                            <View
                                key={message._id}
                                style={{
                                    alignItems: message.sender == route.params.activeUser ? "flex-end" : "flex-start",
                                    paddingLeft: message.sender == route.params.activeUser ? 40 : 0,
                                    paddingRight: message.sender == route.params.activeUser ? 0 : 40,
                                }}
                            >
                                <View style={{ width: "100%" }}>
                                    <Container
                                        animated={false}
                                        title={message.isNote ? "Note" : getMessageSenderName(message.sender) + " " + format(new Date(message.createdDate), "   MMM-dd HH:mm")}
                                        titleColor={!message.isNote ? colors.white : colors.red}
                                        backgroundColor={message.sender == route.params.activeUser ? "#0A4B3A" : "#192127"}
                                        utility={
                                            <View style={{ flexDirection: "row" }}>
                                                {message.sender != route.params.activeUser && !message.reaction && (
                                                    <View style={{ paddingRight: 4 }}>
                                                        <IconTouchable
                                                            name="thumb-up"
                                                            backgroundColor={colors.green}
                                                            size={30}
                                                            onPress={() => handleReactToMessage(message._id, "thumb-up")}
                                                        />
                                                    </View>
                                                )}
                                                {user?.isAdmin && (
                                                    <IconTouchable name="trash-can" backgroundColor={colors.red} size={30} onPress={() => handleDeleteMessage(message._id)} />
                                                )}
                                            </View>
                                        }
                                    >
                                        <AppText selectable={true} style={{ fontWeight: "bold", color: colors.white }}>
                                            {message.body}
                                        </AppText>
                                        {message.body.match(/\bhttps?:\/\/\S+/gi) && (
                                            <AppText
                                                onPress={() => {
                                                    Linking.openURL(message.body.match(/\bhttps?:\/\/\S+/gi)[0]);
                                                }}
                                                selectable={true}
                                                style={{
                                                    paddingTop: 8,
                                                    color: colors.secondary,
                                                    fontWeight: "bold",
                                                }}
                                                numberOfLines={1}
                                                ellipsizeMode="clip"
                                            >
                                                {message.body.match(/\bhttps?:\/\/\S+/gi) && "Link - " + message.body.match(/\bhttps?:\/\/\S+/gi)[0]}
                                            </AppText>
                                        )}
                                        <View style={{ flexDirection: "row" }}>
                                            {message.hasViewed && user?.isAdmin && <Icon name={"eye"} backgroundColor={"transparent"} size={20} />}
                                            {message.reaction && <Icon name={message.reaction} backgroundColor={"transparent"} size={20} />}
                                        </View>
                                    </Container>
                                </View>
                            </View>
                        ))}
                </ScrollView>

                <View
                    style={{
                        backgroundColor: colors.tertiary,
                        flexDirection: "column",
                        borderTopColor: colors.light,
                        borderTopWidth: 0.25,
                        paddingHorizontal: 16,
                        paddingVertical: 8,
                    }}
                >
                    {user?.isAdmin && (
                        <View style={{ flexDirection: "row", justifyContent: "space-between", paddingBottom: 8 }}>
                            <IconTouchable name={"pencil"} size={40} iconColor={colors.red} onPress={() => handleSendNote()} />
                            <IconTouchable name={"link"} size={40} iconColor={colors.red} onPress={handleOpenQuickResponseModel} />
                            <IconTouchable name={"magnify"} size={40} iconColor={colors.red} onPress={() => navigation.navigate("FindLead")} />
                            <IconTouchable
                                name={"bell-badge"}
                                size={40}
                                iconColor={colors.red}
                                onPress={() => confirmDialog(handleSendTestPushNotification, "Are you sure you want to send a test notification?")}
                            />
                            <IconTouchable
                                name={"account"}
                                size={40}
                                iconColor={colors.red}
                                onPress={() => navigation.navigate("UserDetails", { userID: chat.users[0]._id })}
                            />
                        </View>
                    )}
                    <View
                        style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            backgroundColor: colors.light,
                            padding: 4,
                            borderRadius: defaultStyles.borderRadius,
                        }}
                    >
                        <TextInput
                            placeholder="Type a message"
                            style={[config.text, { width: "75%", marginLeft: 8, maxHeight: 150, height }]}
                            multiline={true}
                            value={newMessage}
                            onChangeText={(text) => setNewMessage(text)}
                            onFocus={() => setTimeout(() => scrollView?.current?.scrollToEnd({ animated: true }), 200)}
                            placeholderTextColor="grey"
                            hitSlop={{ top: 20, bottom: 20, left: 40, right: 40 }}
                            textAlign={"left"}
                            onContentSizeChange={(event) => setHeight(event.nativeEvent.contentSize.height)}
                        />
                        <IconTouchable name={"send"} actionBeforeAnimate={true} size={40} borderColor={"transparent"} onPress={() => handleSendMessage(newMessage)} />
                    </View>
                </View>

                <Modal visible={quickResponseDisplay} animationType="slide">
                    <Screen>
                        <ScrollView
                            style={{
                                paddingHorizontal: 5,
                                backgroundColor: colors.white,
                            }}
                        >
                            <Pressable
                                style={{ backgroundColor: colors.white, alignItems: "center", paddingBottom: 20, zIndex: 1 }}
                                onPress={() => setQuickResponseDisplay(!quickResponseDisplay)}
                            >
                                <Icon backgroundColor={colors.white} iconColor={colors.black} size={40} name={"close"} />
                            </Pressable>
                            {quickLinks.map((quickLink, index) => (
                                <Container
                                    key={index}
                                    title={quickLink.question}
                                    subTitle={quickLink.response}
                                    onPress={() => {
                                        setNewMessage(quickLink.response);
                                        setQuickResponseDisplay(!quickResponseDisplay);
                                    }}
                                />
                            ))}
                        </ScrollView>
                    </Screen>
                </Modal>
            </Screen>
        </KeyboardAvoidingView>
    );
}

export default ChatScreen;
