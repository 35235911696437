import client from "./client";

const getReporting = () => client.get("/reporting");
const getStatusCounts = (days: string) => client.get("/reporting/statusCounts/" + days);
const getUserHistory = (userID: string) => client.post("/reporting/userHistory", { userID });
const getAgentStatistics = (userID: string) => client.get("/reporting/agentStatistics/" + userID);
const getPremiumSalvageStats = () => client.get("/reporting/premiumSalvageStats");

export default {
    getReporting,
    getStatusCounts,
    getUserHistory,
    getAgentStatistics,
    getPremiumSalvageStats,
};
