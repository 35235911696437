export default {
    primary: "#ff8500",
    secondary: "#4ecdc4",
    tertiary: "#223F4B",
    low: "#223F4B",
    mid: "#356273",
    top: "#40768A",
    top2: "#4A8BA4",
    red: "#EC3E3E",
    redNew: "#FF5A5A",
    honeyComb: "#ffc52b",
    light: "#e8e8e8",
    black: "#000",
    white: "#fff",
    medium: "#333333",
    dark: "#0c0c0c",
    yellow: "#ffe66d",
    yellowNew: "#f5ca46",
    green: "#3EB489",
    silver: "#C0C0C0",
    gray: "#6e6e6e",
    regPlate: "#fec20a",
    regPlateBlue: "#1e4fb5",
    anyspares: "#E4E8ED",
};
