import React, { useState } from "react";
import { View, StyleSheet, Image, Pressable } from "react-native";
import config from "../config/config";
import AppText from "./AppText";
import Icon from "./Icon";
import { useNavigation } from "@react-navigation/native";
import usersApi from "../api/users";
import useApi from "../hooks/useApi";
import useMedia from "../hooks/useMedia";
import mime from "mime";
import AppActivityIndicator from "./AppActivityIndicator";
import colors from "../config/colors";
import defaultStyles from "../config/defaultStyles";
import Container from "./Container";
import { IUser } from "../interfaces/user";
import { MaterialCommunityIcons } from "@expo/vector-icons";

function UserCardReskin({ user }: { user: IUser }) {
    const navigation = useNavigation();
    const { selectImageFromLibrary } = useMedia();

    const [userProfileImage, setUserProfileImage] = useState(user.profileImage);

    const uploadProfileImageApi = useApi(usersApi.uploadProfileImage);

    const upLoadImageFromLibrary = async () => {
        const image = await selectImageFromLibrary();

        if (!image) {
            return;
        }

        let formdata = new FormData();
        var extension = image.uri.slice(-4);

        // IF YOU DONT HAVE THE CURRENT MIME TYPE HERE IT WONT WORK ON ANDROID
        //AND IT WONT TELL YOU WHY, CHANGE AT YOUR OWN PERIL.
        const mimeType = mime.getType(image);

        formdata.append("file", {
            uri: image.uri,
            type: mimeType,
            name: "profileImage" + extension,
        });

        formdata.append("userID", user._id);

        const response = await uploadProfileImageApi.request(formdata);
        if (response.ok) {
            setUserProfileImage(response.data);
        }
    };

    return (
        <>
            {uploadProfileImageApi.loading && <AppActivityIndicator />}
            <Container onPress={() => navigation.navigate("UserDetails", { userID: user._id })}>
                <View
                    style={{
                        flexDirection: "column",
                        paddingVertical: 0,
                    }}
                >
                    <View style={{ flexDirection: "row" }}>
                        <View
                            style={{
                                height: 80,
                                width: 80,
                                backgroundColor: colors.tertiary,
                                borderRadius: defaultStyles.borderRadius,
                            }}
                        >
                            <Pressable onPress={userProfileImage ? () => navigation.navigate("ViewImages", { images: [userProfileImage] }) : () => upLoadImageFromLibrary()}>
                                <Image source={{ uri: userProfileImage }} style={{ width: 80, height: 80, borderRadius: defaultStyles.borderRadius }} resizeMode="cover" />
                            </Pressable>
                            <Pressable style={{ position: "absolute", opacity: 0.8 }} onPress={() => upLoadImageFromLibrary()}>
                                <Icon name={"camera"} iconColor={colors.white} size={32} />
                            </Pressable>
                        </View>

                        <View style={styles.detailsContainer}>
                            <View
                                style={{
                                    flexDirection: "row",
                                }}
                            >
                                <MaterialCommunityIcons
                                    name={user.isVerified ? "shield-check" : "shield-alert"}
                                    size={22}
                                    color={user.isVerified ? colors.green : colors.red}
                                />
                                <AppText style={styles.title}>{user.name}</AppText>
                            </View>

                            <AppText style={styles.title}>{user.email}</AppText>
                            <AppText style={styles.title}>{user?.contact?.phone}</AppText>
                        </View>
                    </View>
                    <View style={styles.footer}>
                        {config.subscriptions.map((subscription) => (
                            <View key={subscription.name}>
                                <Icon
                                    name={subscription.iconName}
                                    backgroundColor={colors.tertiary}
                                    iconColor={user.subscriptions?.includes(subscription.name) ? colors.green : colors.white}
                                    size={35}
                                />
                            </View>
                        ))}
                    </View>
                </View>
            </Container>
        </>
    );
}

const styles = StyleSheet.create({
    footer: {
        flexDirection: "row",
        paddingTop: 8,
        justifyContent: "space-between",
    },
    container: {
        flexDirection: "column",
        backgroundColor: colors.white,
    },
    detailsContainer: {
        marginLeft: 8,
        justifyContent: "space-between",
    },
    title: {
        fontWeight: "bold",
        fontSize: 16,
        paddingHorizontal: 4,
    },
    subTitle: {
        fontWeight: "bold",
        fontSize: 14,
        paddingHorizontal: 4,
    },
    uniqueID: {
        fontSize: 16,
        color: colors.red,
        fontWeight: "bold",
    },
    body: {
        alignItems: "center",
    },
});

export default UserCardReskin;
