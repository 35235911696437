import React, { useEffect, useState } from "react";
import Screen from "../components/Screen";

import Container from "../components/Container";
import useApi from "../hooks/useApi";
import { format } from "date-fns";
import useToast from "../hooks/useToast";
import paymentsApi from "../api/payments";
import SubContainer from "../components/SubContainer";
import { ScrollView } from "react-native";

export default function TransactionsScreen() {
    const { showToast } = useToast();

    const getPaypalTransactionsApi = useApi(paymentsApi.getTransactions);

    const [transactions, setTransactions] = useState([]);

    useEffect(() => {
        getTransactionHistory();
    }, []);

    const getTransactionHistory = async () => {
        const response = await getPaypalTransactionsApi.request();
        if (!response.ok) {
            showToast("Unable to get transaction history", "error");
            return;
        }

        //filter out negative transactions
        response.data.transaction_details = response.data.transaction_details.filter((transaction) => {
            return transaction.transaction_info.transaction_amount.value > 0;
        });

        setTransactions(response.data.transaction_details.reverse());
    };

    return (
        <Screen loading={getPaypalTransactionsApi.loading}>
            <ScrollView>
                {transactions.map((transaction) => (
                    <Container
                        key={transaction.transaction_info.transaction_id}
                        title={"£" + transaction.transaction_info.transaction_amount.value}
                        subTitle={format(new Date(transaction.transaction_info.transaction_initiation_date), "dd-MM-yyyy HH:mm")}
                        icon={"cash"}
                    >
                        {transaction.payer_info.email_address && <SubContainer title={"Email"} subTitle={transaction.payer_info.email_address} />}
                        {transaction.transaction_info.paypal_reference_id && (
                            <SubContainer title={"Paypal Reference ID"} subTitle={transaction.transaction_info.paypal_reference_id} />
                        )}
                        {transaction.transaction_info.transaction_subject && (
                            <SubContainer title={"Description"} subTitle={transaction.transaction_info.transaction_subject} />
                        )}
                        {transaction.payer_info.address?.postal_code && <SubContainer title={"Postcode"} subTitle={transaction.payer_info.address?.postal_code} />}
                    </Container>
                ))}
            </ScrollView>
        </Screen>
    );
}
