import React, { useEffect, useState } from "react";
import { FlatList } from "react-native";

import Screen from "../components/Screen";
import usersApi from "../api/users";
import useApi from "../hooks/useApi";
import UserCardReskin from "../components/UserCardReskin";
import AppText from "../components/AppText";
import Container from "../components/Container";

export default function MatchingDevicesScreen({ navigation, route }) {
    const getMatchingUsersApi = useApi(usersApi.getMatchingUsers);

    const [matchingUsers, setMatchingUsers] = useState([]);

    useEffect(() => {
        getMatchingUsers();
    }, []);

    const getMatchingUsers = async () => {
        const response = await getMatchingUsersApi.request(route.params.device);
        if (!response.ok) {
            return;
        }

        setMatchingUsers(response.data);
    };

    return (
        <Screen>
            <FlatList
                data={matchingUsers}
                ListEmptyComponent={<Container title={"No Matching Users Found for Device ID"} subTitle={route.params.device} />}
                keyExtractor={(item) => item._id}
                showsVerticalScrollIndicator={true}
                renderItem={({ item }) => <UserCardReskin user={item} onPress={() => navigation.navigate("UserDetails", { userID: item._id })} />}
            />
        </Screen>
    );
}
