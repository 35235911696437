import client from "./client";

const getChats = (userID: string) => client.get("/chats/" + userID);
const getCustomerChats = (leadID: string) => client.get("/chats/getCustomerChats/" + leadID);
const deleteChat = (chatID: string) => client.delete("/chats/" + chatID);
const deleteMessage = (chatID: string, messageID: string) => client.post("/chats/deleteMessage/", { chatID, messageID });
const editMessage = (chatID: string, messageID: string, newMessage: string) => client.post("/chats/editMessage/", { chatID, messageID, newMessage });
const getChat = (chatID: string) => client.get("/chats/getChat/" + chatID);
const getChatCustomer = (chatID: string) => client.get("/chats/getChatCustomer/" + chatID);
const addUserToChat = (chatID: string, userID: string) => client.post("/chats/addUserToChat", { chatID, userID });
const newChat = (users: string[], message: string) => client.post("/chats", { users, message });
const newCustomerChat = (leadID: string, userID: string) => client.post("/chats/newCustomerChat", { leadID, userID });
const sendMessage = (body: string, chatID: string, senderID: string, isNote: boolean) => client.post("/chats/sendMessage", { body, chatID, senderID, isNote });
const sendMessageCustomer = (body: string, chatID: string) => client.post("/chats/sendMessageCustomer", { body, chatID });
const approveMessage = (chatID: string, messageID: string) => client.post("/chats/approveMessage", { chatID, messageID });
const reactToMessage = (chatID: string, messageID: string, reaction: string) => client.post("/chats/reactToMessage", { chatID, messageID, reaction });
const saveChatResponse = (text: string) => client.post("/chats/chatResponse", { text });
const deleteChatResponse = (responseID: string) => client.delete("/chats/chatResponse", { responseID });
const getChatResponses = () => client.get("/chats/chatResponse");

export default {
    getChats,
    getCustomerChats,
    newChat,
    newCustomerChat,
    getChat,
    deleteChat,
    deleteMessage,
    editMessage,
    sendMessage,
    sendMessageCustomer,
    addUserToChat,
    approveMessage,
    reactToMessage,
    getChatCustomer,
    saveChatResponse,
    deleteChatResponse,
    getChatResponses,
};
