import { useContext } from "react";
import { NewsContext } from "../context/newsContext";

import useApi from "./useApi";
import newsApi from "../api/news";
import useUser from "./useUser";

const useNews = () => {
    const { news, setNews } = useContext(NewsContext);
    const { user } = useUser();

    const getNewsApi = useApi(newsApi.getNews);
    const setLastViewedNewsApi = useApi(newsApi.setLastViewedNews);

    const getNews = async () => {
        const response = await getNewsApi.request();
        if (!response.ok) {
            setNews({ newsItems: [], newNewsCount: 0 });
            return;
        }

        setNews(response.data);
    };

    const setLastViewedNews = async () => {
        const response = await setLastViewedNewsApi.request(user._id);
        if (response.ok) {
            getNews();
        }
    };

    return { news, setNews, getNews, setLastViewedNews };
};

export default useNews;
