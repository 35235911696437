import React, { useEffect } from "react";

import Screen from "../components/Screen";
import useUser from "../hooks/useUser";

import AppText from "../components/AppText";
import Container from "../components/Container";
import colors from "../config/colors";
import { FlatList, Platform, View } from "react-native";
import * as Linking from "expo-linking";
import { formatDistance } from "date-fns";
import defaultStyles from "../config/defaultStyles";
import IconTouchable from "../components/IconTouchable";
import useNotifications from "../hooks/useNotifications";

function NotificationsScreen({ navigation }) {
    const { user } = useUser();
    const { notifications, getNotifications, deleteNotification } = useNotifications();

    const handleNotificationAction = (notification) => {
        if (!notification.data) return;

        if (notification.data.leadID) {
            navigation.navigate("ListingDetails", { leadID: notification.data.leadID });
        }
    };

    useEffect(() => {
        getNotifications();
    }, []);

    return (
        <Screen>
            <FlatList
                data={notifications}
                keyExtractor={(item) => item._id}
                showsVerticalScrollIndicator={true}
                ListEmptyComponent={<Container title={"You're up to date!"} subTitle={"No more messages"} icon={"check"} iconColor={colors.green} />}
                ListHeaderComponent={
                    <>
                        {!user.locationServicesEnabled && Platform.OS !== "web" && (
                            <Container
                                title={"Location Services Disabled"}
                                subTitle={"Tap here to enable location services"}
                                icon={"cog"}
                                iconBackgroundColor={colors.light}
                                iconColor={colors.red}
                                onPress={() => Linking.openSettings()}
                            >
                                <View
                                    style={{
                                        justifyContent: "space-evenly",
                                        alignItems: "center",
                                        padding: 4,
                                        flexDirection: "row",
                                        marginTop: 4,
                                        backgroundColor: colors.light,
                                        borderRadius: defaultStyles.borderRadius,
                                    }}
                                >
                                    <AppText>{formatDistance(new Date(), new Date(), { addSuffix: true })}</AppText>
                                </View>
                            </Container>
                        )}
                        {!user.pushNotificationsEnabled && Platform.OS !== "web" && (
                            <Container
                                title={"Push Notifications Disabled"}
                                subTitle={"Tap here to enable push notifications"}
                                iconBackgroundColor={colors.light}
                                icon={"cog"}
                                iconColor={colors.red}
                                onPress={() => Linking.openSettings()}
                            >
                                <View
                                    style={{
                                        justifyContent: "space-evenly",
                                        alignItems: "center",
                                        padding: 4,
                                        flexDirection: "row",
                                        marginTop: 4,
                                        backgroundColor: colors.light,
                                        borderRadius: defaultStyles.borderRadius,
                                    }}
                                >
                                    <AppText>{formatDistance(new Date(), new Date(), { addSuffix: true })}</AppText>
                                </View>
                            </Container>
                        )}
                    </>
                }
                renderItem={({ item }) => (
                    <Container
                        title={item.title}
                        icon={"phone"}
                        iconColor={colors.red}
                        iconBackgroundColor={colors.light}
                        subTitle={item.data ? item.subTitle + "\nTap here for more info" : item.subTitle}
                        onPress={() => handleNotificationAction(item)}
                        utility={
                            <IconTouchable size={40} name={"trash-can"} iconColor={colors.white} backgroundColor={colors.red} onPress={() => deleteNotification(item._id)} />
                        }
                    >
                        <View
                            style={{
                                justifyContent: "space-evenly",
                                alignItems: "center",
                                padding: 4,
                                flexDirection: "row",
                                marginTop: 4,
                                backgroundColor: colors.light,
                                borderRadius: defaultStyles.borderRadius,
                            }}
                        >
                            <AppText>{formatDistance(new Date(item.createdDate), new Date(), { addSuffix: true })}</AppText>
                        </View>
                    </Container>
                )}
            />
        </Screen>
    );
}

export default NotificationsScreen;
