const getDistance = (lat1, lon1, lat2, lon2) => {
    var p = 0.017453292519943295; // Math.PI / 180
    var c = Math.cos;
    var a = 0.5 - c((lat2 - lat1) * p) / 2 + (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;

    return (12742 * Math.asin(Math.sqrt(a))) / 1.6; // 2 * R; R = 6371 km
};

const getRecoveryQuote = (weight, distance) => {
    const pricePerMile = 1.75;
    const basePrice = 50;
    const heavyLoadPrice = basePrice + 20;

    // Make sure it does have a weight 0 if not.
    if (!weight) {
        weight = 0;
    }

    const mileageCost = pricePerMile * distance;

    if (weight < 1500) {
        return Math.round(mileageCost + basePrice);
    } else {
        return Math.round(mileageCost + heavyLoadPrice);
    }
};

export { getDistance, getRecoveryQuote };
