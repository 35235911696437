import { create } from "apisauce";
import authStorage from "../auth/storage";

const apiClient = create({
    baseURL: "https://makemebusynode.herokuapp.com/api",
});

// add the authtoken to the request header to be validated by the backend when making calls
apiClient.addAsyncRequestTransform(async (request) => {
    try {
        const authToken = await authStorage.getToken();

        if (!authToken) {
            return;
        }
        request.headers["x-auth-token"] = authToken;
    } catch (error) {
        return;
    }
});

export default apiClient;
