import React, { useState } from "react";
import { View, Image } from "react-native";

import Screen from "../components/Screen";
import AppText from "../components/AppText";

import authApi from "../api/auth";

import useApi from "../hooks/useApi";
import useUser from "../hooks/useUser";
import colors from "../config/colors";
import useToast from "../hooks/useToast";
import AppTextInput from "../components/AppTextInput";
import AppButton from "../components/AppButton";
import config from "../config/config";

export default function LoginScreen({ navigation }) {
    const { logIn } = useUser();
    const { showToast } = useToast();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const loginApi = useApi(authApi.login);
    const resetPasswordApi = useApi(authApi.resetPassword);

    const handleLogin = async () => {
        if (!email || !password) {
            showToast("Please enter your email and password", "error");
            return;
        }

        const response = await loginApi.request(email.trim(), password);
        if (!response.ok) {
            showToast("Invalid Email or Password", "error");
            return;
        }
        logIn(response.data);
    };

    const handlePasswordReset = async () => {
        if (!email) {
            showToast("Please enter your email to reset your password", "error");
            return;
        }

        await resetPasswordApi.request(email);
        showToast("Reset instructions sent to " + email + "\n\n Please confirm your email is correct and check your junk folder", "success");
    };

    return (
        <Screen loading={loginApi.loading} style={{ margin: 16, rowGap: 16 }}>
            <View
                style={{
                    width: 190,
                    height: 190,
                    borderRadius: 110,
                    backgroundColor: colors.mid,
                    marginBottom: 16,
                    marginHorizontal: 80,
                    alignSelf: "center",
                    alignItems: "center",
                }}
            >
                <Image resizeMode="contain" style={{ height: 200, width: 300 }} source={require("../assets/logo-busybee.png")} />
            </View>
            <AppTextInput
                autoCapitalize="none"
                icon={"email"}
                placeholder="Email"
                autoComplete={"email"}
                textContentType={"emailAddress"}
                blurOnSubmit
                onChangeText={setEmail}
            />
            <AppTextInput
                icon={"lock"}
                placeholder="Password"
                autoComplete={"password"}
                textContentType={"password"}
                blurOnSubmit
                onChangeText={setPassword}
                secureTextEntry={true}
                autoCapitalize="none"
            />

            <AppText onPress={handlePasswordReset} style={{ color: colors.honeyComb }}>
                Forgot Password?
            </AppText>
            <AppButton title={"Login"} onPress={handleLogin} />
            <AppButton onPress={() => navigation.navigate("Register")} style={{ backgroundColor: colors.red }} title={"Register as an Agent"} />
            <View style={{ flex: 1 }}></View>
            <AppText style={{ fontSize: 12, color: "white" }}>
                {config.versionNo}-{config.latestBuildNo}
            </AppText>
        </Screen>
    );
}
