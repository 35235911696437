// React Packages
import React, { useState } from "react";
import { View, Platform, ScrollView, KeyboardAvoidingView } from "react-native";

// Custom Components
import Container from "../components/Container";

// Hooks
import useApi from "../hooks/useApi";

// Styles and config
import colors from "../config/colors";
import Screen from "../components/Screen";
import useToast from "../hooks/useToast";
import authApi from "../api/auth";
import AppTextInput from "../components/AppTextInput";

export default function BlockScreen({ navigation, route }) {
    const blockApi = useApi(authApi.block);

    const { showToast } = useToast();
    const [reason, setReason] = useState("");
    const [itemToBlock, setItemToBlock] = useState(route?.params?.itemToBlock ? route.params.itemToBlock : "");

    const handleBlock = async () => {
        if (!itemToBlock || !reason) {
            showToast("Please fill in all fields", "error");
            return;
        }

        //if includes @ then it is an email
        const type = itemToBlock.includes("@") ? "email" : "phone";

        const response = await blockApi.request(itemToBlock, type, reason);
        if (!response.ok) {
            showToast("Unable to block Item", "error");
            return;
        }

        showToast("Item Blocked", "success");
        navigation.goBack();
    };

    return (
        <Screen>
            <KeyboardAvoidingView behavior={Platform.OS === "ios" ? "padding" : "height"} style={{ flex: 1, backgroundColor: colors.tertiary }} keyboardVerticalOffset={104}>
                <ScrollView>
                    <Container title={"Enter Item to Block"} icon={"numeric-1-box"} animated={false}>
                        <View style={{ paddingTop: 8 }}>
                            <AppTextInput
                                placeholder={"phone / email"}
                                defaultValue={route?.params?.itemToBlock ? route.params.itemToBlock : ""}
                                onChangeText={(value) => setItemToBlock(value)}
                            />
                        </View>
                    </Container>
                    <Container title={"Reason for the block"} subTitle={"users name - add as much context as you can"} icon={"numeric-2-box"} animated={false}>
                        <View style={{ paddingTop: 8 }}>
                            <AppTextInput placeholder={"enter reason"} onChangeText={(value) => setReason(value)} />
                        </View>
                    </Container>

                    <Container title={"Block"} icon={"check"} onPress={handleBlock}></Container>
                </ScrollView>
            </KeyboardAvoidingView>
        </Screen>
    );
}
