import React from "react";
import { StyleSheet, Modal, View, useWindowDimensions } from "react-native";
import colors from "../config/colors";
import usePopUp from "../hooks/usePopUp";
import AppText from "./AppText";
import IconTouchable from "./IconTouchable";

function PopUpBox() {
    const { message, setMessage, popUpOnConfirm, setPopUpOnConfirm } = usePopUp();
    const { width } = useWindowDimensions();

    const handleOnConfirm = () => {
        popUpOnConfirm();
        setMessage(false);
        setPopUpOnConfirm(false);
    };

    return (
        <Modal animationType="fade" transparent={true} visible={message ? true : false}>
            <View style={{ justifyContent: "center", alignItems: "center", flex: 1 }}>
                <View style={[styles.modalView, { width: width < 576 ? "90%" : 576 }]}>
                    <AppText style={{ textAlign: "center", paddingBottom: 20 }}>{message}</AppText>
                    <View style={{ flexDirection: "row", justifyContent: "space-around" }}>
                        <IconTouchable name={"close"} iconColor={colors.red} onPress={() => setMessage(false)} />
                        {popUpOnConfirm && <IconTouchable name={"check"} iconColor={colors.green} onPress={() => handleOnConfirm()} />}
                    </View>
                </View>
            </View>
        </Modal>
    );
}

const styles = StyleSheet.create({
    modalView: {
        marginHorizontal: 46,
        backgroundColor: colors.white,
        borderRadius: 16,
        borderWidth: 8,
        padding: 16,
        borderColor: colors.honeyComb,
        shadowColor: colors.black,
        shadowOffset: { width: -2, height: 4 },
        shadowOpacity: 0.7,
        shadowRadius: 8,
        elevation: 8,
    },
});

export default PopUpBox;
