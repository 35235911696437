import React from "react";
import { Image } from "react-native";

import Screen from "../components/Screen";
import Container from "../components/Container";

export default function UnavailableScreen({ navigation }) {
    return (
        <Screen centered>
            <Image resizeMode="contain" style={{ height: 140, width: 340, paddingBottom: 40 }} source={require("../assets/logo-busybee.png")} />
            <Container title={"This page is currently unavailable on Web"} subTitle={"Please download the Make Me Busy App to access Map View"} icon={"close"} />
        </Screen>
    );
}
