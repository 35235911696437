import { useState, useContext } from "react";
import polyline from "@mapbox/polyline";

import useApi from "./useApi";
import leadsApi from "../api/leads";
import { LeadsContext } from "../context/leadsContext";
import usePopUp from "./usePopUp";
import { ILead } from "../interfaces/lead";
import usersApi from "../api/users";

const useLeads = () => {
    const { leads, setLeads, leadTypeFilters, setLeadTypeFilters, leadDistanceFilter, setLeadDistanceFilter } = useContext(LeadsContext);

    const [loading, setLoading] = useState(false);
    const updateStatusBookedApi = useApi(leadsApi.updateStatusBooked);
    const updateStatusQuotedApi = useApi(leadsApi.updateStatusQuoted);
    const setLeadTypeFiltersApi = useApi(usersApi.setLeadTypeFilters);
    const setLeadDistanceFilterApi = useApi(usersApi.setLeadDistanceFilter);

    const getLeadsApi = useApi<ILead[]>(leadsApi.getLeads);
    const { setMessage } = usePopUp();

    const toggleLeadsTypeFilter = async (type: string) => {
        var result = [...leadTypeFilters];

        if (leadTypeFilters.includes(type)) {
            result = result.filter((filterType) => filterType !== type);
        } else {
            result.push(type);
        }

        setLeadTypeFilters(result);

        try {
            await setLeadTypeFiltersApi.request(type);
        } catch (error) {
            console.log(error);
        }
    };

    const loadLeads = async () => {
        setLoading(true);

        const response = await getLeadsApi.request();
        if (!response.ok) {
            setLoading(false);
            setMessage("Unable to find results");
            return;
        }

        response.data.forEach((el) => {
            if ("delivery" in el.location) {
                if ("route" in el.location.delivery) {
                    const routeDecoded = polyline.decode(el.location.delivery.route);

                    el.location.delivery.route = routeDecoded.map((point) => ({
                        latitude: point[0],
                        longitude: point[1],
                    }));
                }
            }
        });

        setLeads(response.data);
        setLoading(false);
    };

    const updateStatusBooked = async (leadID: string, userID: string) => {
        const response = await updateStatusBookedApi.request(leadID, userID);
        if (!response.ok) {
            setMessage(response.data);
            return;
        }

        //STATE MANAGEMENT LOGIC
        //update leads array with the updated return lead
        let leadToUpdateIndex = leads.findIndex((x) => x._id == leadID);
        if (leadToUpdateIndex >= 0) {
            leads[leadToUpdateIndex] = response.data;
            setLeads(leads);
        }

        return response.data;
    };

    const updateStatusQuoted = async (leadID: string) => {
        const response = await updateStatusQuotedApi.request(leadID);
        if (!response.ok) {
            setMessage(response.data);
            return;
        }

        let leadToUpdateIndex = leads.findIndex((x) => x._id == leadID);
        if (leadToUpdateIndex >= 0) {
            leads[leadToUpdateIndex] = response.data;
            setLeads(leads);
        }

        return response.data;
    };

    const setLeadRangeFilter = async (distance: number) => {
        setLeadDistanceFilter(distance);

        try {
            await setLeadDistanceFilterApi.request(distance);
        } catch (error) {
            console.log(error);
        }
    };

    return {
        leads,
        setLeads,
        loading,
        loadLeads,
        updateStatusBooked,
        toggleLeadsTypeFilter,
        setLeadTypeFilters,
        leadTypeFilters,
        setLeadDistanceFilter,
        setLeadRangeFilter,
        leadDistanceFilter,
        updateStatusQuoted,
    };
};

export default useLeads;
