import React, { useState } from "react";

interface NewsContextProps {
    news: any[];
    setNews: React.Dispatch<React.SetStateAction<any[]>>;
}

const NewsContext = React.createContext<NewsContextProps>({
    news: [],
    setNews: () => {},
});

const NewsProvider = ({ children }) => {
    const [news, setNews] = useState<any[]>([]);

    return <NewsContext.Provider value={{ news, setNews }}>{children}</NewsContext.Provider>;
};

export { NewsContext, NewsProvider };
