import React, { useState } from "react";
import { View, Image, ScrollView } from "react-native";
import * as Yup from "yup";

import authApi from "../api/auth";
import usersApi from "../api/users";

import useApi from "../hooks/useApi";
import useUser from "../hooks/useUser";

import AppText from "../components/AppText";
import Screen from "../components/Screen";
import colors from "../config/colors";
import AppTextInput from "../components/AppTextInput";
import useToast from "../hooks/useToast";
import AppButton from "../components/AppButton";
import ContainerNew from "../components/ContainerNew";
import ListItem from "../components/ListItem";
import config from "../config/config";

function RegisterScreen({ navigation }) {
    const { logIn } = useUser();
    const { showToast } = useToast();

    const onboardingScreens = [
        { notice: "Please enter a valid email below, you will need this to access your account later", image: require("../assets/email.png") },
        { notice: "your chosen name will be visible to potential customers", image: require("../assets/user.png") },
        { notice: "A valid UK mobile number is required, to contact customers", image: require("../assets/phone.png") },
        { notice: "We've sent an SMS code to", image: require("../assets/code.png") },
        { notice: "We use your postcode and location to match you to nearby leads", image: require("../assets/postcode.png") },
        { notice: "Please enter and confirm your password below", image: require("../assets/password.png") },
        { notice: "" },
        { notice: "Confirm your details and login below" },
    ];

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [postcode, setPostcode] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [code, setCode] = useState("");
    const [filterTypes, setFilterTypes] = useState([]);

    const [hidePassword, setHidePassword] = useState(true);

    const registerApi = useApi(usersApi.register);
    const sendOTCApi = useApi(authApi.sendOTC);
    const validateOTCApi = useApi(authApi.validateOTC);
    const isExistingUserApi = useApi(authApi.isExistingUser);
    const [step, setStep] = useState(1);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required().max(35).label("name").min(3),
        email: Yup.string().required().email().label("Email"),
        phone: Yup.number().min(2).required().label("Phone"),
        code: Yup.string().min(4).required().label("Code"),
        postcode: Yup.string().required().min(3).label("Postcode"),
        password: Yup.string().required().min(4).label("Password").min(5),
        filterTypes: Yup.array().min(1).label("Filter Types"),
    });

    const handleNextStep = async () => {
        if (step == 1) {
            validateEmail();
        }
        if (step == 2) {
            validateName();
        }
        if (step == 3) {
            validatePhone();
        }
        if (step == 4) {
            validateCode();
        }
        if (step == 5) {
            validatePostcode();
        }
        if (step == 6) {
            validatePassword();
        }
        if (step == 7) {
            validateFilterTypes();
        }
        if (step == 8) {
            handleRegister();
        }
    };

    const validateEmail = async () => {
        if (!email) {
            showToast("Please enter an email address", "error");
            return;
        }

        try {
            await validationSchema.validateAt("email", { email: email.toLowerCase().trim() });
        } catch (error) {
            showToast(error.message, "error");
            return;
        }

        const response = await isExistingUserApi.request(email);
        if (!response.ok) {
            showToast("Error validating email, please contact support", "error");
            return;
        }

        //check if existing user
        if (response.data) {
            showToast("A user already exists with this email\n\nPlease Login or Reset your password", "error");
            navigation.goBack();
            return;
        }

        setStep(step + 1);
    };

    const validateName = async () => {
        try {
            await validationSchema.validateAt("name", { name });
        } catch (error) {
            showToast(error.message, "error");
            return;
        }

        setStep(step + 1);
    };

    const validatePhone = async () => {
        try {
            await validationSchema.validateAt("phone", { phone });
        } catch (error) {
            showToast("Invalid Phone Number", "error");
            return;
        }

        const response = await sendOTCApi.request(phone);
        if (!response.ok) {
            showToast("Error sending OTC, please enter a valid UK mobile number", "error");
            setStep(step - 1);
            return;
        }

        setStep(step + 1);
    };

    const validateCode = async () => {
        try {
            await validationSchema.validateAt("code", { code });
        } catch (error) {
            showToast("Invalid Code", "error");
            return;
        }

        const response = await validateOTCApi.request(phone, code);
        if (!response.ok) {
            showToast("Invalid Code", "error");
            return;
        }

        setStep(step + 1);
    };

    const validatePostcode = async () => {
        try {
            await validationSchema.validateAt("postcode", { postcode });
        } catch (error) {
            showToast(error.message, "error");
            return;
        }

        setStep(step + 1);
    };

    const validatePassword = async () => {
        if (password !== confirmPassword) {
            showToast("Passwords do not match", "error");
            return;
        }

        try {
            await validationSchema.validateAt("password", { password });
            await validationSchema.validateAt("password", { password: confirmPassword });
        } catch (error) {
            showToast(error.message, "error");
            return;
        }

        setStep(step + 1);
    };

    const validateFilterTypes = async () => {
        if (filterTypes.length === 0) {
            showToast("Please select at least one filter type", "error");
            return;
        }

        setStep(step + 1);
    };

    const handleRegister = async () => {
        try {
            await validationSchema.validate({ name, email, phone, code, postcode, password, filterTypes });
        } catch (error) {
            showToast(error.message, "error");
            return;
        }

        const response = await registerApi.request(name, email, phone, postcode, password, filterTypes);
        if (!response.ok) {
            showToast(response.data, "error");
            return;
        }

        logIn(response.data);
    };

    return (
        <Screen loading={registerApi.loading}>
            <ScrollView style={{ margin: 16, flex: 1 }}>
                {onboardingScreens[step - 1]?.image && (
                    <View
                        style={{
                            width: 210,
                            height: 210,
                            borderRadius: 120,
                            backgroundColor: colors.mid,
                            alignSelf: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Image source={onboardingScreens[step - 1]?.image} style={{ width: 170, height: 170, alignSelf: "center" }} />
                    </View>
                )}
                {onboardingScreens[step - 1]?.notice && (
                    <AppText style={{ color: colors.white, textAlign: "center", marginBottom: 30 }}>{onboardingScreens[step - 1]?.notice}</AppText>
                )}
                {step === 1 && (
                    <AppTextInput
                        icon={"email"}
                        placeholder="Email"
                        autoComplete={"email"}
                        textContentType={"emailAddress"}
                        onChangeText={setEmail}
                        autoFocus={true}
                        autoCapitalize="none"
                    />
                )}
                {step === 2 && (
                    <AppTextInput
                        icon={"account"}
                        placeholder={"name or business name"}
                        autoComplete={"name"}
                        textContentType={"name"}
                        onChangeText={setName}
                        autoFocus={true}
                        autoCapitalize="words"
                    />
                )}
                {step === 3 && (
                    <AppTextInput
                        icon={"phone"}
                        placeholder={"UK Mobile Number (0712345678)"}
                        autoComplete={"tel"}
                        textContentType={"telephoneNumber"}
                        onChangeText={setPhone}
                        keyboardType={"numeric"}
                        autoFocus={true}
                        autoCorrect={false}
                    />
                )}
                {step === 4 && (
                    <>
                        <AppText style={{ color: colors.white, textAlign: "center", marginBottom: 30, marginTop: -30 }} onPress={() => setStep(step - 1)}>
                            {phone}
                        </AppText>
                        <AppTextInput
                            icon={"cellphone-message"}
                            placeholder={"SMS Code"}
                            autoComplete={"sms-otp"}
                            textContentType={"oneTimeCode"}
                            onChangeText={setCode}
                            keyboardType={"numeric"}
                            autoFocus={true}
                            autoCorrect={false}
                        />
                    </>
                )}
                {step === 5 && (
                    <AppTextInput
                        icon={"map-marker"}
                        placeholder={"Postcode"}
                        autoComplete={"postal-code"}
                        textContentType={"postalCode"}
                        onChangeText={setPostcode}
                        autoFocus={true}
                        autoCorrect={false}
                    />
                )}
                {step === 6 && (
                    <>
                        <AppTextInput
                            icon={"lock"}
                            rightIcon={hidePassword ? "eye-off" : "eye"}
                            onPressRightIcon={() => setHidePassword(!hidePassword)}
                            placeholder={"Password"}
                            autoComplete="password-new"
                            onChangeText={setPassword}
                            autoFocus={true}
                            autoCorrect={false}
                            secureTextEntry={hidePassword}
                        />
                        <View style={{ marginTop: 16 }}>
                            <AppTextInput
                                icon={"lock-check"}
                                rightIcon={hidePassword ? "eye-off" : "eye"}
                                onPressRightIcon={() => setHidePassword(!hidePassword)}
                                placeholder={"Confirm Password"}
                                onChangeText={setConfirmPassword}
                                autoCorrect={false}
                                secureTextEntry={hidePassword}
                            />
                        </View>
                    </>
                )}
                {step === 7 && (
                    <ContainerNew title={"Which leads are you interested in?"} subTitle="Select all that apply" style={{ rowGap: 8 }}>
                        {config.subscriptions.map((subscription) => (
                            <View key={subscription.name}>
                                <ListItem
                                    icon={subscription.iconName}
                                    subTitle={subscription.description}
                                    title={subscription.nameFormatted}
                                    iconRight={filterTypes.includes(subscription.name) ? "check" : "close"}
                                    iconRightColor={filterTypes.includes(subscription.name) ? colors.white : colors.red}
                                    onPress={() =>
                                        setFilterTypes((prev) =>
                                            prev.includes(subscription.name) ? prev.filter((item) => item !== subscription.name) : [...prev, subscription.name]
                                        )
                                    }
                                />
                            </View>
                        ))}
                    </ContainerNew>
                )}
                {step === 8 && (
                    <>
                        <ContainerNew title={"Your Details"} icon="account" style={{ rowGap: 8 }}>
                            <ListItem icon={"account"} title={name} iconRight={"pencil"} onPress={() => setStep(2)} />
                            <ListItem icon={"email"} title={email} iconRight={"pencil"} onPress={() => setStep(1)} />
                            <ListItem icon={"phone"} title={phone} iconRight={"pencil"} onPress={() => setStep(3)} />
                            <ListItem icon={"map-marker"} title={postcode} iconRight={"pencil"} onPress={() => setStep(5)} />
                        </ContainerNew>
                        <View style={{ marginTop: 16 }}>
                            <AppButton title={"Login"} onPress={handleRegister} />
                        </View>
                    </>
                )}
                <>
                    {step < 8 && (
                        <View style={{ marginTop: 16 }}>
                            <AppButton title={"Continue"} onPress={handleNextStep} />
                        </View>
                    )}
                    {step > 1 && step < 8 && (
                        <View style={{ marginTop: 16 }}>
                            <AppButton title={"Back"} style={{ backgroundColor: colors.red }} onPress={() => setStep(step - 1)} />
                        </View>
                    )}
                </>
            </ScrollView>
        </Screen>
    );
}

export default RegisterScreen;
