import { useContext } from "react";
import * as Location from "expo-location";

import useApi from "./useApi";
import usersApi from "../api/users";
import { LocationContext } from "../context/locationContext";
import useUser from "./useUser";

const useLocation = () => {
    const { location, setLocation } = useContext(LocationContext);
    const { user, setUser } = useUser();
    const setLocationApi = useApi(usersApi.setLocation);

    const getLocation = async () => {
        try {
            var permission = await Location.getForegroundPermissionsAsync();

            if (permission.canAskAgain && permission.status == "denied") {
                permission = await Location.requestForegroundPermissionsAsync();
            }

            if (!permission.granted) {
                user.locationServicesEnabled = false;
                setUser(user);
                return;
            }

            const lastKnownLocation = await Location.getLastKnownPositionAsync({ maxAge: 600000 });
            if (lastKnownLocation) {
                setLocation(lastKnownLocation);
                user.locationServicesEnabled = true;
                setUser(user);
                await setLocationApi.request(lastKnownLocation);
                return;
            }

            const currentLocation = await Location.getCurrentPositionAsync();
            if (currentLocation) {
                setLocation(currentLocation);
                user.locationServicesEnabled = true;
                setUser(user);
                await setLocationApi.request(currentLocation);
                return;
            }
        } catch (error) {
            console.log("Error setting location");
        }
    };

    const getCurrentLocation = async () => {
        try {
            var permission = await Location.getForegroundPermissionsAsync();

            if (permission.canAskAgain && permission.status == "denied") {
                permission = await Location.requestForegroundPermissionsAsync();
            }

            if (!permission.granted) {
                return false;
            }
            var currentLocation = await Location.getCurrentPositionAsync({ accuracy: Location.Accuracy.High });
            if (currentLocation) {
                return currentLocation;
            }

            return false;
        } catch (error) {
            return false;
        }
    };

    return { location, setLocation, getLocation, getCurrentLocation };
};

export default useLocation;
