import React, { useEffect, useState } from "react";

import Screen from "../components/Screen";
import leadsApi from "../api/leads";
import useApi from "../hooks/useApi";
import useToast from "../hooks/useToast";
import { FlatList } from "react-native";
import ListingCard from "../components/ListingCard";

export default function MyPostsScreen({ navigation }) {
    const { showToast } = useToast();

    const getPostsApi = useApi(leadsApi.getPosts);

    const [posts, setPosts] = useState([]);

    useEffect(() => {
        getPosts();
    }, []);

    const getPosts = async () => {
        const response = await getPostsApi.request();
        if (!response.ok) {
            showToast("Something went wrong, please try again later", "error");
            return;
        }

        setPosts(response.data);
    };
    return (
        <Screen>
            <FlatList
                data={posts}
                keyExtractor={(item) => item._id}
                showsVerticalScrollIndicator={false}
                horizontal={false}
                renderItem={({ item }) => <ListingCard lead={item} status={item.status} onPress={() => navigation.navigate("ListingDetails", { leadID: item._id })} />}
            />
        </Screen>
    );
}
