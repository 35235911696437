import React from "react";
import Container from "../components/Container";

import Screen from "../components/Screen";
import colors from "../config/colors";
import * as Linking from "expo-linking";

const affiliateLink =
    "https://www.amazon.co.uk/gp/search?ie=UTF8&tag=makemebusy-21&linkCode=ur2&linkId=ce0dd2b85036125bb6a3630e695a77c4&camp=1634&creative=6738&index=automotive&keywords=Vehicle Recovery Equipment";

function AdminToolsScreen({ navigation }) {
    return (
        <Screen>
            <Container title={"User Management"} icon={"account-group"} iconColor={colors.red} onPress={() => navigation.navigate("Users")} />
            <Container title={"Pirate Detector"} icon={"pirate"} iconColor={colors.red} onPress={() => navigation.navigate("SuspiciousActivity")} />
            <Container title={"Booked Premium Salvage"} icon={"cash"} onPress={() => navigation.navigate("BookedPremiumSalvage")} />
            <Container title={"Onboarding"} icon={"account-check"} onPress={() => navigation.navigate("Onboarding")} />
            <Container title={"Find A Lead"} icon={"magnify"} onPress={() => navigation.navigate("FindLead")} />
            <Container title={"Send News Update"} icon={"alert"} onPress={() => navigation.navigate("SendNews")} />
            <Container title={"Reporting"} icon={"chart-bell-curve"} onPress={() => navigation.navigate("Reporting")} />
            {/*          <Container title={"Affiliate Link"} icon={"link"} onPress={() => Linking.openURL(affiliateLink)} /> */}
        </Screen>
    );
}

export default AdminToolsScreen;
