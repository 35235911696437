import React from "react";
import { View, StyleSheet } from "react-native";
import colors from "../config/colors";
import AppText from "./AppText";
import { useNetwork } from "../hooks/useNetwork";
import { MaterialCommunityIcons } from "@expo/vector-icons";

export default function NetworkIndicator() {
    const { isInternetReachable } = useNetwork();

    if (isInternetReachable) return null;

    return (
        <View style={styles.component}>
            <MaterialCommunityIcons name={"alert"} size={32} color={colors.red} style={{ paddingRight: 8 }} />
            <AppText style={{ fontSize: 20, fontWeight: "bold" }}>Internet Disconnected..</AppText>
        </View>
    );
}

const styles = StyleSheet.create({
    component: {
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: colors.honeyComb,
        flexDirection: "row",
    },
});
