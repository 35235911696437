import { Platform } from "react-native";
import colors from "./colors";

export default {
    colors,
    text: {
        color: colors.black,
        fontSize: 18,
        fontFamily: Platform.OS === "android" ? "Roboto" : "Avenir",
    },
    boldText: {
        color: colors.black,
        fontSize: 18,
        fontWeight: "bold",
        fontFamily: Platform.OS === "android" ? "Roboto" : "Avenir",
    },
    logo: {
        height: 160,
        width: 320,
        alignSelf: "center",
        position: "absolute",
    },
    borderRadius: 8,
};
