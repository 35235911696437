import React, { useState } from "react";

import Screen from "../components/Screen";
import useApi from "../hooks/useApi";
import usersApi from "../api/users";
import { addDays } from "date-fns";
import Container from "../components/Container";
import usePopUp from "../hooks/usePopUp";
import config from "../config/config";
import colors from "../config/colors";
import { TextInput } from "react-native";
import TabSelector from "../components/TabSelector";
import AppTextInput from "../components/AppTextInput";
import ContainerNew from "../components/ContainerNew";

export default function UpdatePaypalIDScreen({ navigation, route }) {
    const setPaypalIDApi = useApi(usersApi.setPaypalID);

    const [paypalID, setPaypalID] = useState(route.params[1]);
    const [type, setType] = useState("paypal");
    const [bankDays, setBankDays] = useState(0);
    const [paymentAmount, setPaymentAmount] = useState(0);
    const { setMessage } = usePopUp();

    const handlePaypal = async () => {
        const response = await setPaypalIDApi.request(route.params[0]._id, paypalID, route.params[2]);
        if (response.ok) {
            navigation.goBack();
        } else {
            setMessage("Error - Contact Support");
        }
    };

    const handleBank = async () => {
        const nextPaymentDate = addDays(new Date(Date.now()), bankDays);

        if (paymentAmount <= 0) {
            setMessage("Payment amount must be greater than 0");
            return;
        }

        const response = await setPaypalIDApi.request(route.params[0]._id, "BANK", route.params[2], nextPaymentDate, paymentAmount);
        if (response.ok) {
            navigation.goBack();
        } else {
            setMessage("Error - Contact Support");
        }
    };

    return (
        <Screen loading={setPaypalIDApi.loading}>
            <Container title={route.params[2] + " Subscription"}>
                <TabSelector
                    tabs={[
                        { name: "Paypal", tabValue: "paypal" },
                        { name: "Bank", tabValue: "bank" },
                    ]}
                    onPress={(value) => setType(value.tabValue)}
                />
            </Container>
            {type == "paypal" && (
                <>
                    <Container title={"Paypal-ID"}>
                        <TextInput
                            placeholder={route.params[1]}
                            value={paypalID}
                            autoFocus={true}
                            spellCheck={false}
                            style={config.text}
                            returnKeyType="done"
                            hitSlop={{ top: 40, bottom: 40, left: 100, right: 100 }}
                            autoComplete={"email"}
                            textContentType={"emailAddress"}
                            blurOnSubmit
                            onChangeText={setPaypalID}
                            placeholderTextColor={colors.medium}
                        />
                    </Container>
                    {paypalID && <Container title="Update" icon={"check"} onPress={handlePaypal} />}
                </>
            )}
            {type == "bank" && (
                <ContainerNew style={{ gap: 8 }}>
                    <AppTextInput placeholder="Days Remaining" onChangeText={setBankDays} />
                    <AppTextInput placeholder="Payment Amount" onChangeText={setPaymentAmount} />
                    {bankDays > 0 && paymentAmount > 0 && <Container title="Update" icon={"check"} onPress={handleBank} />}
                </ContainerNew>
            )}
        </Screen>
    );
}
