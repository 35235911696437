import React, { useEffect, useState, useRef } from "react";
import { FlatList, View, RefreshControl, useWindowDimensions } from "react-native";
import * as Linking from "expo-linking";
import * as Notifications from "expo-notifications";

import useUser from "../hooks/useUser";
import useLeads from "../hooks/useLeads";
import useUpdates from "../hooks/useUpdates";

import Screen from "../components/Screen";
import ListingCard from "../components/ListingCard";
import Container from "../components/Container";
import TabSelector from "../components/TabSelector";
import leadsApi from "../api/leads";
import useApi from "../hooks/useApi";
import IconTouchable from "../components/IconTouchable";
import { ILead } from "../interfaces/lead";
import useToast from "../hooks/useToast";
import VersionIndicator from "../components/VersionIndicator";
import NetworkIndicator from "../components/NetworkIndicator";
import colors from "../config/colors";
import SubContainer from "../components/SubContainer";
import AnimatedIcon from "../components/AnimatedIcon";
import ListItem from "../components/ListItem";
import cmsApi from "../api/cms";

function ListingsScreen({ navigation }) {
    const { checkForUpdates } = useUpdates();
    const { showToast } = useToast();
    const { width } = useWindowDimensions();
    const listingsRef = useRef<FlatList>(null);

    const { user } = useUser();
    const getPreviousJobsApi = useApi(leadsApi.getPreviousJobs);
    const getCMSApi = useApi(cmsApi.getCMS);

    const [pendingJobs, setPendingJobs] = useState<ILead[]>([]);
    const [selectedType, setSelectedType] = useState("new");
    const { leads, loading, loadLeads } = useLeads();
    const [contentData, setContentData] = useState();

    const [extraData, setExtraData] = useState(false);

    const MMB_ACCOUNT_ID = "631a79abf97a8178ac41376a";

    useEffect(() => {
        setExtraData(false);

        //On Press Push Notification Router
        const subscription = Notifications.addNotificationResponseReceivedListener((response) => {
            if (response.notification.request.content.data._id) {
                navigation.navigate("ListingDetails", { leadID: response.notification.request.content.data._id });
            }

            //route admin user to chat screen
            if (response.notification.request.content.data.chatID) {
                navigation.navigate("Chat", { chatID: response.notification.request.content.data.chatID, activeUser: user.isAdmin ? MMB_ACCOUNT_ID : user._id });
            }

            //open url from push notification
            if (response.notification.request.content.data.url) {
                Linking.canOpenURL(response.notification.request.content.data.url)
                    .then((supported) => {
                        if (supported) {
                            return Linking.openURL(response.notification.request.content.data.url);
                        }
                    })
                    .catch(() => console.log("Unable to open Link"));
            }
        });
        return () => subscription.remove();
    }, []);

    useEffect(() => {
        loadLeads();
        /*   getContentData(); */
        checkForUpdates();
    }, []);

    const getContentData = async () => {
        try {
            const response = await getCMSApi.request();
            if (!response.ok) {
                return;
            }

            if (!response.data.acf) return;

            setContentData(response.data.acf);
        } catch (error) {
            console.log(error);
        }
    };

    const setupFilter = async (type: string) => {
        setPendingJobs([]);
        setSelectedType(type);

        if (type === "new") {
            return;
        }

        const response = await getPreviousJobsApi.request(type);
        if (!response.ok) {
            showToast("No Jobs Found", "error");
            return;
        }

        setPendingJobs(response.data);
    };

    return (
        <Screen loading={loading}>
            <FlatList
                ref={listingsRef}
                refreshControl={<RefreshControl onRefresh={loadLeads} refreshing={loading} />}
                data={selectedType === "new" ? leads : pendingJobs}
                keyExtractor={(item) => item._id}
                showsVerticalScrollIndicator={false}
                horizontal={false}
                numColumns={4}
                initialNumToRender={3}
                columnWrapperStyle={{ flexWrap: "wrap", flex: 1, justifyContent: "space-evenly" }}
                ListEmptyComponent={
                    <View style={{ paddingTop: 150 }}>
                        <Container
                            onPress={() => navigation.navigate("ListingFilter")}
                            title={"No Results"}
                            subTitle={"Update your search here"}
                            utility={<IconTouchable animated={false} size={40} onPress={() => navigation.navigate("ListingFilter")} name={"filter"} />}
                        />
                    </View>
                }
                ListHeaderComponent={
                    <>
                        <Container animated={false}>
                            <TabSelector
                                tabs={[
                                    { name: "New", leadType: "new" },
                                    { name: "Recent", leadType: "contacted" },
                                    { name: "Complete", leadType: "completed" },
                                ]}
                                onPress={(value) => setupFilter(value.leadType)}
                            />
                            {user?.subscriptions?.length > 0 && (
                                <>
                                    <SubContainer
                                        title={"Post a Vehicle"}
                                        subTitle={"Trade or Sell your vehicle"}
                                        icon="car"
                                        utility={
                                            <AnimatedIcon
                                                type={"confirm"}
                                                size={40}
                                                backgroundColor={colors.green}
                                                onPress={() => navigation.navigate("NewPost")}
                                                name={"plus"}
                                            />
                                        }
                                    />
                                    <SubContainer
                                        title={"Post a Recovery Request"}
                                        subTitle={"Backloads, Breakdowns, etc.."}
                                        icon={"tow-truck"}
                                        utility={
                                            <AnimatedIcon
                                                type="confirm"
                                                size={40}
                                                backgroundColor={colors.green}
                                                onPress={() => navigation.navigate("NewRecoveryPost")}
                                                name={"plus"}
                                            />
                                        }
                                    />
                                </>
                            )}
                        </Container>
                        {contentData && contentData?.listingsbanner && (
                            <ListItem style={{ margin: 8 }} title={contentData.listingsbanner} subTitle={contentData.listingsbanner_subtitle} icon="bell-alert" />
                        )}
                    </>
                }
                extraData={extraData}
                renderItem={({ item }) => (
                    <View style={{ width: width > 900 ? "25%" : "100%" }}>
                        <ListingCard lead={item} status={item.status} onPress={() => navigation.navigate("ListingDetails", { leadID: item._id })} />
                    </View>
                )}
            />
            <View style={{ position: "absolute", bottom: 20, right: 16, opacity: 0.75 }}>
                <View style={{ paddingBottom: 8 }}>
                    <AnimatedIcon size={40} type={"confirm"} onPress={() => navigation.navigate("ListingFilter")} name={"filter"} />
                </View>
                <View style={{ paddingBottom: 8 }}>
                    <AnimatedIcon size={40} type={"rotate"} onPress={loadLeads} name={"refresh"} />
                </View>
                <AnimatedIcon size={40} type={"bounce"} onPress={() => listingsRef.current.scrollToOffset({ animated: true, offset: 0 })} name={"arrow-up"} />
            </View>
            <VersionIndicator />
            <NetworkIndicator />
        </Screen>
    );
}

export default ListingsScreen;
