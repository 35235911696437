import React, { useState, useCallback, useMemo } from "react";
import { View, Pressable, StyleSheet } from "react-native";
import Animated, { useSharedValue, useAnimatedStyle, withSpring } from "react-native-reanimated";

import AppText from "./AppText";
import defaultStyles from "../config/defaultStyles";
import colors from "../config/colors";

type Tab = {
    name: string;
};

type Props = {
    tabs: Tab[];
    onPress: (tab: Tab) => void;
};

function TabSelectorNew({ tabs, onPress }: Props) {
    const translateValue = useSharedValue(0);
    const [selected, setSelected] = useState(tabs[0]);
    const [componentWidth, setComponentWidth] = useState(0);
    const tabWidth = useMemo(() => componentWidth / tabs.length, [componentWidth, tabs.length]);

    const handleSelected = useCallback(
        (index: number) => () => {
            setSelected(tabs[index]);
            onPress(tabs[index]);
            translateValue.value = withSpring((componentWidth / tabs.length) * index, { damping: 15 });
        },
        [tabs, onPress, translateValue, componentWidth]
    );

    const animatedStyle = useAnimatedStyle(() => {
        return {
            transform: [{ translateX: translateValue.value }],
        };
    });

    return (
        <View style={styles.container} onLayout={(event) => setComponentWidth(event.nativeEvent.layout.width - 12)}>
            <Animated.View style={[animatedStyle, styles.animatedView, { width: tabWidth }]} />
            {tabs.map((tab, index) => (
                <Pressable key={tab.name} onPress={handleSelected(index)}>
                    <View style={{ width: tabWidth }}>
                        <AppText style={[styles.text, selected.name == tab.name ? styles.selectedText : styles.unselectedText]}>{tab.name}</AppText>
                    </View>
                </Pressable>
            ))}
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        justifyContent: "space-evenly",
        borderRadius: defaultStyles.borderRadius,
        backgroundColor: colors.top,
        alignItems: "center",
        padding: 4,
    },
    animatedView: {
        margin: 4,
        position: "absolute",
        backgroundColor: colors.low,
        height: "100%",
        borderRadius: defaultStyles.borderRadius,
    },
    text: {
        textAlign: "center",
        padding: 4,
    },
    selectedText: {
        color: colors.white,
    },
    unselectedText: {
        color: colors.white,
        opacity: 0.7,
    },
});

export default TabSelectorNew;
