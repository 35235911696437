import React, { useEffect, useState } from "react";

import Screen from "../components/Screen";

import useApi from "../hooks/useApi";
import usePopUp from "../hooks/usePopUp";
import { FlatList, Modal, Platform, Pressable } from "react-native";
import * as Linking from "expo-linking";
import config from "../config/config";
import usersApi from "../api/users";
import useUser from "../hooks/useUser";
import { format, formatDistance } from "date-fns";
import Container from "../components/Container";
import colors from "../config/colors";
import SubContainer from "../components/SubContainer";
import Icon from "../components/Icon";
import AppTextInput from "../components/AppTextInput";
import { HeaderBackButton } from "@react-navigation/stack";
import AppText from "../components/AppText";

function SubscriptionsScreen({ navigation }) {
    const { user } = useUser();
    const { setMessage } = usePopUp();

    const [userDetails, setUserDetails] = useState();

    const [agentFeedback, setAgentFeedback] = useState("");

    const [selectedSubscription, setSelectedSubscription] = useState("");
    const [selectedSubType, setSelectedSubType] = useState("");

    const [cancelSubModalVisible, setCancelSubModalVisible] = useState(false);

    const getUserApi = useApi(usersApi.getUser);
    const cancelPaypalSubscriptionApi = useApi(usersApi.cancelPayPalSubscription);

    useEffect(() => {
        getUserDetails();
    }, []);

    const getUserDetails = async () => {
        const response = await getUserApi.request(user._id);
        if (response.ok) {
            setUserDetails(response.data);
        }
    };

    const handleGetFeedback = async (subscriptionID, subType) => {
        if (!subscriptionID) {
            setMessage("No Active Paypal ID found, unable to cancel subscription\n\n Please contact support");
            return;
        }

        if (!subscriptionID == "BANK") {
            setMessage("You can only cancel a bank subscription manually, Please contact support");
            return;
        }

        setSelectedSubscription(subscriptionID);
        setSelectedSubType(subType);

        setCancelSubModalVisible(true);
    };

    const handleCancelSubscription = async () => {
        const response = await cancelPaypalSubscriptionApi.request(selectedSubscription, selectedSubType, user._id, agentFeedback);
        if (response.ok) {
            setMessage("Paypal Subscription Cancelled, your account has been updated");
            getUserDetails();
        } else {
            setMessage("Subscription Cancel Failed, Please contact support");
        }

        setCancelSubModalVisible(false);
    };

    return (
        <Screen loading={getUserApi.loading}>
            {userDetails && (
                <FlatList
                    data={config.subscriptions.filter((subscription) => userDetails.subscriptions.includes(subscription.name))}
                    keyExtractor={(item) => item.name}
                    horizontal={false}
                    ListHeaderComponent={
                        <Container
                            title={"Add Subscription Plan"}
                            subTitle={"Click here to add a recurring subscription plan"}
                            icon={"plus"}
                            onPress={() => navigation.navigate("NewSubscription")}
                            borderColor={colors.green}
                        />
                    }
                    renderItem={({ item }) =>
                        userDetails[item.subscriptionObject]?.id && (
                            <Container animated={false} key={item.name} title={item.nameFormatted} icon={item.iconName}>
                                <SubContainer title={"Status"} subTitle={userDetails.subscriptions.includes(item.name) ? "Active" : "Inactive"} />
                                <SubContainer
                                    title={
                                        userDetails[item.subscriptionObject]?.lastPaymentAmount
                                            ? "Last Payment" + "   £" + userDetails[item.subscriptionObject]?.lastPaymentAmount
                                            : "Last Payment"
                                    }
                                    subTitle={
                                        userDetails[item.subscriptionObject]?.lastPaymentDate &&
                                        format(new Date(userDetails[item.subscriptionObject]?.lastPaymentDate), "dd-MM-yyyy") +
                                            "  -  " +
                                            formatDistance(new Date(userDetails[item.subscriptionObject]?.lastPaymentDate), new Date(), {
                                                addSuffix: true,
                                            })
                                    }
                                />
                                <SubContainer
                                    title={"Next Payment Due"}
                                    subTitle={
                                        userDetails[item.subscriptionObject]?.nextPaymentDate &&
                                        format(new Date(userDetails[item.subscriptionObject]?.nextPaymentDate), "dd-MM-yyyy") +
                                            "  -  " +
                                            formatDistance(new Date(userDetails[item.subscriptionObject]?.nextPaymentDate), new Date(), {
                                                addSuffix: true,
                                            })
                                    }
                                />
                                {userDetails[item.subscriptionObject]?.id && userDetails[item.subscriptionObject]?.status == "ACTIVE" && (
                                    <SubContainer
                                        title={"Cancel Subscription"}
                                        icon={"trash-can"}
                                        iconColor={colors.red}
                                        iconBackgroundColor={colors.light}
                                        onPress={() => handleGetFeedback(userDetails[item.subscriptionObject]?.id, item.name)}
                                        utility={<Icon iconColor={colors.red} name={"arrow-right"} size={32} backgroundColor={colors.light} />}
                                    />
                                )}
                            </Container>
                        )
                    }
                />
            )}
            <Modal animationType="fade" visible={cancelSubModalVisible} transparent={false}>
                <Screen>
                    <Pressable style={{ flexDirection: "row", alignItems: "center" }} onPress={() => setCancelSubModalVisible(false)}>
                        <HeaderBackButton tintColor={colors.red} />
                        <AppText style={{ color: colors.white }}>Back</AppText>
                    </Pressable>
                    <Container
                        icon={"alert-circle"}
                        iconColor={colors.green}
                        animated={false}
                        subTitle={
                            "We regularly use feedback to improve our services, if you have been unhappy with your experience please let our support teams know through the app to see how we could help "
                        }
                    >
                        <Container
                            title={"Contact Support"}
                            icon={"chat"}
                            onPress={() => {
                                setCancelSubModalVisible(false);
                                navigation.navigate("MyChats");
                            }}
                        />
                        <Container
                            subTitle={"View our help section"}
                            onPress={() => {
                                if (Platform.OS == "web") {
                                    window.open("https://makemebusy.com/how-it-works", "_blank");
                                } else {
                                    Linking.openURL("https://makemebusy.com/how-it-works");
                                }
                            }}
                            icon={"help-circle-outline"}
                            title={"How it Works / Getting Started"}
                        />
                    </Container>
                    <Container iconColor={colors.green} animated={false} subTitle={"Please take a moment to let us know how we can improve"}>
                        <AppTextInput onChangeText={setAgentFeedback} multiline={true} />
                    </Container>

                    {agentFeedback && <Container title={"Cancel Subscription"} icon={"check"} onPress={() => handleCancelSubscription()} />}
                </Screen>
            </Modal>
        </Screen>
    );
}

export default SubscriptionsScreen;
