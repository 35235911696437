import React, { useEffect, useState } from "react";
import { View, FlatList } from "react-native";

import Screen from "../components/Screen";
import useApi from "../hooks/useApi";
import leadsApi from "../api/leads";
import ListingCard from "../components/ListingCard";
import usePopUp from "../hooks/usePopUp";

function PremiumSalvageScreen({ navigation }) {
    const getPremiumSalvageLeadsApi = useApi(leadsApi.getPremiumSalvageLeads);
    const { setMessage } = usePopUp();

    const [leads, setLeads] = useState([]);

    useEffect(() => {
        getPremiumSalvageLeads();
    }, []);

    const getPremiumSalvageLeads = async () => {
        const response = await getPremiumSalvageLeadsApi.request();
        if (!response.ok) {
            setMessage("No Leads Found");
            return;
        }

        setLeads(response.data);
    };

    return (
        <Screen loading={getPremiumSalvageLeadsApi.loading}>
            <View style={{ flex: 1 }}>
                <FlatList
                    data={leads}
                    keyExtractor={(item) => item._id}
                    showsVerticalScrollIndicator={true}
                    renderItem={({ item }) => (
                        <ListingCard lead={item} status={item.status} onPress={() => navigation.navigate("ListingDetails", { leadID: item._id })} showDetails={true} />
                    )}
                />
            </View>
        </Screen>
    );
}

export default PremiumSalvageScreen;
