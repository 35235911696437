import React from "react";
import { StyleSheet, Text } from "react-native";

import colors from "../config/colors";

export default function AppText({ children, style = {}, ...otherProps }) {
    return (
        <Text style={[styles.text, style]} maxFontSizeMultiplier={1.05} {...otherProps}>
            {children}
        </Text>
    );
}

const styles = StyleSheet.create({
    text: {
        color: colors.black,
        fontSize: 18,
        fontFamily: "SofiaProRegular",
    },
});
