import React, { useState } from "react";
import Screen from "../components/Screen";
import { ScrollView } from "react-native";
import config from "../config/config";
import Container from "../components/Container";
import useLeads from "../hooks/useLeads";
import AppSwitch from "../components/AppSwitch";
import Slider from "@react-native-community/slider";
import colors from "../config/colors";

function ListingFilterScreen({ navigation }) {
    const { toggleLeadsTypeFilter, leadTypeFilters, loading, setLeadRangeFilter, leadDistanceFilter } = useLeads();
    const [filterDistanceText, setFilterDistanceText] = useState(leadDistanceFilter);

    return (
        <Screen loading={loading}>
            <ScrollView style={{ margin: 8 }}>
                <Container
                    title={"Set Range"}
                    subTitle={filterDistanceText > 99 ? "Any Distance" : filterDistanceText + " Miles"}
                    icon={"map-marker"}
                    utility={
                        <Slider
                            maximumValue={100}
                            value={leadDistanceFilter}
                            onSlidingComplete={(value) => setLeadRangeFilter(value)}
                            onValueChange={(value) => setFilterDistanceText(value)}
                            step={5}
                            minimumTrackTintColor={colors.tertiary}
                            minimumValue={5}
                            maximumTrackTintColor={colors.tertiary}
                            thumbTintColor={colors.red}
                            style={{ width: 160, height: 40 }}
                        />
                    }
                />
                {config.subscriptions.map((subscription) => {
                    return (
                        <Container
                            key={subscription.name}
                            title={subscription.nameFormatted}
                            icon={subscription.iconName}
                            onPress={() => toggleLeadsTypeFilter(subscription.name)}
                            utility={<AppSwitch value={leadTypeFilters.includes(subscription.name)} onChange={() => toggleLeadsTypeFilter(subscription.name)} />}
                        />
                    );
                })}
            </ScrollView>
        </Screen>
    );
}

export default ListingFilterScreen;
