import React from "react";
import { View, Pressable, StyleSheet } from "react-native";

import AppText from "./AppText";
import colors from "../config/colors";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import defaultStyles from "../config/defaultStyles";

type Props = {
    onPress?: any;
    children?: any;
    title?: string;
    titleColor?: string;
    subTitle?: string;
    subTitleColor?: string;
    icon?: keyof typeof MaterialCommunityIcons.glyphMap;
    backgroundColor?: string;
    iconColor?: string;
    toolTip?: string;
    badge?: string;
    utility?: any;
    iconBackgroundColor?: string;
    enabled?: boolean;
    animated?: boolean;
    onLongPress?: any;
    borderColor?: string;
    style?: object;
};

export default function ContainerNew({ onPress, style, title, titleColor = colors.white, subTitle, subTitleColor = colors.light, icon, onLongPress, children }: Props) {
    return (
        <Pressable onPress={onPress} onLongPress={onLongPress} style={[styles.container, style]}>
            <View style={{ flexDirection: "row", alignItems: "center", marginBottom: 8 }}>
                <View style={{ flex: 1 }}>
                    {title && <AppText style={{ color: titleColor }}>{title}</AppText>}
                    {subTitle && <AppText style={{ color: subTitleColor }}>{subTitle}</AppText>}
                </View>
                {icon && <MaterialCommunityIcons name={icon} size={20} color={colors.white} />}
            </View>
            {children}
        </Pressable>
    );
}

const styles = StyleSheet.create({
    container: {
        padding: 12,
        borderRadius: defaultStyles.borderRadius,
        maxWidth: 600,
        backgroundColor: colors.mid,
    },
});
