import client from "./client";

const getPaymentIntent = (amount: number) => client.post("/payments/paymentIntent", { amount });
const getTransactions = () => client.get("/payments/transactions");
const createCheckout = (type: string) => client.post("/payments/create-checkout-session", { type });

export default {
    getPaymentIntent,
    getTransactions,
    createCheckout,
};
