import { useEffect } from "react";
import useApi from "./useApi";
import usersApi from "../api/users";
import { addScreenshotListener } from "expo-screen-capture";
import { Platform } from "react-native";
import { requestPermissionsAsync } from "expo-media-library";

const useScreenshot = (screenName: string) => {
    const screenshotDetectedApi = useApi(usersApi.screenshotDetected);

    useEffect(() => {
        if (Platform.OS === "web") return;

        let subscription;

        const handleScreenshot = async () => {
            try {
                await screenshotDetectedApi.request(screenName);
            } catch (error) {
                console.log(error.message);
            }
        };

        const init = async () => {
            try {
                const { status } = await requestPermissionsAsync();
                if (status === "granted") {
                    subscription = addScreenshotListener(() => {
                        handleScreenshot();
                    });
                }
            } catch (error) {
                console.log(error.message);
            }
        };

        init();

        return () => {
            if (subscription) {
                subscription.remove();
            }
        };
    }, [screenName]);
};

export default useScreenshot;
