import React from "react";
import { View, Pressable, PressableProps, StyleSheet } from "react-native";

import AppText from "./AppText";
import colors from "../config/colors";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import defaultStyles from "../config/defaultStyles";

interface Props extends PressableProps {
    title: string;
    subTitle?: string;
    icon?: keyof typeof MaterialCommunityIcons.glyphMap;
    iconRight?: keyof typeof MaterialCommunityIcons.glyphMap;
    style?: object;
    iconRightColor?: string;
}

export default function ListItem({ title, subTitle, icon, iconRight, iconRightColor = colors.white, style, ...otherProps }: Props) {
    return (
        <Pressable style={({ pressed }) => [styles.container, style, { backgroundColor: pressed ? colors.top : colors.mid }]} {...otherProps}>
            {icon && <MaterialCommunityIcons name={icon || "account"} size={32} color={colors.white} style={{ paddingLeft: 8 }} />}
            <View style={{ paddingLeft: 8, borderColor: colors.white, flex: 1 }}>
                <AppText style={{ color: colors.white }}>{title}</AppText>
                {subTitle && <AppText style={{ fontSize: 16, color: colors.light }}>{subTitle}</AppText>}
            </View>

            {iconRight && <MaterialCommunityIcons name={iconRight || "chevron-right"} size={20} color={iconRightColor} />}
        </Pressable>
    );
}

const styles = StyleSheet.create({
    container: {
        borderRadius: defaultStyles.borderRadius,
        padding: 4,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
    },
});
