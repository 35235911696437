import React, { useState } from "react";

import useApi from "../hooks/useApi";
import usePopUp from "../hooks/usePopUp";

import { ScrollView } from "react-native";

import stockApi from "../api/stock";

import Container from "../components/Container";
import Screen from "../components/Screen";
import { FlatList, Image, Pressable, View } from "react-native";
import { callNumber } from "../utility/contactUser";
import colors from "../config/colors";
import AppText from "../components/AppText";
import leadsApi from "../api/leads";

function BrowseStockScreen({ navigation }) {
    const { setMessage } = usePopUp();

    const [selectedAgent, setSelectedAgent] = useState(null);

    const [buttonClicked, setButtonClicked] = useState(false);

    const [stock, setStock] = useState([]);
    const [uniqueModels, setUniqueModels] = useState([]);
    const [uniqueMakes, setUniqueMakes] = useState([]);

    const [leadID, setLeadID] = useState(""); //if the user is browsing stock from a lead, this will be the leadID

    const [phone, setPhone] = useState("");
    const [landline, setLandline] = useState("");

    const [selectedMake, setSelectedMake] = useState("");
    const [selectedModel, setSelectedModel] = useState("");

    const addContactedAgentApi = useApi(leadsApi.addContactedAgent);
    const getAvailableStockApi = useApi(stockApi.getAvailableStock);
    const browseLeadApi = useApi(leadsApi.browseLead);

    const handleButtonPress = async () => {
        getAvailableStock();
        const response = await browseLeadApi.request();
        if (!response.ok) {
            setMessage(response.data);
            return;
        }

        setButtonClicked(true);
        setLeadID(response.data);
    };

    const getAvailableStock = async () => {
        const response = await getAvailableStockApi.request();
        if (!response.ok) {
            setMessage(response.data);
            return;
        }

        const uniqueMakes = [];

        //order alphabetically by make
        response.data.sort((a, b) => (a.make > b.make ? 1 : b.make > a.make ? -1 : 0));

        response.data.forEach((stockItem) => {
            if (!uniqueMakes.includes(stockItem.make)) {
                uniqueMakes.push(stockItem.make);
            }
        });

        const randomMakeIndex = Math.floor(Math.random() * uniqueMakes.length);
        setUniqueMakes(uniqueMakes);
        setSelectedMake(uniqueMakes[randomMakeIndex]);

        //random whole number between 0 and the number of unique makes

        //get the available models for the selected make
        const uniqueModels = [];

        response.data.forEach((stockItem) => {
            if (stockItem.make == uniqueMakes[randomMakeIndex]) {
                if (!uniqueModels.includes(stockItem.model)) {
                    uniqueModels.push(stockItem.model);
                }
            }
        });

        setUniqueModels(uniqueModels);
        setSelectedModel(uniqueModels[0]);

        setStock(response.data);
    };

    const getAvailableModels = async (make) => {
        setSelectedMake(make);
        const uniqueModels = [];

        stock.forEach((stockItem) => {
            if (stockItem.make == make) {
                if (!uniqueModels.includes(stockItem.model)) {
                    uniqueModels.push(stockItem.model);
                }
            }
        });

        //order alphabetically by model
        uniqueModels.sort((a, b) => (a > b ? 1 : b > a ? -1 : 0));

        setUniqueModels(uniqueModels);
        setSelectedModel(uniqueModels[0]);
    };

    const handleSetSelectedAgent = async (agentID) => {
        const response = await addContactedAgentApi.request(agentID, leadID);
        if (response.ok) {
            setSelectedAgent(agentID);
            setPhone(response.data.phone);
            setLandline(response.data.landline);
        } else {
            setMessage("Something went wrong");
        }
    };

    const handleCallAgent = async (number) => {
        callNumber(number);
    };

    return (
        <Screen backgroundColor={colors.white}>
            {buttonClicked ? (
                <>
                    <Container animated={false} icon={"car"} title={"Make"} subTitle={selectedMake} onPress={() => setSelectedMake("")}>
                        <ScrollView>
                            {uniqueMakes.length > 0 &&
                                selectedMake == "" &&
                                uniqueMakes.map((make, index) => <Container key={index} title={make} onPress={() => getAvailableModels(make)} />)}
                        </ScrollView>
                    </Container>
                    <Container animated={false} icon={"car"} title={"Model"} subTitle={selectedModel} onPress={() => setSelectedModel("")}>
                        <ScrollView>
                            {uniqueModels.length > 0 &&
                                selectedModel == "" &&
                                uniqueModels.map((model, index) => <Container key={index} title={model} onPress={() => setSelectedModel(model)} />)}
                        </ScrollView>
                    </Container>

                    <FlatList
                        data={stock.filter((item) => item.make == selectedMake && item.model == selectedModel)}
                        keyExtractor={(item) => item._id}
                        showsVerticalScrollIndicator={false}
                        /*   ListEmptyComponent={<Container animated={false} title={"No results found"} subTitle={"Click here to view our stock"} icon={"store"}></Container>} */
                        renderItem={({ item }) => (
                            <Container
                                animated={false}
                                title={item.year + " " + item.make + " " + item.model + " " + item.variant}
                                subTitle={"Engine: " + item.engineCode + " " + item.engineSize + "\nTransmission: " + item.transmissionType}
                            >
                                <Pressable
                                    style={{
                                        alignItems: "center",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                    }}
                                    onPress={() => {
                                        if (item.images?.length > 0) {
                                            navigation.navigate("ViewImages", { images: item.images });
                                        }
                                    }}
                                >
                                    {item.images.map((image, index) => (
                                        <Image key={index} style={{ flex: 1, height: 100 }} source={{ uri: image }} resizeMode={"contain"} />
                                    ))}
                                </Pressable>
                                <Container
                                    animated={false}
                                    title={item.ownerID.name}
                                    subTitle={selectedAgent != item.ownerID._id && "Tap to view phone number"}
                                    backgroundColor={selectedAgent == item.ownerID._id ? colors.honeyComb : colors.white}
                                    icon={"account"}
                                    onPress={() => handleSetSelectedAgent(item.ownerID._id)}
                                >
                                    {selectedAgent == item.ownerID._id && (
                                        <View style={{ paddingTop: 8 }}>
                                            <AppText onPress={() => handleCallAgent(phone)}>{"Mobile: " + phone}</AppText>
                                            {landline && (
                                                <AppText style={{ paddingTop: 8 }} onPress={() => handleCallAgent(landline)}>
                                                    {"Landline: " + landline}
                                                </AppText>
                                            )}
                                        </View>
                                    )}
                                </Container>
                            </Container>
                        )}
                    />
                </>
            ) : (
                <Container animated={false} icon={"store"} title={"Click Here To Browse Our Stock"} onPress={handleButtonPress} />
            )}
        </Screen>
    );
}

export default BrowseStockScreen;
