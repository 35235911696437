import React from "react";

import { IUser } from "../interfaces/user";

interface UserContextProps {
    user: IUser | null;
    setUser: React.Dispatch<React.SetStateAction<IUser | null>>;
}

const UserContext = React.createContext<UserContextProps>({
    user: null,
    setUser: () => {},
});

export { UserContext };
