import { Platform } from "react-native";
import * as Linking from "expo-linking";

import * as Device from "expo-device";
import { ILead } from "interfaces/lead";

const useContact = () => {
    const setupMessage = (lead: ILead) => {
        let message = " ";

        if (lead.type == "vehicleRecovery") {
            message = `Hi, This is your local Cheap Car Recovery agent, would you like to book in the recovery of ${lead.vehicle.registration} today?`;
        }

        if (["vehicleSalvage", "vehicleScrap", "premiumSalvage"].includes(lead.type)) {
            message = `Hi, This is your local Car Salvage Group agent, would you like to book in ${lead.vehicle.registration} today?`;
        }

        return message;
    };

    const callNumber = async (phoneNumber: string) => {
        try {
            let phoneLink = `tel:${phoneNumber}`;

            //check if iframe
            if (window.self !== window.top) {
                if (navigator && navigator.userAgent) {
                    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
                    if (isSafari) {
                        phoneLink = `telprompt:${phoneNumber}`;
                    }
                }
            }

            return await Linking.openURL(phoneLink);
        } catch (error) {
            return false;
        }
    };

    const sendWhatsapp = async (phoneNumber: string, lead: object) => {
        var message = "";
        if (lead) {
            message = setupMessage(lead);
        }

        let WhatsAppLink = `whatsapp://send?text=${message}&phone=+44${phoneNumber}`;

        try {
            return await Linking.openURL(WhatsAppLink);
        } catch (error) {
            return false;
        }
    };

    const sendSMS = async (phoneNumber: string, lead: object) => {
        var message = "";

        if (lead) {
            message = setupMessage(lead);
        }

        //Seems to require URL encoded strings to work on all devices ()
        message = encodeURIComponent(message);

        var smsLink = "";
        if (Platform.OS === "ios") {
            smsLink = `sms:${phoneNumber}&body=${message}`;
        }

        if (Platform.OS === "android") {
            smsLink = `sms:${phoneNumber}?body=${message}`;
        }

        if (Platform.OS == "web") {
            smsLink = `sms:${phoneNumber}`;

            if (Device.osName == "iOS") {
                smsLink = `sms:${phoneNumber}&body=${message}`;
            }

            if (Device.osName == "Android") {
                smsLink = `sms:${phoneNumber}?body=${message}`;
            }
        }

        if (!smsLink) {
            return;
        }

        try {
            return await Linking.openURL(smsLink);
        } catch (error) {
            return false;
        }
    };

    return { callNumber, sendWhatsapp, sendSMS };
};

export default useContact;
