import React, { useState } from "react";
import { View, Image, TextInput } from "react-native";

import authStorage from "../../auth/storage";

import authApi from "../../api/auth";

import useApi from "../../hooks/useApi";

import Screen from "../../components/Screen";
import usePopUp from "../../hooks/usePopUp";
import AppText from "../../components/AppText";
import Container from "../../components/Container";
import colors from "../../config/colors";
import config from "../../config/config";
import SubContainer from "../../components/SubContainer";
import * as Font from "expo-font";
import AppActivityIndicator from "../../components/AppActivityIndicator";

function CustomerLoginScreen({ navigation, route }) {
    const customerLoginApi = useApi(authApi.customerLogin);

    const { setMessage } = usePopUp();
    const [pin, setPin] = useState(route?.params?.pin);
    const [id, setId] = useState(route?.params?.id);

    const [fontsLoaded] = Font.useFonts({
        SofiaProRegular: require("../../assets/fonts/Sofia-Pro-Regular.otf"),
    });

    const handleLogin = async () => {
        const response = await customerLoginApi.request(id, pin);
        if (!response.ok) {
            setMessage(response.data);
            return;
        }

        authStorage.storeToken(response.data);
        navigation.navigate("CustomerTabNavigator");
    };

    if (!fontsLoaded) return <AppActivityIndicator />;

    return (
        <Screen centered>
            <Image resizeMode="contain" style={{ height: 140, width: 340, marginBottom: 40, alignSelf: "center" }} source={require("../../assets/logo-busybee.png")} />
            <AppText style={{ fontWeight: "900", color: colors.white, paddingBottom: 40, textAlign: "center" }}>Login using the SMS code we sent you</AppText>
            <AppText style={{ fontWeight: "900", color: colors.white, paddingBottom: 40, textAlign: "center" }}>{"ID: " + id}</AppText>
            <View style={{ paddingBottom: 16 }} />
            <SubContainer>
                <TextInput
                    placeholder={"Enter Code"}
                    onChangeText={setPin}
                    keyboardType="number-pad"
                    value={pin}
                    autoFocus={true}
                    autoComplete={"sms-otp"}
                    textContentType={"oneTimeCode"}
                    spellCheck={false}
                    style={config.text}
                    hitSlop={{ top: 40, bottom: 40, left: 100, right: 100 }}
                    placeholderTextColor={colors.medium}
                />
            </SubContainer>
            <View style={{ paddingBottom: 16 }} />
            <Container title="Manage My Request" icon={"check"} enabled={pin} onPress={() => handleLogin()} />
        </Screen>
    );
}

export default CustomerLoginScreen;
