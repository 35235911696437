import React from "react";
import { View, Pressable } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import Animated, { useSharedValue, withSpring, useAnimatedStyle, interpolate, withTiming } from "react-native-reanimated";
import colors from "../config/colors";

interface Props {
    type: "confirm" | "rotate" | "bounce";
    name: any;
    size?: number;
    backgroundColor?: string;
    iconColor?: string;
    onPress?: any;
}

const AnimatedIcon: React.FC<Props> = ({ type, name, size = 40, backgroundColor = colors.tertiary, iconColor = colors.white, onPress = () => {} }) => {
    const translate = useSharedValue(0);

    const animate = () => {
        "worklet";
        if (type === "rotate") {
            onPress();
            translate.value = withSpring(270);
            setTimeout(() => {
                translate.value = withTiming(0);
            }, 500);
            return;
        }
        if (type === "confirm") {
            translate.value = withSpring(size); // animate down by the size of the icon
            setTimeout(() => {
                translate.value = withTiming(0); // reset the position after the animation
                onPress();
            }, 500);
        }

        if (type === "bounce") {
            onPress();
            translate.value = withSpring(size / 5, { damping: 4 }); // animate down by the size of the icon
            setTimeout(() => {
                translate.value = withTiming(0); // reset the position after the animation
            }, 1000);
        }
    };

    const animatedIcon = useAnimatedStyle(() => {
        if (type === "rotate") {
            return {
                transform: [{ rotate: `${translate.value}deg` }],
            };
        }

        if (type === "bounce") {
            return {
                transform: [{ translateY: -translate.value }],
            };
        }

        return {
            transform: [{ translateX: translate.value }],
        };
    });

    function hexToRgb(hex: string) {
        "worklet";
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result
            ? {
                  r: parseInt(result[1], 16),
                  g: parseInt(result[2], 16),
                  b: parseInt(result[3], 16),
              }
            : null;
    }

    const animatedContainer = useAnimatedStyle(() => {
        //get the rgb values of the background colour
        const tertiary = hexToRgb(backgroundColor);
        const honeyComb = hexToRgb(colors.red);

        //animate background colour
        const r = interpolate(translate.value, [0, 5], [tertiary.r, honeyComb.r]);
        const g = interpolate(translate.value, [0, 5], [tertiary.g, honeyComb.g]);
        const b = interpolate(translate.value, [0, 5], [tertiary.b, honeyComb.b]);
        const animatedColor = `rgb(${r}, ${g}, ${b})`;

        return {
            backgroundColor: animatedColor,
            borderColor: colors.silver,
            flexDirection: "row",
            borderWidth: 2,
            width: size,
            height: size,
            borderRadius: size / 2,
            overflow: "hidden",
            alignItems: "center",
            justifyContent: "center",
        };
    });

    return (
        <Pressable onPress={animate}>
            <Animated.View style={animatedContainer}>
                <Animated.View style={animatedIcon}>
                    <View style={{ position: "absolute", left: -size }}>
                        <MaterialCommunityIcons name={"check"} color={iconColor} size={size * 0.5} />
                    </View>
                    <MaterialCommunityIcons name={name} color={iconColor} size={size * 0.5} />
                </Animated.View>
            </Animated.View>
        </Pressable>
    );
};

export default AnimatedIcon;
