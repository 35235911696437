import React, { useState } from "react";
import { FlatList, View } from "react-native";

import Screen from "../components/Screen";
import Container from "../components/Container";
import usersApi from "../api/users";
import useApi from "../hooks/useApi";
import usePopUp from "../hooks/usePopUp";
import TabSelector from "../components/TabSelector";
import IconTouchable from "../components/IconTouchable";
import UserCardReskin from "../components/UserCardReskin";
import colors from "../config/colors";
import { format, isValid } from "date-fns";
import useToast from "../hooks/useToast";
import AppTextInput from "../components/AppTextInput";

export default function UsersScreen({ navigation }) {
    const { setMessage } = usePopUp();
    const { showToast } = useToast();

    const [searchTerm, setSearchTerm] = useState("");
    const [tabValue, setTabValue] = useState("search");

    const getMismatchUsersApi = useApi(usersApi.getMismatchUsers);
    const getBankUsersApi = useApi(usersApi.getBankUsers);
    const getBlockedItemsApi = useApi(usersApi.getBlockedItems);
    const removeBlockedItem = useApi(usersApi.removeBlockedItem);
    const getAllUsersApi = useApi(usersApi.getUsers);
    const userSearchApi = useApi(usersApi.userSearch);

    const [selectedUsers, setSelectedUsers] = useState([]);
    const [blockedItems, setBlockedItems] = useState([]);

    const handleChangeFilter = async (filter: string) => {
        setTabValue(filter);
        if (filter == "expired") {
            const response = await getMismatchUsersApi.request();
            if (!response.ok) {
                setMessage("No Users Found");
                return;
            }

            setSelectedUsers(response.data);
            return;
        }

        if (filter == "bank") {
            const response = await getBankUsersApi.request();
            if (!response.ok) {
                setMessage("No Users Found");
                return;
            }
            setSelectedUsers(response.data);
            return;
        }

        if (filter == "blocked") {
            const response = await getBlockedItemsApi.request("phone");
            if (!response.ok) {
                setMessage("No Users Found");
                return;
            }
            setBlockedItems(response.data);
            return;
        }
    };

    const handleGetBlockedItems = async (type: string) => {
        const response = await getBlockedItemsApi.request(type);
        if (!response.ok) {
            setMessage("No Users Found");
            return;
        }
        setBlockedItems(response.data);
        return;
    };
    const handleUnblock = async (itemID: string) => {
        const response = await removeBlockedItem.request(itemID);
        if (!response.ok) {
            setMessage("No Users Found");
            return;
        }

        //remove item from list
        const newList = blockedItems.filter((item) => item._id !== itemID);
        setBlockedItems(newList);

        showToast(itemID + " Unblocked", "success");

        return;
    };

    const handleSearch = async () => {
        if (!searchTerm) return;

        setTabValue("bank");

        const response = await userSearchApi.request(searchTerm);
        if (!response.ok) {
            setSelectedUsers([]);
            return;
        }

        setSelectedUsers(response.data);
    };

    return (
        <Screen loading={getAllUsersApi.loading || getBankUsersApi.loading || getMismatchUsersApi.loading}>
            <Container animated={false}>
                <View style={{ paddingBottom: 4, flexDirection: "row" }}>
                    <View style={{ flex: 1, paddingRight: 8 }}>
                        <AppTextInput icon={"magnify"} placeholder={"Name, Email, Phone, Paypal ID"} onChangeText={setSearchTerm} />
                    </View>
                    <IconTouchable name={"account-search"} size={32} onPress={() => handleSearch()} />
                </View>
                <TabSelector
                    tabs={[{ name: "bank" }, { name: "expired" }, { name: "blocked" }]}
                    onPress={(value: { name: string; value: string }) => handleChangeFilter(value.name)}
                />
                {tabValue == "blocked" && (
                    <View style={{ paddingTop: 8 }}>
                        <TabSelector
                            tabs={[{ name: "phone" }, { name: "email" }, { name: "device" }]}
                            onPress={(value: { name: string; value: string }) => handleGetBlockedItems(value.name)}
                        />
                    </View>
                )}
            </Container>

            {tabValue == "blocked" ? (
                <FlatList
                    data={blockedItems}
                    keyExtractor={(item) => item._id}
                    showsVerticalScrollIndicator={true}
                    renderItem={({ item }) => (
                        <Container
                            title={item._id + "\nReason: " + item.reason}
                            subTitle={isValid(new Date(item.createdDate)) && format(new Date(item.createdDate), "dd-MM-yyyy")}
                            utility={<IconTouchable iconColor={colors.red} size={40} name={"trash-can"} onPress={() => handleUnblock(item._id)} />}
                        />
                    )}
                />
            ) : (
                <FlatList
                    data={selectedUsers}
                    keyExtractor={(item) => item._id}
                    showsVerticalScrollIndicator={true}
                    renderItem={({ item }) => <UserCardReskin user={item} onPress={() => navigation.navigate("UserDetails", { userID: item._id })} />}
                />
            )}
        </Screen>
    );
}
