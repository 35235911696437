import React from "react";
import { TextInput, StyleSheet, TextInputProps, View } from "react-native";
import config from "../config/config";
import defaultStyles from "../config/defaultStyles";
import colors from "../config/colors";
import { MaterialCommunityIcons } from "@expo/vector-icons";

interface AppTextInputProps extends TextInputProps {
    icon?: keyof typeof MaterialCommunityIcons.glyphMap;
    rightIcon?: keyof typeof MaterialCommunityIcons.glyphMap;
    onPressRightIcon?: () => void;
}

export default function AppTextInput({ icon, rightIcon, onPressRightIcon, style, ...props }: AppTextInputProps) {
    return (
        <View style={styles.container}>
            {icon && <MaterialCommunityIcons name={icon} size={20} color={colors.white} />}
            <TextInput
                spellCheck={false}
                style={[config.text, styles.input, style]}
                placeholderTextColor={colors.light}
                returnKeyType="done"
                hitSlop={{ top: 16, bottom: 16, left: 40, right: 40 }}
                selectionColor={colors.red}
                cursorColor={colors.red}
                {...props}
            />
            {rightIcon && <MaterialCommunityIcons name={rightIcon} size={20} color={colors.white} onPress={onPressRightIcon} />}
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        alignItems: "center",
        paddingVertical: 8,
        paddingHorizontal: 12,
        backgroundColor: colors.top,
        maxWidth: 600,
        borderRadius: defaultStyles.borderRadius,
    },
    input: {
        paddingHorizontal: 12,
        color: colors.white,
        flex: 1,
    },
});
