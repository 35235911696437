import React, { useState } from "react";

import Screen from "../components/Screen";
import useApi from "../hooks/useApi";
import usersApi from "../api/users";
import AppTextInput from "../components/AppTextInput";
import usePopUp from "../hooks/usePopUp";
import Container from "../components/Container";
import { useNavigation } from "@react-navigation/native";

export default function ChangePasswordScreen() {
    const { setMessage } = usePopUp();
    const setPasswordApi = useApi(usersApi.setPassword);
    const navigation = useNavigation();

    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");

    const handleSubmit = async () => {
        if (!password) return setMessage("Please enter your previous password");
        if (!newPassword) return setMessage("Please enter your new password");

        const response = await setPasswordApi.request(password, newPassword);
        if (!response.ok) {
            setMessage("Error changing password, please contact support");
            return;
        }
        setMessage("Password Updated");
        navigation.goBack();
    };

    return (
        <Screen>
            <Container title={"Previous Password"}>
                <AppTextInput placeholder="Enter Previous Password" onChangeText={setPassword} />
            </Container>
            <Container title={"New Password"}>
                <AppTextInput placeholder="Enter New Password" onChangeText={setNewPassword} />
            </Container>
            {password && newPassword ? <Container icon={"check"} title="Update" onPress={() => handleSubmit()} /> : null}
        </Screen>
    );
}
