import React from "react";
import { SafeAreaView, Platform, View, useWindowDimensions, StatusBar } from "react-native";
import Constants from "expo-constants";

import AppActivityIndicator from "./AppActivityIndicator";
import colors from "../config/colors";

interface Props {
    children: any;
    loading?: boolean;
    backgroundColor?: string;
    centered?: boolean;
    style?: object;
}

const Screen: React.FC<Props> = ({ children, loading = false, backgroundColor = colors.low, centered = false, style }) => {
    const { width } = useWindowDimensions();
    const isBigScreen = width >= 768;

    let paddingTop = 0;

    if (isBigScreen) {
        paddingTop = 48;
    }

    return (
        <>
            {loading && <AppActivityIndicator />}
            <SafeAreaView
                style={{
                    paddingTop: isBigScreen ? 48 : 0,
                    backgroundColor: backgroundColor,
                    flex: 1,
                }}
            >
                <View style={[style && style, { flex: 1 }, centered && { alignSelf: "center" }]}>{children}</View>
                <StatusBar barStyle={"light-content"} backgroundColor={colors.low} />
            </SafeAreaView>
        </>
    );
};

export default Screen;
