import React, { useState, useEffect, createContext, ReactNode } from "react";
import NetInfo, { NetInfoState } from "@react-native-community/netinfo";

interface NetworkState {
    isConnected: boolean | null;
    isInternetReachable: boolean | null;
}

interface NetworkContextType {
    networkState: NetworkState;
}

const NetworkContext = createContext<NetworkContextType | null>(null);

const NetworkProvider = ({ children }: { children: ReactNode }) => {
    const [networkState, setNetworkState] = useState<NetworkState>({
        isConnected: true,
        isInternetReachable: true,
    });

    useEffect(() => {
        const unsubscribe = NetInfo.addEventListener((state: NetInfoState) => {
            setNetworkState({
                isConnected: state.isConnected,
                isInternetReachable: state.isInternetReachable,
            });
        });

        return () => {
            unsubscribe();
        };
    }, []);

    return <NetworkContext.Provider value={{ networkState }}>{children}</NetworkContext.Provider>;
};

export { NetworkContext, NetworkProvider };
