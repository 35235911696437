// React Packages
import React, { useEffect, useState } from "react";
import { FlatList, Pressable, Image, View, Platform } from "react-native";
import mime from "react-native-mime-types";

// Custom Components
import AppText from "../components/AppText";
import Container from "../components/Container";
import IconTouchable from "../components/IconTouchable";

// APIs
import stockApi from "../api/stock";

// Hooks
import useUser from "../hooks/useUser";
import useApi from "../hooks/useApi";
import usePopUp from "../hooks/usePopUp";
import useMedia from "../hooks/useMedia";

// Styles and config
import colors from "../config/colors";
import defaultStyles from "../config/defaultStyles";
import Screen from "../components/Screen";
import useToast from "../hooks/useToast";
import AppTextInput from "../components/AppTextInput";

export default function MyStockScreen({ navigation }) {
    const getStockApi = useApi(stockApi.getStock);
    const addStockApi = useApi(stockApi.addStock);
    const addStockWebApi = useApi(stockApi.addStockWeb);
    const removeStockApi = useApi(stockApi.removeStock);

    const { showToast } = useToast();

    const [selectedMake, setSelectedMake] = useState("all");

    const [registration, setRegistration] = useState("");

    const [listData, setListData] = useState([]);

    const { selectImageFromLibrary, selectImageFromCamera } = useMedia();

    const { user } = useUser();
    const { setMessage, confirmDialog } = usePopUp();

    const [selectedUser, setSelectedUser] = useState(user._id);

    useEffect(() => {
        getStock(selectedUser);
    }, []);

    useEffect(() => {
        const uniqueMakes = [];

        const makes = listData.map((lead) => {
            if (lead.vehicle) {
                return lead.vehicle.make;
            } else {
                return lead.make;
            }
        });
        makes.sort((a, b) => (a > b ? 1 : b > a ? -1 : 0));
        makes.forEach((make) => {
            if (!uniqueMakes.includes(make)) {
                uniqueMakes.push(make);
            }
        });

        setSelectedMake("all");
    }, [listData]);

    const handleAddStock = async (image) => {
        let formdata = new FormData();

        const extension = image.uri.split(".").pop();

        const mimeType = mime.lookup(extension);

        formdata.append("file", {
            uri: image.uri,
            type: mimeType,
            name: "stockItem." + extension,
        });

        formdata.append("userID", selectedUser);

        if (registration) {
            formdata.append("registration", registration);
        }

        var response;
        if (Platform.OS == "web") {
            response = await addStockWebApi.request(image.uri, selectedUser, registration);
            if (!response.ok) {
                setMessage(response.data);
                return;
            }
        } else {
            response = await addStockApi.request(formdata);
            if (!response.ok) {
                setMessage(response.data);
                return;
            }
        }

        setSelectedMake(response.data.make);
        setListData([response.data, ...listData]);
    };

    const upLoadImageFromLibrary = async () => {
        if (!user.subscriptions.includes("vehicleParts")) {
            showToast("You are not subscribed to this service", "error");
            return;
        }

        const image = await selectImageFromLibrary();

        if (!image) {
            setMessage("No Image Selected");
            return;
        }

        handleAddStock(image);
    };

    const uploadImageFromCamera = async () => {
        if (!user.subscriptions.includes("vehicleParts")) {
            showToast("You are not subscribed to this service", "error");
            return;
        }

        const image = await selectImageFromCamera();

        if (!image) {
            setMessage("No Image Selected");
            return;
        }

        handleAddStock(image);
    };

    const getStock = async (userID) => {
        const response = await getStockApi.request(userID);
        if (!response.ok) {
            return;
        }

        setListData(response.data);
    };

    // Removes stock
    const handleRemoveStock = async (itemID) => {
        const response = await removeStockApi.request(itemID);
        if (!response.ok) {
            setMessage(response.data);
            return;
        }

        setListData(listData.filter((item) => item._id != itemID));
    };

    return (
        <Screen loading={addStockApi.loading}>
            <Container
                title={"Add Stock"}
                subTitle={"Enter Reg or Add Image"}
                icon={"plus"}
                utility={
                    <View style={{ flexDirection: "row" }}>
                        <IconTouchable name={"camera-plus"} onPress={() => uploadImageFromCamera()} size={40} />
                        <IconTouchable name={"folder-plus"} onPress={() => upLoadImageFromLibrary()} size={40} />
                    </View>
                }
            >
                <AppTextInput placeholder={"YC18HMV"} onChangeText={(value) => setRegistration(value)} />
            </Container>

            <FlatList
                data={selectedMake == "all" ? listData : listData.filter((item) => item.make == selectedMake)}
                keyExtractor={(item) => item._id}
                renderItem={({ item }) => (
                    <Container
                        key={item._id}
                        animated={false}
                        title={item.registration}
                        subTitle={item.year + " " + item.make + " " + item.model}
                        icon={"car"}
                        utility={
                            <IconTouchable
                                name={"trash-can"}
                                iconColor={colors.red}
                                onPress={() => confirmDialog(() => handleRemoveStock(item._id), "Are you sure you want to remove this stock?")}
                                size={40}
                            />
                        }
                    >
                        <View
                            style={{
                                flexDirection: "row",
                                backgroundColor: colors.light,
                                padding: 4,
                                borderRadius: defaultStyles.borderRadius,
                            }}
                        >
                            <View>
                                <AppText>Engine Code</AppText>
                                <AppText>{item.engineCode}</AppText>
                                <AppText style={{ paddingTop: 8 }}>Engine Size</AppText>
                                <AppText>{item.engineSize}cc</AppText>
                                <AppText style={{ paddingTop: 8 }}>Transmission</AppText>
                                <AppText>{item.transmissionType}</AppText>
                            </View>
                            <Pressable
                                style={{ flex: 1 }}
                                onPress={() => {
                                    if (item.images?.length > 0) {
                                        navigation.navigate("ViewImages", { images: item.images });
                                    }
                                }}
                            >
                                {item.images.map((image, index) => (
                                    <View style={{ flex: 1, alignItems: "flex-end" }} key={index}>
                                        <Image
                                            key={index}
                                            style={{
                                                height: "100%",
                                                width: 130,
                                                borderRadius: defaultStyles.borderRadius,
                                                borderWidth: 2,
                                                borderColor: colors.white,
                                            }}
                                            source={{ uri: image }}
                                            resizeMode={"cover"}
                                        />
                                    </View>
                                ))}
                            </Pressable>
                        </View>
                    </Container>
                )}
            />
        </Screen>
    );
}
