import React from "react";
import { Image, View } from "react-native";

import useUser from "../hooks/useUser";
import Screen from "../components/Screen";
import Container from "../components/Container";
import colors from "../config/colors";
import AppText from "../components/AppText";

export default function WelcomeScreen({ navigation }) {
    const { logOut, user } = useUser();

    return (
        <Screen centered>
            <Image resizeMode="contain" style={{ height: 140, width: 340, alignSelf: "center" }} source={require("../assets/logo-busybee.png")} />
            <View>
                <AppText style={{ paddingVertical: 20, textAlign: "center", color: colors.white, fontWeight: "bold", fontSize: 24 }}>{"Welcome Back " + user?.name}</AppText>
                {user?.isAdmin && (
                    <Container title={"App Dashboard"} subTitle={"Click here to use the Make Me Busy App"} icon={"bee"} onPress={() => navigation.navigate("AgentStack")} />
                )}
                <Container title={"Add a Subscription"} icon={"cash"} borderColor={colors.red} onPress={() => navigation.navigate("NewSubscription")} />
                <Container title={"My Subscriptions"} icon={"cash"} onPress={() => navigation.navigate("Subscriptions")} />
                <Container title={"Speak to one of our team"} icon={"chat"} onPress={() => navigation.navigate("MyChats")} />
            </View>
            <Container title={"Log Out"} icon={"logout"} iconColor={colors.red} onPress={() => logOut()} />
        </Screen>
    );
}
