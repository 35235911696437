import React from "react";
import { View, StyleSheet, ActivityIndicator } from "react-native";
import colors from "../config/colors";
import AppText from "./AppText";

export default function AppActivityIndicator() {
    return (
        <View style={styles.component}>
            <View style={styles.spinner}>
                <AppText style={{ color: colors.honeyComb, paddingBottom: 20 }}>Loading</AppText>
                <ActivityIndicator color={colors.honeyComb} size={"large"} style={styles.item} animating={true} />
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    component: {
        zIndex: 1,
        position: "absolute",
        alignSelf: "center",
        width: "100%",
        height: "100%",
        backgroundColor: colors.tertiary,
        opacity: 0.8,
    },
    spinner: {
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
    },
    item: {
        paddingHorizontal: 10,
    },
});
