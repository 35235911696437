import React, { useState } from "react";
import { LocationObject } from "expo-location";

interface LocationContextProps {
    location: LocationObject | null;
    setLocation: React.Dispatch<React.SetStateAction<LocationObject | null>>;
}

const LocationContext = React.createContext<LocationContextProps>({
    location: null,
    setLocation: () => {},
});

const LocationProvider = ({ children }) => {
    const [location, setLocation] = useState<LocationObject | null>(null);

    return <LocationContext.Provider value={{ location, setLocation }}>{children}</LocationContext.Provider>;
};

export { LocationContext, LocationProvider };
