// React Packages
import React, { useEffect, useState } from "react";
import { FlatList, ScrollView, Platform } from "react-native";

// Custom Components
import Container from "../components/Container";

// APIs
import stockApi from "../api/stock";

// Hooks
import useUser from "../hooks/useUser";
import useApi from "../hooks/useApi";
import usePopUp from "../hooks/usePopUp";

// Styles and config
import TabSelector from "../components/TabSelector";
import leadsApi from "../api/leads";
import ListingCardStock from "../components/ListingCardStock";
import Screen from "../components/Screen";
import SubContainer from "../components/SubContainer";
import ListingCardStockBasic from "../components/ListingCardStockBasic";

export default function PartsSystemScreen({ navigation }) {
    const getMatchingPartRequestsMakeModelYearApi = useApi(stockApi.getMatchingPartRequestsMakeModelYear);
    const getMatchingPartRequestsEngineApi = useApi(stockApi.getMatchingPartRequestsEngine);
    const getPartRequestsApi = useApi(leadsApi.getPartRequests);

    const [uniqueMakes, setUniqueMakes] = useState([]);

    const [selectedMake, setSelectedMake] = useState("all");

    const [tabValue, setTabValue] = useState("New");
    const [tab2Value, setTab2Value] = useState("Part Requests");
    const [listData, setListData] = useState([]);
    const getPreviousJobsApi = useApi(leadsApi.getPreviousJobs);

    const notInterestedApi = useApi(leadsApi.notInterested);

    const { user } = useUser();
    const { setMessage, confirmDialog } = usePopUp();

    const [selectedUser, setSelectedUser] = useState(user._id);

    const notInterested = async (leadID: string) => {
        const newLeadData = listData.filter((lead) => lead._id != leadID);
        setListData(newLeadData);

        const response = await notInterestedApi.request(leadID);
        if (!response.ok) {
            setMessage(response.data);
            return;
        }
    };

    useEffect(() => {
        getPartRequests();
    }, []);

    useEffect(() => {
        const uniqueMakes = [];

        const makes = listData.map((lead) => {
            if (lead.vehicle) {
                return lead.vehicle.make;
            } else {
                return lead.make;
            }
        });
        makes.sort((a, b) => (a > b ? 1 : b > a ? -1 : 0));
        makes.forEach((make) => {
            if (!uniqueMakes.includes(make)) {
                uniqueMakes.push(make);
            }
        });

        setUniqueMakes(uniqueMakes);
        setSelectedMake("all");
    }, [listData]);

    const handleChangeTab = async (tabName: string) => {
        setListData([]);

        if (tabName == "New") {
            getPartRequests();
        }

        if (tabName == "Matching") {
            getMatchingPartRequestsMakeModelYear();
        }

        if (tabName == "Quoted") {
            getQuotedPartRequests();
        }

        setTabValue(tabName);
    };

    const handleSelectTab2 = async (tabName: string) => {
        setTab2Value(tabName);
        let response;

        if (tabName == "Part Requests") {
            response = await getMatchingPartRequestsMakeModelYearApi.request(selectedUser);
        } else {
            response = await getMatchingPartRequestsEngineApi.request(selectedUser);
        }

        if (!response.ok) {
            setListData([]);
            return;
        }

        setListData(response.data);
    };

    const getPartRequests = async () => {
        const response = await getPartRequestsApi.request();
        if (!response.ok) {
            setMessage(response.data);
            return;
        }

        setListData(response.data);
    };

    const getQuotedPartRequests = async () => {
        const response = await getPreviousJobsApi.request("contacted");
        if (!response.ok) {
            setMessage(response.data);
            return;
        }

        setListData(response.data);
    };

    const getMatchingPartRequestsMakeModelYear = async () => {
        const response = await getMatchingPartRequestsMakeModelYearApi.request(selectedUser);
        if (!response.ok) {
            setListData([]);
            return;
        }

        setListData(response.data);
    };

    return (
        <Screen>
            <Container animated={false}>
                <TabSelector tabs={[{ name: "New" }, { name: "Quoted" }, { name: "Matching" }]} onPress={(value) => handleChangeTab(value.name)} />
            </Container>

            {tabValue == "Matching" && (
                <Container animated={false}>
                    <TabSelector tabs={[{ name: "Part Requests" }, { name: "Engine / Gearbox" }]} onPress={(value) => handleSelectTab2(value.name)} value={"tab"} />
                </Container>
            )}

            <FlatList
                data={selectedMake == "all" ? listData : listData.filter((item) => item.vehicle.make == selectedMake)}
                horizontal={false}
                keyExtractor={(item) => item._id}
                ListHeaderComponent={
                    uniqueMakes.length > 0 && (
                        <Container title={"Filter by Make"} subTitle={selectedMake} onPress={() => setSelectedMake("")}>
                            <ScrollView style={{ flex: 1, height: "50%" }}>
                                {selectedMake == "" && <SubContainer title={"All"} onPress={() => setSelectedMake("all")} />}
                                {selectedMake == "" && uniqueMakes.map((make, index) => <SubContainer key={index} title={make} onPress={() => setSelectedMake(make)} />)}
                            </ScrollView>
                        </Container>
                    )
                }
                showsVerticalScrollIndicator={true}
                renderItem={({ item }) =>
                    Platform.OS === "web" ? (
                        <ListingCardStockBasic
                            lead={item}
                            onUtilityPress={() => notInterested(item._id)}
                            onPress={item.contact ? () => navigation.navigate("ListingDetails", { leadID: item._id }) : null}
                        />
                    ) : (
                        <ListingCardStock
                            lead={item}
                            onUtilityPress={() => notInterested(item._id)}
                            onPress={item.contact ? () => navigation.navigate("ListingDetails", { leadID: item._id }) : null}
                        />
                    )
                }
            />
        </Screen>
    );
}
