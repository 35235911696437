import React, { useEffect } from "react";
import { ScrollView } from "react-native";

import Screen from "../components/Screen";

import ChatCard from "../components/ChatCard";
import useChats from "../hooks/useChats";
import useUser from "../hooks/useUser";

function ViewCustomerChatsScreen({ navigation, route }) {
    const { getCustomerChats, chats } = useChats();
    const { user } = useUser();

    useEffect(() => {
        getChats();
    }, []);

    const getChats = async () => {
        getCustomerChats(route.params.leadID);
    };

    return (
        <Screen>
            <ScrollView showsVerticalScrollIndicator={false}>
                {chats && chats.map((chat) => <ChatCard key={chat._id} chat={chat} onPress={() => navigation.navigate("Chat", { chatID: chat._id, activeUser: user._id })} />)}
            </ScrollView>
        </Screen>
    );
}

export default ViewCustomerChatsScreen;
