import React, { useEffect, useState, useRef } from "react";
import { StyleSheet, Dimensions, Platform } from "react-native";
import MapView, { Polyline, Marker } from "react-native-maps";
import polyline from "@mapbox/polyline";

import Screen from "../components/Screen";
import useApi from "../hooks/useApi";
import leadsApi from "../api/leads";
import useLocation from "../hooks/useLocation";
import useToast from "../hooks/useToast";
import { ILead } from "../interfaces/lead";

function ListingRouteScreen({ navigation, route }) {
    const { location } = useLocation();
    const mapRef = useRef(null);
    const [deliveryRoute, setDeliveryRoute] = useState();
    const { width, height } = Dimensions.get("window");
    const [lead, setLead] = useState<ILead>();
    const getLeadApi = useApi(leadsApi.getLead);

    const { showToast } = useToast();

    const initialRegion = {
        latitude: 54,
        longitude: -2.3,
        latitudeDelta: 9.5,
        longitudeDelta: 9.5,
    };

    useEffect(() => {
        getLead();
    }, []);

    const animate = async () => {
        var coordsToFit = [];

        if (location) {
            coordsToFit.push(location.coords);
        }

        if (lead?.location?.delivery?.coords) {
            coordsToFit.push(lead.location.delivery.coords);
        }

        if (lead.location.coords) {
            coordsToFit.push(lead.location.coords);
        }

        mapRef.current.fitToCoordinates(coordsToFit, {
            edgePadding: {
                right: width / 5,
                top: height / 5,
                bottom: height / 5,
                left: width / 5,
            },
        });
    };

    const getLead = async () => {
        const response = await getLeadApi.request(route.params);
        if (!response.ok) {
            showToast("Unable to load lead", "error");
            navigation.goBack();
        }

        if (response?.data?.location?.delivery?.route) {
            const routeDecoded = polyline.decode(response.data.location.delivery.route);

            const routePoints = routeDecoded.map((point) => ({
                latitude: point[0],
                longitude: point[1],
            }));

            setDeliveryRoute(routePoints);
        }
        setLead(response.data);
    };

    if (!lead) {
        return null;
    }

    return (
        <Screen>
            <MapView
                style={styles.map}
                mapType={"standard"}
                ref={mapRef}
                showsUserLocation={true}
                initialRegion={initialRegion}
                initialCamera={{
                    center: {
                        latitude: 54,
                        longitude: -2.5,
                    },
                    pitch: 0,
                    heading: 0,
                    altitude: 10000,
                    zoom: 7,
                }}
                onMapReady={animate}
                googleMapsApiKey={"AIzaSyD86oxaEq4TTV77dO3hST84a2yc1q3BDKM"}
                minZoomLevel={4}
                pitchEnabled={false}
                rotateEnabled={false}
                provider={Platform.OS === "web" || Platform.OS === "android" ? "google" : MapView.PROVIDER_APPLE}
            >
                <Marker title={lead.vehicle.makeModel} coordinate={{ latitude: lead.location.coords.latitude, longitude: lead.location.coords.longitude }} />
                {typeof lead.location.delivery !== "undefined" && (
                    <>
                        <Marker title={"Delivery"} coordinate={{ latitude: lead.location.coords.latitude, longitude: lead.location.coords.longitude }} pinColor={"blue"} />
                        <Polyline coordinates={deliveryRoute} strokeColor={"red"} strokeWidth={3}></Polyline>
                    </>
                )}
            </MapView>
        </Screen>
    );
}

const styles = StyleSheet.create({
    screen: {
        flex: 1,
    },
    map: {
        flex: 1,
        alignItems: "center",
    },
    getDirectionsButton: {
        position: "absolute",
        width: "100%",
        bottom: 10,
    },
});

export default ListingRouteScreen;
