import * as Notifications from "expo-notifications";
import { Platform } from "react-native";

import usersApi from "../api/users";
import useUser from "./useUser";
import * as Device from "expo-device";
import NotificationsContext from "../context/notificationsContext";
import useApi from "./useApi";
import notificationsApi from "../api/notifications";
import { useContext } from "react";
import { useNetwork } from "./useNetwork";

const GRANTED = "granted";
const EXPERIENCE_ID = "@brentcharlton/MakeMeBusy";

const useNotifications = () => {
    const { user, setUser } = useUser();
    const { notifications, setNotifications } = useContext(NotificationsContext);
    const { isInternetReachable } = useNetwork();

    const getNotificationsApi = useApi(notificationsApi.getNotifications);
    const setPushTokenApi = useApi(usersApi.setPushToken);

    const getNotificationPushToken = async () => {
        try {
            if (!Device.isDevice || Platform.OS === "web") {
                setUser({ ...user, pushNotificationsEnabled: false });
                return;
            }

            const { status: existingStatus, canAskAgain } = await Notifications.getPermissionsAsync();
            let finalStatus = existingStatus;
            if (existingStatus !== GRANTED && canAskAgain) {
                const { status } = await Notifications.requestPermissionsAsync();
                finalStatus = status;
            }

            if (finalStatus !== GRANTED) {
                setUser({ ...user, pushNotificationsEnabled: false });
                return;
            }

            if (!isInternetReachable) {
                setUser({ ...user, pushNotificationsEnabled: false });
                return;
            }

            const { data: token } = await Notifications.getExpoPushTokenAsync({ experienceId: EXPERIENCE_ID });
            if (!token) {
                setUser({ ...user, pushNotificationsEnabled: false });
                return;
            }

            if (user.expoPushToken != token) {
                try {
                    await setPushTokenApi.request(token);
                    setUser({ ...user, expoPushToken: token, pushNotificationsEnabled: true });
                } catch (error) {
                    setUser({ ...user, pushNotificationsEnabled: false });
                    console.log(error);
                    return;
                }
            }

            if (Platform.OS === "android") {
                Notifications.setNotificationChannelAsync("default", {
                    name: "default",
                    importance: Notifications.AndroidImportance.MAX,
                    vibrationPattern: [0, 250, 250, 250],
                    lightColor: "#FF231F7C",
                });
            }

            Notifications.setNotificationHandler({
                handleNotification: async () => ({
                    shouldShowAlert: true,
                    shouldPlaySound: true,
                    shouldSetBadge: false,
                }),
            });
        } catch (error) {
            console.log(error);
            setUser({ ...user, pushNotificationsEnabled: false });
        }
    };

    const getNotifications = async () => {
        const response = await getNotificationsApi.request(user._id);
        if (!response.ok) {
            setNotifications([]);
            return;
        }

        setNotifications(response.data);
    };

    const deleteNotification = async (notificationID: string) => {
        const response = await notificationsApi.deleteNotification(notificationID);
        if (response.ok) {
            //remove from notifications array
            const newNotifications = notifications.filter((notification) => notification._id !== notificationID);
            setNotifications(newNotifications);
        }
    };

    return { getNotificationPushToken, notifications, setNotifications, getNotifications, deleteNotification };
};

export default useNotifications;
