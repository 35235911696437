import { useContext } from "react";
import { ToastContext } from "../context/toastContext";

const useToast = () => {
    const { toast, setToast } = useContext(ToastContext);

    const showToast = (message: string, type: string, duration?: number) => {
        setToast({ message, type, duration });
    };

    return { showToast, toast, setToast };
};

export default useToast;
