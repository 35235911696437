import React from "react";
import { useState } from "react";
import { View, Pressable, Animated } from "react-native";

import usePopUp from "../hooks/usePopUp";
import AppText from "./AppText";
import Icon from "./Icon";
import IconTouchable from "./IconTouchable";
import { useRef } from "react";
import defaultStyles from "../config/defaultStyles";
import colors from "../config/colors";

import { MaterialCommunityIcons } from "@expo/vector-icons";

type Props = {
    onPress?: any;
    children?: any;
    title?: string;
    titleColor?: string;
    subTitle?: string;
    subTitleColor?: string;
    icon?: keyof typeof MaterialCommunityIcons.glyphMap;
    backgroundColor?: string;
    iconColor?: string;
    toolTip?: string;
    badge?: string;
    utility?: any;
    iconBackgroundColor?: string;
    enabled?: boolean;
    animated?: boolean;
    onLongPress?: any;
    borderColor?: string;
};

export default function Container({
    onPress,
    children,
    title,
    borderColor,
    titleColor = colors.black,
    subTitle,
    subTitleColor = colors.medium,
    icon,
    backgroundColor,
    iconColor,
    toolTip,
    badge,
    utility,
    iconBackgroundColor,
    enabled = true,
    animated = true,
    onLongPress,
}: Props) {
    const { setMessage } = usePopUp();

    const [hovered, setHovered] = useState(false);

    const springValue = useRef(new Animated.Value(1)).current;

    const handleHoverIn = () => {
        if (!enabled) return;
        if (!animated) return;

        setHovered(true);

        Animated.spring(springValue, {
            toValue: 1.025,
            bounciness: 2,
            useNativeDriver: true,
        }).start();
    };

    const handleHoverOut = () => {
        if (!enabled) return;
        if (!animated) return;

        setHovered(false);

        Animated.spring(springValue, {
            toValue: 1,
            bounciness: 2,
            useNativeDriver: true,
        }).start();
    };

    return (
        <Pressable
            onPress={enabled && onPress}
            onPressIn={() => handleHoverIn()}
            onPressOut={() => handleHoverOut()}
            onHoverIn={() => handleHoverIn()}
            onHoverOut={() => handleHoverOut()}
            onLongPress={onLongPress}
        >
            <Animated.View
                style={{
                    shadowColor: colors.black,
                    shadowOffset: { width: -2, height: 4 },
                    shadowOpacity: 0.7,
                    shadowRadius: 8,
                    elevation: 8,
                    padding: 8,
                    margin: borderColor ? 6 : 8,
                    borderRadius: 8,
                    maxWidth: 600,
                    borderColor: borderColor || undefined,
                    borderWidth: borderColor ? 2 : 0,
                    backgroundColor: hovered ? colors.honeyComb : backgroundColor || colors.white,
                    transform: [{ scale: springValue }],
                }}
            >
                {toolTip && (
                    <View style={{ position: "absolute", right: 4, top: 4, zIndex: 1 }}>
                        <IconTouchable name={"help"} size={30} onPress={() => setMessage(toolTip)} />
                    </View>
                )}
                <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center", paddingBottom: children ? 4 : 0 }}>
                    {icon && (
                        <View style={{ paddingRight: 8 }}>
                            {badge ? (
                                <View
                                    style={{
                                        position: "absolute",
                                        width: 16,
                                        height: 16,
                                        backgroundColor: colors.red,
                                        borderRadius: defaultStyles.borderRadius,
                                        zIndex: 1,
                                    }}
                                >
                                    <AppText style={{ fontSize: 12, fontWeight: "bold", color: colors.white, textAlign: "center", top: 0 }}>{badge ? badge : ""}</AppText>
                                </View>
                            ) : null}

                            <Icon name={icon} iconColor={iconColor} backgroundColor={iconBackgroundColor} />
                        </View>
                    )}
                    <View style={{ flex: 1 }}>
                        {title && <AppText style={{ fontWeight: "bold", color: titleColor }}>{title}</AppText>}
                        {subTitle && <AppText style={{ fontWeight: "600", color: subTitleColor }}>{subTitle}</AppText>}
                    </View>
                    {utility && <View>{utility}</View>}
                </View>
                {children}
            </Animated.View>
        </Pressable>
    );
}
