import React from "react";
import { View, FlatList } from "react-native";
import * as Linking from "expo-linking";

import AppText from "../components/AppText";
import Screen from "../components/Screen";
import { format } from "date-fns";
import useNews from "../hooks/useNews";
import { useEffect } from "react";
import IconTouchable from "../components/IconTouchable";
import useUser from "../hooks/useUser";

import newsApi from "../api/news";
import useApi from "../hooks/useApi";
import Container from "../components/Container";
import colors from "../config/colors";
import SubContainer from "../components/SubContainer";
import useToast from "../hooks/useToast";

function NewsScreen() {
    const { news, setLastViewedNews, getNews } = useNews();
    const { user } = useUser();
    const { showToast } = useToast();

    const setFeedbackApi = useApi(newsApi.setFeedback);

    useEffect(() => {
        setLastViewedNews();
    }, []);

    const handleFeedbackGiven = async (newsItemID: string, type: string) => {
        const response = await setFeedbackApi.request(newsItemID, type);
        if (response.ok) {
            getNews();
        } else {
            showToast(response.data, "error");
        }
    };

    return (
        <Screen>
            <FlatList
                data={news.newsItems}
                keyExtractor={(item) => item.createdDate}
                renderItem={({ item }) => (
                    <Container title={item.title} icon={"bell"} subTitle={item.createdDate && format(new Date(item.createdDate), "dd MMMMMM yy")} animated={false}>
                        <SubContainer subTitle={item.body} />
                        <SubContainer>
                            <View style={{ flexDirection: "row", justifyContent: "space-evenly" }}>
                                <View style={{ alignItems: "center" }}>
                                    {user?.isAdmin && item.downvotes && <AppText>{item.downvotes.length.toString()}</AppText>}
                                    <IconTouchable name={"thumb-down"} size={32} iconColor={colors.red} onPress={() => handleFeedbackGiven(item._id, "downvote")} />
                                </View>
                                <View style={{ alignItems: "center" }}>
                                    {user?.isAdmin && item.upvotes && <AppText>{item.upvotes.length.toString()}</AppText>}
                                    <IconTouchable name={"thumb-up"} size={32} iconColor={colors.green} onPress={() => handleFeedbackGiven(item._id, "upvote")} />
                                </View>
                            </View>
                        </SubContainer>
                    </Container>
                )}
            />
        </Screen>
    );
}

export default NewsScreen;
